import CIcon from '@coreui/icons-react';
import {CInputGroupPrepend, CInputGroupText} from '@coreui/react';
import React from 'react';
import {v4 as uuidv4} from 'uuid';

const CInputGroupRowPrepend = (props: any) => {
  const {iconStart, displayStr, textClass} = props;
  //console.log('Input group row prepend props are: ', props)
  let prependItems: any[] = [];
  if (iconStart) {
    prependItems.push(
      <CInputGroupText key={uuidv4()}>
        <CIcon name={iconStart} />
      </CInputGroupText>
    );
  }
  if (displayStr) {
    prependItems.push(
      <CInputGroupText key={uuidv4()} className={textClass}>
        {displayStr}
      </CInputGroupText>
    );
  }
  //console.log('Prepend items are: ', prependItems)
  if (prependItems.length > 0) {
    return <CInputGroupPrepend key={uuidv4()}>{prependItems}</CInputGroupPrepend>;
  } else {
    return <span></span>;
  }
};
export default CInputGroupRowPrepend;

import React from 'react';
import {Link} from 'react-router-dom';
import {CButton} from '@coreui/react';

const PLinkButton = (props: any) => {
  const {item, color, variant, shape, size, className, toLink, itemBaseLink, itemLinkIdField, buttonText, onClick, componentKey} = props;
  const navigateTo = itemLinkIdField ? (itemBaseLink ? `${itemBaseLink}${item[itemLinkIdField]}` : `${item[itemLinkIdField]}`) : toLink ? toLink : '';
  // console.log('Navigate to field is: ', navigateTo);
  // console.log('In PlinkButton, props are: ', props);
  return (
    <td className="py-2">
      <Link to={`${navigateTo}`}>
        {/* <Link to="forms/new"> */}
        <CButton
          key={componentKey}
          onClick={() => (onClick ? onClick() : console.log('No onclick function provided, props are: ', props))}
          color={color || 'info'}
          variant={variant || 'outline'}
          shape={shape || 'square'}
          size={size || 'sm'}
          className={className || 'mx-1'}
        >
          {`${buttonText || 'Details'}`}
        </CButton>
      </Link>
    </td>
  );
};

export default PLinkButton;
//  : (

// <td className="py-2">
//   <Link to={`${navigateTo}`}>
//     {/* <Link
//     to={{
//       pathname: `${navigateTo}`,
//       state: `${state}`,
//     }}
//   > */}
//     {/* <Link to="forms/new"> */}
//     <CButton onClick={() => onClick()} color={color || 'info'} variant={variant || 'outline'} shape={shape || 'square'} size={size || 'sm'} className={className || 'mx-1'}>
//       {`${buttonText || 'Details'}`}
//     </CButton>
//   </Link>
// </td>

import * as React from 'react';

function PathSpotArrowRightRaw(props: any) {
  const {primary_color, secondary_color, tertiary_color, quaternary_color, className, viewBox, width, height} = props;

  return (
    <svg viewBox={viewBox || '0 0 507.932 364.742'} className={className || 'p-icon-5xl'}>
      <path
        d="M.375 249.217V122.771a17.591 17.591 0 0117.591-17.589h261.261a17.591 17.591 0 110 35.181H35.557v91.263h243.67a17.591 17.591 0 110 35.181H17.966a17.591 17.591 0 01-17.591-17.59z"
        fill={primary_color}
        stroke={tertiary_color}
        strokeMiterlimit={10}
        strokeWidth={0.75}
      />
      <path
        d="M265.154 346.776V17.971A17.591 17.591 0 01293.678 4.19l207.221 164.4a17.59 17.59 0 010 27.561L293.678 360.556a17.591 17.591 0 01-28.524-13.78zm35.182-292.4v255.989l161.33-127.994z"
        fill={primary_color}
        stroke={tertiary_color}
        strokeMiterlimit={10}
        strokeWidth={0.75}
      />
    </svg>
  );
}

const PathSpotArrowRight = React.memo(PathSpotArrowRightRaw);
export default PathSpotArrowRight;

import Cookies from 'js-cookie';
import React from 'react';
import {api} from '../api';
import {REFRESH_TOKEN_API_URL} from '../constants';
import {logIn} from '../../layout/login/login.api';
import _ from 'lodash';

import history from '../history';
import {valueof} from '../../@webapp-lib/pathspot-react';

let renderCounter = 0;

export const LoginEvent = {
  login: `event-login-admin-app-${process.env.NODE_ENV}`,
  logout: `event-logout-admin-app-${process.env.NODE_ENV}`,
  loginCookie: `event-login-cookie-admin-app-${process.env.NODE_ENV}`,
  loginToken: `event-login-token-admin-app-${process.env.NODE_ENV}`,
} as const;

type LoginEvent = keyof typeof LoginEvent;

const defaultTokenExpiration = 900000;
export enum LoginResponse {
  success = 'success',
  fail = 'fail',
  error = 'error',
  unknown = 'unknown',
}

export const AuthenticationStatus = {
  loggedIn: `loginstatus:loggedIn:${process.env.NODE_ENV}`,
  loggedOut: `loginstatus:loggedOut:${process.env.NODE_ENV}`,
  unknown: `loginstatus:unknown:${process.env.NODE_ENV}`,
} as const;

export type AuthenticationStatusType = valueof<typeof AuthenticationStatus>;
export enum AuthContext {
  loggedIn = 'loggedIn',
  notLoggedIn = 'notLoggedIn',
  unknown = 'unknown',
}
export type CurrentUser = {
  userId: number;
  firstName: string;
  lastName: string;
  userEmail: string;
};
export type AuthState = {
  currentContext: AuthContext;
  currentUser: CurrentUser;
  sidebarItems: Array<any>;
  accessToken: string;
  tokenExpiration: number;
  initialized: boolean;
};
export const defaultCurrentUser: CurrentUser = {
  userId: -1,
  userEmail: '',
  firstName: '',
  lastName: '',
};
export const defaultAuthState: AuthState = {
  currentContext: AuthContext.unknown,
  currentUser: {...defaultCurrentUser},
  sidebarItems: [],
  accessToken: '',
  tokenExpiration: -1,
  initialized: false,
};
export type AuthenticationProviderState = {
  authState: AuthState;
  currentUser: CurrentUser;
  setAuthState: (authState: AuthState) => void;
  userLogin: (values: any) => Promise<LoginResponse>;
  userLogout: () => void;
  silentlyRefreshToken: () => Promise<LoginResponse>;
};
const initialState: AuthenticationProviderState = {
  authState: {...defaultAuthState},
  currentUser: {...defaultCurrentUser},
  setAuthState: (newAuthState: AuthState) => {},
  userLogin: async (values: any) => LoginResponse.unknown,
  userLogout: () => {},
  silentlyRefreshToken: async () => LoginResponse.unknown,
};
const AuthenticationContext = React.createContext(initialState);

type AuthenticationContextProps = {
  children: React.ReactNode;
  initialAuthState: any;
};

function AuthenticationProvider({children, initialAuthState}: AuthenticationContextProps) {
  const submitting = React.useRef<any>(null);
  const initialized = React.useRef<boolean>(false);
  const [authState, _setAuthState] = React.useState<AuthState>({...initialState.authState});

  const setAuthState = (newAuthState: AuthState) => {
    _setAuthState({...newAuthState});
  };

  const updateAuthState = (updateData: any) => {
    _setAuthState({...authState, ...updateData});
  };
  const notLoggedInRedirect = () => {
    const currentPath = window.location.pathname;
    // console.log('current path in not logged in redirect is :', currentPath)
    const allowedPaths = ['/email-unsubscribed', '/set-new-password', '/initial-password', '/login'];

    if (!allowedPaths.includes(currentPath)) {
      history.push('/login');
    }
  };
  const setAuthStateFromLogout = () => {
    if (authState.currentContext !== AuthContext.notLoggedIn) {
      updateAuthState({
        ...defaultAuthState,
        currentContext: AuthContext.notLoggedIn,
      } as AuthState);
    }
  };
  const userLogout = () => {
    Cookies.remove(LoginEvent.loginCookie);
    localStorage.removeItem(LoginEvent.logout);
    setAuthStateFromLogout();
    notLoggedInRedirect();
  };

  const updatedAuthStateValidResponse = (logInResponse: any) => {
    const {userId, userEmail, firstName, lastName, accessToken, accessTokenExpMs, sidebarItems, developerOptions} = logInResponse;
    updateAuthState({
      currentUser: {userId, userEmail, firstName, lastName} as CurrentUser,
      accessToken,
      currentContext: AuthContext.loggedIn,
      tokenExpiration: accessTokenExpMs || defaultTokenExpiration,
      sidebarItems,
      initialized: true,
    } as AuthState);
  };

  const parseLoginResponse = (loginResponse: any) => {
    try {
      if (loginResponse && loginResponse.accessToken) {
        const {userId, userEmail, firstName, lastName, accessToken, accessTokenExpMs, sidebarItems, developerOptions} = loginResponse;
        api.setToken(accessToken);
        // console.log('Parsing login response, access token set.: ', _.cloneDeep(loginResponse))
        Cookies.set(LoginEvent.loginCookie, AuthenticationStatus.loggedIn);
        // console.log('Cookie set in parse login response.')
        localStorage.setItem(LoginEvent.logout, AuthenticationStatus.loggedOut + Math.random());
        // console.log('login event set in parse loginResponse')
        setTimeout(() => {
          silentlyRefreshToken();
        }, Math.floor(accessTokenExpMs / 2));
        // console.log('Setting user as logged in in parse login response....', _.cloneDeep({ logInResponse: loginResponse, ...authState }))
        updatedAuthStateValidResponse(loginResponse);
        return LoginResponse.success;
      }
      return LoginResponse.fail;
    } catch (e) {
      //console.log('Error parsing login response: ', e)
      return LoginResponse.error;
    }
  };
  const silentlyRefreshToken = async () => {
    let loginResult: any = null;

    submitting.current = true;
    // console.log('Checking cookies in silent token refresh...')
    if (Cookies.get(LoginEvent.loginCookie) === AuthenticationStatus.loggedIn) {
      try {
        console.log('===== Previous login found =====');
        const silentLogInResponse = await api.noAuth().url(`${REFRESH_TOKEN_API_URL}`).options({credentials: 'include', mode: 'cors'}).post().json();
        loginResult = parseLoginResponse(silentLogInResponse);
        // console.log('Login result after parsing login response is: ', _.cloneDeep(loginResult))

        submitting.current = false;
        // console.log('Done setting auth state from cookie...', _.cloneDeep(silentLogInResponse))
      } catch (err) {
        submitting.current = false;
        // console.warn('!!!!! Attempt to refresh token caused an error !!!!!: ', _.cloneDeep({ loginResult, ...authState, err }))
      }
      // console.log('Login result before returning from silent token refresh is: j', loginResult)
      if (loginResult === LoginResponse.success) {
        // console.log('===== refresh was successful, no re-login needed =====')
      } else if (loginResult === LoginResponse.fail) {
        console.warn('!!!!! Access token invalid or does not exist, re-login required. !!!!!', _.cloneDeep({...authState, loginResult}));
        setAuthStateFromLogout();
      } else if (loginResult === LoginResponse.unknown) {
        // console.warn('!!!!! Access token request returned unknown. !!!!!', _.cloneDeep({ ...authState, loginResult }))
      } else if (loginResult !== null) {
        throw new Error('Access token refresh attempt did not return a reliable result. Please contact PathSpot support.');
      }
    } else {
      // console.log('===== Cookie not found, returning to login screen... =====, Cookie is: ', Cookies.get(LoginEvent.loginCookie))
      notLoggedInRedirect();
    }

    return loginResult;
  };

  const userLogin = async (values: any) => {
    const data = {email: values.email.toLowerCase(), password: values.password};
    // send log in request to the API
    //console.log('Data to be sent for login is: ', _.cloneDeep(data))
    console.log('========= Submitting login request... =========');
    const logInResponse: any = await logIn(data);

    // console.log('Login response is: ', logInResponse)
    if (parseLoginResponse(logInResponse) === LoginResponse.success) {
      console.log('========= Login was successful =========');
      updatedAuthStateValidResponse(logInResponse);
      return LoginResponse.success;
    } else {
      return LoginResponse.fail;
    }
  };
  if (initialAuthState?.success === true && initialized?.current === false) {
    // console.log('Authstate set from bootstrap conditions met...', initialAuthState)
    if (initialAuthState && initialAuthState.authState) {
      // console.log('Setting auth state from bootstrap :', initialAuthState.authState)
      updatedAuthStateValidResponse({...initialAuthState.authState, ...initialAuthState.authState.currentUser});
    }
    initialized.current = true;
    setTimeout(() => {
      silentlyRefreshToken();
    }, Math.floor(initialAuthState.authState.tokenExpiration / 2));
  }

  // console.log('Rendering authentication provider, count is: ', renderCounter)
  // console.log(`Auth state for render # ${renderCounter} is: `, _.cloneDeep(authState))
  renderCounter++;
  return (
    <AuthenticationContext.Provider
      value={{
        authState,
        currentUser: {...authState.currentUser},
        setAuthState,
        userLogin,
        userLogout,
        silentlyRefreshToken,
      }}
    >
      {children}
    </AuthenticationContext.Provider>
  );
}

export {AuthenticationProvider, AuthenticationContext};

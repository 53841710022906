import * as React from 'react';
import CIcon from '@coreui/icons-react';
import {
  PathSpotFavicon,
  PathSpotHandWash,
  PathSpotHorizontal,
  PathSpotHorizontalOutlined,
  PathSpotHorizontalOutlinedThin,
  PathSpotHorizontalOutlinedMedium,
  PathSpotArrowDown,
  PathSpotArrowUp,
  PathSpotArrowLeft,
  PathSpotArrowRight,
  PathSpotCheckCircle,
  PathSpotGerm,
  PathSpotGroup,
  PathSpotLocation,
  PathSpotMessaging,
  PathSpotSearch,
  PathSpotSink,
  PathSpotSoap,
  PathSpotTablet,
  PathSpotTime,
  PathSpotWarning,
  PathSpotWaterDrop,
  PathSpotWifi,
  PathSpotHorizontalWhite,
  PathspotDesktopFallback,
} from './catalog';
import './PIcon.scss';
const pathspot_primary = '#025c71';
const pathspot_secondary = '#00a29b';
const pathspot_icon_primary = '#035d72';
const pathspot_icon_secondary = '#00a199';
const pathspot_success = '#00a29b';
const pathspot_warning = '#a4cf59';
const pathspot_info = '#a55095';
const pathspot_danger = '#681823';
const pathspot_blue = '#025c71';
const pathspot_blue_50 = '#509cad';
const pathspot_teal = '#00a29b';
const pathspot_teal_50 = '#72b6b2';
const pathspot_light_green = '#a4cf59';
const pathspot_lavender = '#a55095';
const pathspot_lavender_50 = '#d492c8';
const pathspot_grey = '#636f83';
const pathspot_grey_9 = '#999999';
const pathspot_grey_dark = '#515967';
const pathspot_grey_light = '#818a98';
const pathspot_white = '#ffffff';
const pathspot_off_white = '#f4f3ee';
const pathspot_bg_white = '#cfd7e3';
const pathspot_black = '#000000';
const pathspot_red = '#8d1928';

const iconColors = {
  primary_color: pathspot_icon_primary,
  secondary_color: pathspot_icon_secondary,
  tertiary_color: pathspot_white,
  quaternary_color: pathspot_light_green,
  purple_color: pathspot_lavender,
};

const icons: any = {
  pathspotHandWash: PathSpotHandWash,
  pathspotFavicon: PathSpotFavicon,
  pathspotHorizontal: PathSpotHorizontal,
  pathspotHorizontalWhite: PathSpotHorizontalWhite,
  pathspotArrowDown: PathSpotArrowDown,
  pathspotArrowUp: PathSpotArrowUp,
  pathspotArrowLeft: PathSpotArrowLeft,
  pathspotArrowRight: PathSpotArrowRight,
  pathspotCheckCircle: PathSpotCheckCircle,
  pathspotGerm: PathSpotGerm,
  pathspotGroup: PathSpotGroup,
  pathspotLocation: PathSpotLocation,
  pathspotMessaging: PathSpotMessaging,
  pathspotSearch: PathSpotSearch,
  pathspotSink: PathSpotSink,
  pathspotSoap: PathSpotSoap,
  pathspotTablet: PathSpotTablet,
  pathspotTime: PathSpotTime,
  pathspotWarning: PathSpotWarning,
  pathspotWaterDrop: PathSpotWaterDrop,
  pathspotWifi: PathSpotWifi,
  pathspotDesktopFallback: PathspotDesktopFallback,
};
export const PathSpotIcon = (props: any) => {
  const {width, height, viewBox, className, size, scale, iconName} = props
    ? props
    : {
        width: undefined,
        height: undefined,
        viewBox: undefined,
        className: undefined,
        size: undefined,
        scale: undefined,
        iconName: undefined,
      };
  const svgArgs = {width, height, viewBox, className, size, scale};
  // console.log('props in PathSpotIcon are: ', props);
  const iconComponentName: string = `${iconName}`;
  const iconNameObject = {name: iconName};
  // console.log('Icon component name is: ', iconComponentName);
  const PathSpotComponent = icons[iconName];
  if (PathSpotComponent) {
    return <PathSpotComponent {...iconColors} {...svgArgs} />;
  } else {
    return <CIcon name={iconName} />;
  }
};

export default PathSpotIcon;

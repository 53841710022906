import React from 'react';
import {ReportContext, ReportingCache, AutomatedReport, emptyDropdowns} from './automated-reporting.api';

export const emptyReportingCache: ReportingCache = {
  // dailyTemplates: null,
  allReports: null,
  dropdowns: {...emptyDropdowns},
};

export type ReportState = {
  reportContext: ReportContext;
  setReportContext: (reportContext: ReportContext) => void;
  reportingCache: ReportingCache;
  setReportingCache: (reportingCache: ReportingCache) => void;
  currentReport: AutomatedReport;
  setCurrentReport: (report: AutomatedReport) => void;
};
const initialState: ReportState = {
  reportContext: ReportContext.weekly,
  setReportContext: (reportContext: ReportContext) => {},
  reportingCache: {...emptyReportingCache},
  setReportingCache: (reportingCache: ReportingCache) => {},
  currentReport: {} as AutomatedReport,
  setCurrentReport: (report: AutomatedReport) => {},
};

const ReportingContext = React.createContext(initialState);

type ReportingContextProps = {
  children: React.ReactNode;
};

function ReportingContextProvider({children}: ReportingContextProps) {
  const [reportContext, _setReportContext] = React.useState<ReportContext>(initialState.reportContext);
  const [reportingCache, _setReportingCache] = React.useState<ReportingCache>(initialState.reportingCache);
  const [currentReport, _setCurrentReport] = React.useState<AutomatedReport>(initialState.currentReport);

  const setReportContext = (reportContext: ReportContext) => {
    console.log('CTXT: Setting reporting context to: ', reportContext);
    _setReportContext(reportContext);
  };
  const setReportingCache = (reportingCache: ReportingCache) => {
    console.log('CTXT: Reporting data is being set to: ', reportingCache);
    _setReportingCache(reportingCache);
  };
  const setCurrentReport = (report: AutomatedReport) => {
    console.log('CTXT: Current report in context API is being set to: ', report);
    _setCurrentReport(report);
  };

  return (
    <ReportingContext.Provider value={{reportContext, setReportContext, reportingCache, setReportingCache, currentReport, setCurrentReport}}>{children}</ReportingContext.Provider>
  );
}

export {ReportingContextProvider, ReportingContext};

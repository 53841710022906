import * as React from 'react';

function PathSpotSoapRaw(props: any) {
  const {primary_color, secondary_color, tertiary_color, quaternary_color, className, viewBox, width, height} = props;
  return (
    <svg viewBox={viewBox || '0 0 465.952 505.515'} className={className || 'p-icon-5xl'}>
      <path
        d="M82.66 357.497a35.744 35.744 0 00-23.086 20.755 34.312 34.312 0 00-2.421 9.309 35.964 35.964 0 0060.881 30.13"
        fill="none"
        stroke={secondary_color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={31.03}
      />
      <path
        d="M59.574 378.252A54.489 54.489 0 0177.23 270.768c1.365.186 2.7.4 4 .682a51.959 51.959 0 0115.825 5.927M76.237 246.689a6.974 6.974 0 01.186-1.955 45.211 45.211 0 0184.93-15.174"
        fill="none"
        stroke={secondary_color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={31.03}
      />
      <path
        d="M337.416 174.761a77.141 77.141 0 0161.191 39.591l11.078 19.984a77.2 77.2 0 01-30.189 104.924l-.035.019-144.134 79.719a77.234 77.234 0 01-104.943-30.192l-11.047-19.984a77.2 77.2 0 0130.169-104.93l.024-.013 54.4-30.1"
        fill="none"
        stroke={primary_color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={31.03}
      />
      <path
        d="M191.823 357.993l-6.206-11.326a21.721 21.721 0 018.311-29.572l.223-.124 36.491-20.169"
        fill="none"
        stroke={primary_color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={31.03}
      />
      <path
        d="M409.747 234.618l31.03 61.067a77.2 77.2 0 01-30.189 104.924l-.035.019-144.134 79.716a77.233 77.233 0 01-104.943-30.192l-31.03-61.1"
        fill="none"
        stroke={primary_color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={31.03}
      />
      <circle
        cx={271.263}
        cy={202.766}
        r={48.127}
        transform="rotate(-45 263.275 204.473)"
        fill="none"
        stroke={secondary_color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={31.03}
      />
      <circle cx={271.601} cy={38.539} r={38.539} fill={secondary_color} />
      <circle cx={153.719} cy={83.626} r={56.723} fill={secondary_color} />
    </svg>
  );
}

const PathSpotSoap = React.memo(PathSpotSoapRaw);
export default PathSpotSoap;

import React from 'react';
import {CCardBody, CCard} from '@coreui/react';
import CIcon from '@coreui/icons-react';

const PFormCard = (props: any) => {
  const {layout, content} = props;

  return (
    <CCard className={layout?.card?.className || 'subcard-card'}>
      <div className={layout?.card?.header?.className || 'subcard-header'}>
        <CIcon className={layout?.card?.header?.icon?.className || 'mx-2'} name={content?.card?.header?.icon} />
        <span className={layout?.card?.header?.text?.className || 'subcard-header-text'}>{`${content?.card?.header?.text}`}</span>
      </div>
      <CCardBody>
        <div className={layout?.group?.className || ''}>{props.children}</div>
      </CCardBody>
    </CCard>
  );
};
export default PFormCard;

import * as React from 'react';

const PathSpotHorizontalOutlinedThinRaw = (props: any) => {
  const {primary_color, secondary_color, tertiary_color, quaternary_color, className, viewBox, width, height} = props;
  return (
    <svg viewBox={viewBox || '0 0 509.532 101.239'} className={className || 'p-icon-5xl'}>
      <path
        fill={primary_color}
        d="M105.331 75.871v-47.59h19.43c10.773 0 15.594 7.824 15.594 15.579 0 7.129-4.084 15.454-15.594 15.454h-9.629v16.557zM124.3 50.677c4.568 0 6.2-3.521 6.2-6.817 0-3.174-1.586-6.88-6.056-6.88h-9.287v13.7z"
        transform="translate(-.847 -1.464)"
      />
      <path
        fill={tertiary_color}
        d="M124.761 28.406c10.732 0 15.469 7.7 15.469 15.454s-4.737 15.329-15.469 15.329h-9.754v16.557h-9.552v-47.34h19.306m-9.738 22.4h9.277c4.6 0 6.321-3.572 6.321-6.942 0-3.433-1.724-7.005-6.181-7.005h-9.412V50.8m9.738-22.646h-19.561V76h10.052V59.439h9.5c11.6 0 15.719-8.392 15.719-15.579a16.334 16.334 0 00-3.771-10.8c-2.8-3.21-6.932-4.906-11.948-4.906zm-9.493 8.946h9.162c4.373 0 5.931 3.489 5.931 6.755 0 3.235-1.6 6.692-6.071 6.692h-9.022V37.1z"
        transform="translate(-.847 -1.464)"
      />
      <path
        fill={primary_color}
        d="M180.072 75.871l-3.851-11.338h-17.557l-3.775 11.338h-10.443L162.2 28.265h10.487l17.69 47.606zm-6.825-19.9L167.38 38.69l-5.806 17.283z"
        transform="translate(-.847 -1.464)"
      />
      <path
        fill={tertiary_color}
        d="M172.6 28.39l17.6 47.356h-10.038l-3.852-11.338h-17.737L154.8 75.746h-10.174l17.659-47.356H172.6M161.4 56.1h12.021l-6.042-17.8-5.979 17.8m11.372-27.96h-10.661l-.06.163-17.659 47.355-.126.338h10.713l.057-.171 3.717-11.167h17.378l3.794 11.168.058.17h10.572l-.126-.337L172.832 28.3l-.06-.163zm-11.025 27.708l5.634-16.767 5.691 16.767z"
        transform="translate(-.847 -1.464)"
      />
      <path fill={primary_color} d="M204.895 74.407V35.516h-13.063v-8.699h35.911v8.699h-13.047v38.891h-9.801z" />
      <path
        fill={tertiary_color}
        d="M228.465 28.406v8.449h-13.047v38.891h-9.552V36.855H192.8v-8.449h35.661m.25-.25h-36.157V37.1h13.062V76h10.052V37.1h12.812v-.25h.235v-8.7z"
        transform="translate(-.847 -1.464)"
      />
      <path fill={primary_color} d="M267.362 74.423V55.179h-17.409v19.244h-9.802V26.817h9.802V46.34h17.409V26.817h9.802v47.606h-9.802z" />
      <path
        fill={tertiary_color}
        d="M277.886 28.406v47.355h-9.552V56.518h-17.659v19.243h-9.552V28.406h9.552v19.523h17.659V28.406h9.552m.25-.25h-10.052v19.523h-17.159V28.156h-10.052v47.855h10.052V56.768h17.159v19.243h10.052V28.156z"
        transform="translate(-.847 -1.464)"
      />
      <path
        fill={primary_color}
        d="M309.321 76.508c-10.114-.016-17.178-6.065-17.178-14.709v-.125h9.8v.123c.063 3.44 3.419 6.135 7.641 6.135s6.958-2.132 6.958-5.434c0-2.556-1.927-4.381-5.891-5.58l-5.776-1.647c-10.189-2.7-12.329-8.768-12.329-13.384 0-8.235 6.931-14.211 16.479-14.211 9.532 0 15.936 5.848 15.936 14.553v.125h-9.8v-.125c0-3.556-2.487-5.855-6.336-5.855-3.707 0-6.4 2.233-6.4 5.311 0 2.285 1.751 3.933 5.2 4.9l5.3 1.507c9.08 2.525 13.494 7.081 13.494 13.927 0 9.956-8.864 14.492-17.1 14.492z"
        transform="translate(-.847 -1.464)"
      />
      <path
        fill={tertiary_color}
        d="M309.026 27.8c8.931 0 15.811 5.436 15.811 14.429h-9.552c0-3.371-2.329-5.98-6.461-5.98-3.712 0-6.523 2.268-6.523 5.436 0 1.165.342 3.635 5.3 5.017l5.3 1.506c7.144 1.988 13.4 5.778 13.4 13.808 0 9.412-8.045 14.366-16.976 14.366-10.126-.015-17.053-6.2-17.053-14.584h9.552c.062 3.433 3.37 6.26 7.765 6.26 4.256 0 7.083-2.206 7.083-5.561 0-2.609-1.926-4.473-5.98-5.7l-5.778-1.646c-10.375-2.749-12.238-9.008-12.238-13.264 0-8.449 7.284-14.087 16.354-14.087m0-.25c-9.621 0-16.6 6.03-16.6 14.337 0 4.659 2.155 10.785 12.424 13.506l5.774 1.645c3.9 1.18 5.8 2.967 5.8 5.459 0 3.226-2.682 5.311-6.833 5.311s-7.454-2.642-7.515-6.014v-.246h-10.062v.25c0 8.719 7.115 14.819 17.3 14.834 8.3 0 17.226-4.574 17.226-14.616 0-6.909-4.444-11.5-13.586-14.048l-5.3-1.507c-3.395-.947-5.115-2.553-5.115-4.776 0-3.005 2.638-5.186 6.273-5.186 3.773 0 6.211 2.249 6.211 5.73v.25h10.052v-.25c0-8.78-6.454-14.679-16.061-14.679z"
        transform="translate(-.847 -1.464)"
      />
      <path
        fill={primary_color}
        d="M340.306 75.871v-47.59h19.43c10.773 0 15.6 7.824 15.6 15.579 0 7.129-4.084 15.454-15.6 15.454h-9.629v16.557zm18.965-25.194c4.568 0 6.2-3.521 6.2-6.817 0-3.174-1.586-6.88-6.057-6.88h-9.287v13.7z"
        transform="translate(-.847 -1.464)"
      />
      <path
        fill={tertiary_color}
        d="M359.736 28.406c10.733 0 15.47 7.7 15.47 15.454s-4.737 15.329-15.47 15.329h-9.753v16.557h-9.552v-47.34h19.305M350 50.8h9.273c4.6 0 6.321-3.572 6.321-6.942 0-3.433-1.724-7.005-6.182-7.005H350V50.8m9.738-22.646h-19.557V76h10.052V59.439h9.5c11.6 0 15.72-8.392 15.72-15.579a16.334 16.334 0 00-3.772-10.8c-2.8-3.21-6.932-4.906-11.948-4.906zm-9.49 8.946h9.162c4.374 0 5.932 3.489 5.932 6.755 0 3.235-1.595 6.692-6.071 6.692h-9.023V37.1z"
        transform="translate(-.847 -1.464)"
      />
      <path
        fill={primary_color}
        d="M409.577 76.492a24.027 24.027 0 01-24.183-24.385 24.057 24.057 0 0124.183-24.447 24.417 24.417 0 010 48.832zm0-39.59c-8.2 0-14.382 6.537-14.382 15.2s6.183 15.2 14.382 15.2 14.381-6.536 14.381-15.2S417.775 36.9 409.577 36.9z"
        transform="translate(-.847 -1.464)"
      />
      <path
        fill={tertiary_color}
        d="M409.577 27.785a24.292 24.292 0 11-24.058 24.322 24.148 24.148 0 0124.058-24.322m0 39.651c8.449 0 14.506-6.8 14.506-15.329s-6.041-15.33-14.506-15.33c-8.527 0-14.506 6.8-14.506 15.33s5.979 15.329 14.506 15.329m0-39.9a24.181 24.181 0 00-24.308 24.572 24.5 24.5 0 007.051 17.332 24.051 24.051 0 0017.257 7.178 24.542 24.542 0 000-49.082zm0 39.651c-8.128 0-14.256-6.482-14.256-15.079s6.128-15.08 14.256-15.08 14.256 6.483 14.256 15.08-6.133 15.078-14.256 15.078z"
        transform="translate(-.847 -1.464)"
      />
      <path fill={primary_color} d="M453.755 74.407V35.516h-13.062v-8.699h35.91v8.699h-13.046v38.891h-9.802z" />
      <path
        fill={tertiary_color}
        d="M477.325 28.406v8.449h-13.046v38.891h-9.552V36.855h-13.062v-8.449h35.66m.25-.25h-36.16V37.1h13.062V76h10.052V37.1h12.812v-.25h.234v-8.7z"
        transform="translate(-.847 -1.464)"
      />
      <path
        fill={primary_color}
        d="M507.111 39.682l-.615-5.528-1.974 4.177h-2.316l-2-4.139-.617 5.49h-3.109l1.381-11.4h2.536l2.948 6.38 2.918-6.38h2.6l1.382 11.4zm-17.16-.015v-8.682h-2.826v-2.7h8.761v2.7h-2.858v8.682z"
        transform="translate(-.847 -1.464)"
      />
      <path
        fill={tertiary_color}
        d="M508.745 28.406l1.355 11.151h-2.873l-.652-5.855-2.128 4.5h-2.159l-2.159-4.457-.652 5.808h-2.858l1.351-11.151h2.346l3.028 6.554 3-6.554h2.407m-12.984 0v2.458H492.9v8.682h-2.827V30.86h-2.823v-2.454h8.511m13.206-.25h-2.79l-.066.146-2.772 6.06-2.8-6.061-.067-.145h-2.727l-.027.22-1.351 11.151-.034.28h3.367l.025-.222.556-4.949 1.782 3.679.068.141h2.469l.068-.143 1.751-3.706.555 4.978.024.222h3.38l-.034-.28-1.352-11.151-.026-.22zm-12.956 0H487v2.954h2.826v8.682h3.327V31.11h2.858v-2.954z"
        transform="translate(-.847 -1.464)"
      />
      <path
        d="M50.83 102.578a32.443 32.443 0 01-29.647-26.1 32.287 32.287 0 014.875-24.4l23.863-35.632a2.91 2.91 0 012.439-1.311 2.944 2.944 0 012.54 1.408l22.3 36.073a32.936 32.936 0 014.011 25.271 32.01 32.01 0 01-14.67 20.073 32.47 32.47 0 01-15.7 4.618h-.011zm1.228-66.443a.079.079 0 00-.066.032L36.606 59.141a19.833 19.833 0 005.437 27.513 19.922 19.922 0 009.057 3.221 19.744 19.744 0 008.91-2.775 19.44 19.44 0 008.89-12.2 20.427 20.427 0 00-2.482-15.613L52.126 36.169z"
        transform="translate(-.847 -1.464)"
        fill={secondary_color}
      />
      <path
        fill={tertiary_color}
        d="M52.43 15.26a2.8 2.8 0 012.359 1.348L77.1 52.682a32.815 32.815 0 014 25.176 31.9 31.9 0 01-14.624 19.995 32.363 32.363 0 01-15.638 4.6 32.315 32.315 0 01-29.532-26 32.166 32.166 0 014.856-24.3l23.863-35.638a2.79 2.79 0 012.335-1.255h.07M51.082 90a19.871 19.871 0 008.993-2.788 19.567 19.567 0 008.946-12.282 20.545 20.545 0 00-2.5-15.707L52.233 36.1a.223.223 0 00-.175-.093.206.206 0 00-.174.093L36.5 59.072A19.9 19.9 0 0051.082 90m1.351-74.99h-.073a3.035 3.035 0 00-2.542 1.365L25.954 52.008a32.644 32.644 0 008.956 45.274 32.755 32.755 0 0015.911 5.418 32.612 32.612 0 0015.784-4.636 32.134 32.134 0 0014.73-20.15 33.057 33.057 0 00-4.027-25.364L55 16.477a3.034 3.034 0 00-2.565-1.467zm-1.346 74.738a19.763 19.763 0 01-8.974-3.2 19.707 19.707 0 01-5.4-27.338l15.344-22.916 14.253 23.06a20.3 20.3 0 012.468 15.518A19.321 19.321 0 0159.946 87a19.583 19.583 0 01-8.859 2.752z"
        transform="translate(-.847 -1.464)"
      />
      <path fill={primary_color} d="M68.892 32.1l-.043-4.933a6.562 6.562 0 0113.123-.059v26.047z" transform="translate(-.847 -1.464)" />
      <path
        fill={tertiary_color}
        d="M75.41 20.709a6.437 6.437 0 016.437 6.4v25.608L69 32.037l-.023-4.868a6.446 6.446 0 016.413-6.46h.023m0-.25h-.023a6.7 6.7 0 00-6.663 6.71l.023 4.869v.071l.037.06 12.85 20.68.462.744V27.111a6.693 6.693 0 00-6.687-6.652z"
        transform="translate(-.847 -1.464)"
      />
      <path
        fill={primary_color}
        d="M8.942 29.966a6.436 6.436 0 012.074-8.852 6.422 6.422 0 018.839 2.074l10.139 16.345-7.016 10.476c-.205.293-.392.592-.563.865l-.2.324z"
        transform="translate(-.847 -1.464)"
      />
      <path
        fill={tertiary_color}
        d="M14.381 20.268a6.135 6.135 0 011.452.174 6.244 6.244 0 013.916 2.811l10.1 16.277-6.975 10.41c-.244.349-.465.709-.662 1.022L9.049 29.9a6.31 6.31 0 012.033-8.679 6.218 6.218 0 013.276-.952h.023m0-.25h-.023a6.446 6.446 0 00-3.409.99 6.561 6.561 0 00-2.113 9.023L22 51.1l.211.338.212-.337.1-.156c.17-.271.356-.568.558-.857l6.973-10.414.09-.134-.085-.136-10.1-16.277A6.488 6.488 0 0015.89 20.2a6.416 6.416 0 00-1.509-.18z"
        transform="translate(-.847 -1.464)"
      />
      <path fill={primary_color} d="M36.546 11.4a6.43 6.43 0 0110.936-6.766l4.227 6.81-.192.025a6.714 6.714 0 00-4.688 2.909l-4.383 6.538z" transform="translate(-.847 -1.464)" />
      <path
        fill={tertiary_color}
        d="M42.009 1.714A6.269 6.269 0 0147.376 4.7l4.124 6.645a6.791 6.791 0 00-4.775 2.963l-4.275 6.378-5.8-9.353a6.294 6.294 0 015.321-9.619h.035m0-.25h-.035a6.544 6.544 0 00-5.533 10l5.8 9.353.2.329.216-.322 4.275-6.378a6.586 6.586 0 014.6-2.854l.385-.05-.2-.33-4.125-6.644a6.529 6.529 0 00-5.58-3.1z"
        transform="translate(-.847 -1.464)"
      />
      <path fill={primary_color} d="M17.993 13.014a6.425 6.425 0 1110.924-6.765L40.148 24.36l-7.856 11.73z" transform="translate(-.847 -1.464)" />
      <path
        fill={tertiary_color}
        d="M23.443 3.329a6.271 6.271 0 015.368 2.985L40 24.357l-7.7 11.5-14.2-22.909a6.294 6.294 0 015.32-9.619h.023m0-.25h-.023a6.543 6.543 0 00-5.533 10l14.2 22.911.2.329L32.5 36l7.7-11.5.089-.134-.085-.137L29.023 6.183a6.528 6.528 0 00-5.58-3.1z"
        transform="translate(-.847 -1.464)"
      />
      <path
        fill={primary_color}
        d="M1.937 49.925a6.436 6.436 0 012.073-8.851 6.423 6.423 0 017.524.557 6.494 6.494 0 01.952 1.117L20.123 55.1l-.028.061a35.887 35.887 0 00-3.056 18.686l.056.542z"
        transform="translate(-.847 -1.464)"
      />
      <path
        fill={tertiary_color}
        d="M7.387 40.239a6.286 6.286 0 014.066 1.487 6.381 6.381 0 01.93 1.092l7.6 12.292a35.951 35.951 0 00-3.067 18.751l-14.871-24a6.294 6.294 0 015.321-9.62h.023m0-.25h-.025a6.545 6.545 0 00-5.533 10l14.87 24 .575.928-.113-1.086a35.776 35.776 0 013.045-18.621l.056-.122-.07-.113-7.594-12.29a6.655 6.655 0 00-.972-1.144 6.554 6.554 0 00-4.237-1.554z"
        transform="translate(-.847 -1.464)"
      />
    </svg>
  );
};

const PathSpotHorizontalOutlinedThin = React.memo(PathSpotHorizontalOutlinedThinRaw);
export default PathSpotHorizontalOutlinedThin;

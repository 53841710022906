import * as React from 'react';

function PathSpotArrowLeftRaw(props: any) {
  const {primary_color, secondary_color, tertiary_color, quaternary_color, className, viewBox, width, height} = props;

  return (
    <svg viewBox={viewBox || '0 0 507.932 364.742'} className={className || 'p-icon-5xl'}>
      <path
        d="M507.557 115.525v126.446a17.591 17.591 0 01-17.591 17.589H228.705a17.591 17.591 0 010-35.181h243.67v-91.263h-243.67a17.591 17.591 0 010-35.181h261.261a17.591 17.591 0 0117.591 17.59z"
        fill={primary_color}
        stroke={tertiary_color}
        strokeMiterlimit={10}
        strokeWidth={0.75}
      />
      <path
        d="M242.778 17.971v328.81a17.591 17.591 0 01-28.524 13.781L7.033 196.152a17.59 17.59 0 010-27.561l207.221-164.4a17.59 17.59 0 0128.524 13.78zm-35.181 292.4V54.377L46.266 182.371z"
        fill={primary_color}
        stroke={tertiary_color}
        strokeMiterlimit={10}
        strokeWidth={0.75}
      />
    </svg>
  );
}

const PathSpotArrowLeft = React.memo(PathSpotArrowLeftRaw);
export default PathSpotArrowLeft;

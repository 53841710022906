import {CInput, CInvalidFeedback} from '@coreui/react';
import React from 'react';
import {v4 as uuidv4} from 'uuid';
const componentKey = uuidv4();
const StyledCInput = (props: any) => {
  const {inputProps, handlers, values, errors, touched, readOnly} = props;
  const {fieldName, className, borderStyle, inputType, placeholderStr, inputId} = inputProps;
  const {setFieldValue, handleChange, handleBlur} = handlers;
  //console.log('Styled CInput props are: ', props)
  if (values[fieldName] === null) {
    console.log('Null found as real null on: ', values[fieldName]);
  }
  if (values[fieldName] === 'null') {
    console.log('Null found as string null on: ', values[fieldName]);
  }
  const keyVal = `${fieldName}-${componentKey}`;
  return (
    <span>
      <CInput
        key={keyVal}
        id={inputId}
        disabled={readOnly || false}
        className={className}
        style={borderStyle}
        type={inputType}
        name={fieldName}
        placeholder={placeholderStr}
        onChange={(e: any) => {
          setFieldValue(fieldName, e.target.value);
        }}
        onBlur={(e: any) => {
          setFieldValue(fieldName, e.target.value);
          handleChange(e.target.value, fieldName, inputId);
        }}
        // value={values?.[fieldName] !== null && (values[fieldName] || values[fieldName] === '') ? values[fieldName] : displayStr}
        value={values?.[fieldName]}
        invalid={errors?.[fieldName] ? true : false}
      />
      {errors?.[fieldName] && <CInvalidFeedback>{errors[fieldName]}</CInvalidFeedback>}
    </span>
  );
};
export default StyledCInput;

import {createSlice} from '@reduxjs/toolkit';
import Cookies from 'js-cookie';

type AppState = {
  sidebarShow: 'responsive' | boolean;
  asideShow: boolean;
  darkMode: boolean;
  currentUser: any;
};

type args = {type?: string; [key: string]: any};

const initialState: AppState = {
  sidebarShow: 'responsive',
  asideShow: false,
  darkMode: false,
  currentUser: null,
};
const sliceName = 'app';

export const appkey = 'app';

export enum AppActions {
  setState = 'setState',
  logout = 'logout',
}

export const AppDispatches = {
  [AppActions.setState]: `${appkey}/${AppActions.setState}`,
  [AppActions.logout]: `${appkey}/${AppActions.logout}`,
};
// export const appReducer = {
//   [sliceReducer.setState]: `${sliceName}/${sliceReducer.setState}`,
// };
export const appSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    // [sliceReducer.setState]: (state: AppState, {type, ...rest}: args) => {
    //   return {...state, ...rest};
    // },
    [AppActions.setState]: (state: AppState, action: any) => {
      //console.log('Set state action payload is: ', { ...action.payload })
      //console.log('Sidebar items are: ', action.payload.displayPages)
      return {...state, ...action.payload};
    },
    [AppActions.logout]: (state: AppState, action: any) => {
      Cookies.remove('userLoggedIn');
      localStorage.removeItem('logout-event');
    },
  },
});

export const {setState} = appSlice.actions;

export default appSlice.reducer;

// const changeState = (state = initialState, {type, ...rest}: args) => {
//   switch (type) {
//     case 'set':
//       return {...state, ...rest};
//     default:
//       return state;
//   }
// };

// setRelogin: (state, action) => {
//   state.appState.reloginRequired = action.payload;
// },
// setAdmin: (state, action) => {
//   state.currentUser.isAdmin = action.payload;
// },
// refreshToken: (state, action) => {
//   state.currentUser = {...state.currentUser, ...action.payload.currentUser};
//   state.appState = {...state.appState, ...action.payload.appState};
// },
// loginSuccess: (state, action) => {
//   state.currentUser = {...state.currentUser, ...action.payload};
//   state.appState = {...state.appState, isAppenticated: true, isLoggedIn: true};
// },

import * as React from 'react';

function PathSpotSinkRaw(props: any) {
  const {primary_color, secondary_color, tertiary_color, quaternary_color, className, viewBox, width, height} = props;
  return (
    <svg viewBox={viewBox || '0 0 510.53 447.433'} className={className || 'p-icon-5xl'}>
      <path fill="none" stroke={secondary_color} strokeLinecap="round" strokeLinejoin="round" strokeWidth={30.53} d="M446.508 432.167H64.022" />
      <path
        fill="none"
        stroke={primary_color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={30.53}
        d="M79.166 267.364h31.782v59.107M431.365 267.364h-31.782v59.107M495.265 351.781h-480M293.638 346.621V121.062a32.423 32.423 0 0164.816-1.99q.031.995 0 1.99l71.839-1.557a104.262 104.262 0 00-208.524 0v227.116"
      />
    </svg>
  );
}

const PathSpotSink = React.memo(PathSpotSinkRaw);
export default PathSpotSink;

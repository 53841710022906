import React, {useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import '../../../styles/assets/icons/pathspot/PIcon.scss';
import PathSpotIcon from '../../../styles/assets/icons/pathspot/PathSpotIcon';

const toCamelCase = (str: string) => {
  return str
    .replace(/([-_][a-z0-9])/gi, ($1) => {
      return $1.toUpperCase();
    })
    .replace(/-/gi, '');
};

const PIcon = (props: any) => {
  const {className, name, size, title, outerTagClass, ...attributes} = props;
  //console.log('!!!!P-Icon props are: ', props);
  const [change, setChange] = useState(0);

  useMemo(() => setChange(change + 1), [name]);

  const iconName = useMemo(() => {
    const iconNameIsKebabCase = name && name.includes('-');
    return iconNameIsKebabCase ? toCamelCase(name) : name;
  }, [change]);
  //console.log('58.1-]]]] PIcon: iconName: UE', iconName);
  const titleCode = title ? `<title>${title}</title>` : '';

  const PathSpotIconMZ = useMemo(() => {
    return <PathSpotIcon iconName={iconName} {...props} />;
  }, [change]);

  return (
    <div className="p-icon">
      {titleCode}
      {PathSpotIconMZ}
    </div>
  );
};

PIcon.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  size: PropTypes.oneOf(['custom', 'custom-size', 'sm', 'lg', 'xl', '2xl', '3xl', '4xl', '5xl', '6xl', '7xl', '8xl', '9xl']),
  customClasses: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.object]),
  src: PropTypes.string,
  title: PropTypes.string,
  use: PropTypes.string,
};

export default PIcon;

export const PIconWarn = (props: any) => {
  return <PIcon {...props} />;
};

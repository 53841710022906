import { sygnet } from './sygnet'
import { logo } from './logo'
import { logoNegative } from './logo-negative'

import {
  cilApplicationsSettings,
  cilSpeedometer,
  cilFolderOpen,
  cilSpeech,
  cilSun,
  cilMoon,
  cilSettings,
  cilLockLocked,
  cilUser,
  cilPeople,
  cilChevronBottom,
  cilChevronTop,
  cilAccountLogout,
  cilCloudDownload,
  cilCast,
  cilBan,
  cilScrubber,
  cilEnvelopeClosed,
  cilShortText,
  cilPhone,
  cilBuilding,
  cilAddressBook,
  cilLaptop,
  cilList,
  cilPlus,
  cilCenterFocus,
  cilObjectGroup,
  cilUsb,
  cilCalendar,
  cilNotes,
  cilLifeRing,
  cilCloud,
  cilCloudUpload,
  cilDelete,
  cilTrash,
  cilInfo,
  cilDevices,
  cilTablet,
  cilInput,
  cilMemory,
  cilBarcode,
  cilCamera,
  cilStream,
  cilDescription,
  cilListNumbered,
  cilTag,
  cilTextSquare,
  cilTask,
  cilTerminal,
  cilLevelUp,
  cilLan,
  cilCreditCard,
  cilTruck,
  cilText,
  cilSmile,
  cilLocationPin,
  cilScreenSmartphone,
  cilDialpad,
  cilAsterisk,
  cilPenNib,
  cilMediaPause,
  cilColorBorder,
  cilAvTimer,
  cilCheck,
  cilMap,
  cilLibraryAdd,
  cilActionRedo,
  cilClone,
  cilCalendarCheck,
  cilChartLine,
  cilCalculator,
  cilGlobeAlt,
  cilClock,
  cilNoteAdd,
  cilLineStyle,
  cilSpreadsheet,
  cilClipboard,
  cilPlaylistAdd,
  cilStorage,
  cilSignLanguage,
  cilCommentBubble,
  cilReload,
  cilX,
  cilCart,
  cilSearch,
  cilListRich,
  cilWarning,
  cilViewQuilt,
  cilViewColumn,
  cilViewModule,
  cilCheckAlt
  
} from '@coreui/icons/js/free'

export const icons = Object.assign({}, {
  sygnet,
  logo,
  logoNegative
}, {
  cilApplicationsSettings,
  cilSpeedometer,
  cilFolderOpen,
  cilSpeech,
  cilSun,
  cilMoon,
  cilSettings,
  cilLockLocked,
  cilUser,
  cilChevronBottom,
  cilChevronTop,
  cilAccountLogout,
  cilCloudDownload,
  cilCast,
  cilBan,
  cilScrubber,
  cilEnvelopeClosed,
  cilShortText,
  cilPhone,
  cilBuilding,
  cilAddressBook,
  cilLaptop,
  cilList,
  cilPeople,
  cilPlus,
  cilCenterFocus,
  cilObjectGroup,
  cilUsb,
  cilCalendar,
  cilNotes,
  cilLifeRing,
  cilCloud,
  cilCloudUpload,
  cilDelete,
  cilTrash,
  cilInfo,
  cilDevices,
  cilTablet,
  cilInput,
  cilMemory,
  cilBarcode,
  cilCamera,
  cilStream,
  cilDescription,
  cilListNumbered,
  cilTag,
  cilTextSquare,
  cilTask,
  cilTerminal,
  cilLevelUp,
  cilLan,
  cilCreditCard,
  cilTruck,
  cilSmile,
  cilText,
  cilLocationPin,
  cilScreenSmartphone,
  cilDialpad,
  cilAsterisk,
  cilPenNib,
  cilMediaPause,
  cilColorBorder,
  cilAvTimer,
  cilCheck,
  cilMap,
  cilLibraryAdd,
  cilActionRedo,
  cilClone,
  cilCalendarCheck,
  cilChartLine,
  cilCalculator,
  cilGlobeAlt,
  cilClock,
  cilNoteAdd,
  cilLineStyle,
  cilSpreadsheet,
  cilClipboard,
  cilPlaylistAdd,
  cilStorage,
  cilSignLanguage,
  cilCommentBubble,
  cilReload,
  cilX,
  cilCart,
  cilSearch,
  cilListRich,
  cilWarning,
  cilViewQuilt,
  cilViewColumn,
  cilViewModule,
  cilCheckAlt
})

import * as React from 'react';

const PathSpotFaviconRaw = (props: any) => {
  const {primary_color, secondary_color, tertiary_color, quaternary_color, className, viewBox, width, height} = props;
  return (
    <svg viewBox={viewBox || '0 0 409.897 510'} className={className || 'p-icon-5xl'}>
      <path
        d="M440.442 258.876L327.419 75.564a15.974 15.974 0 00-11.981-6.79 14.776 14.776 0 00-11.981 6.39L180.45 255.681a164.478 164.478 0 00125 255.2 166.142 166.142 0 0079.874-23.563 159.748 159.748 0 0074.683-101.041 164.143 164.143 0 00-19.565-127.401zM400.5 371.5a99.442 99.442 0 01-45.528 62.3 96.645 96.645 0 01-45.529 13.978 99.843 99.843 0 01-89.06-79.874 97.849 97.849 0 0115.176-75.881l77.079-116.218 72.686 117.416a105.035 105.035 0 0114.382 78.279z"
        transform="translate(-55.261 -.88)"
        fill={secondary_color}
      />
      <path
        fill={primary_color}
        d="M464.4 261.671V129.08a32.747 32.747 0 00-32.34-33.148 31.546 31.546 0 00-22.764 9.984 31.145 31.145 0 00-9.585 22.764v24.761zM162.079 250.09l3.195-5.192 35.544-52.718-51.118-82.67a31.947 31.947 0 00-19.969-13.578h-7.188a29.95 29.95 0 00-16.375 4.792 31.552 31.552 0 00-10.774 43.3q.192.318.391.632zM264.717 95.932l21.567-32.35a33.548 33.548 0 0124.361-14.776l-20.767-32.749A31.552 31.552 0 00262.721.881a35.539 35.539 0 00-16.774 4.792A32.349 32.349 0 00235.963 50zM213.2 173.41l39.936-58.31-57.51-91.456a31.552 31.552 0 00-27.157-15.175 29.95 29.95 0 00-16.375 4.792 31.95 31.95 0 00-11.582 42.733zM150.9 270.857l-39.937-62.3a53.611 53.611 0 00-4.393-5.591 32.751 32.751 0 00-20.77-7.191 33.944 33.944 0 00-16.773 4.393 32.348 32.348 0 00-8.387 44.33l75.481 121.809a182.514 182.514 0 0114.779-95.45z"
        transform="translate(-55.261 -.88)"
      />
    </svg>
  );
};

const PathSpotFavicon = React.memo(PathSpotFaviconRaw);
export default PathSpotFavicon;

import React from 'react';
import {CInput, CInputGroup, CRow} from '@coreui/react';
import {CInputGroupRowPrepend, CInputGroupRowAppend, PAlertErrors, CAlertRow} from '../../index';
import FAlertRow from './FAlertRow';
const CInputGroupRow = (props: any) => {
  const {inputType, fieldName, iconStart, displayStr, placeholderStr, iconEnd, endStr, style, borderStyle, handleChange, values, touched, errors, readOnly, className, useRow} =
    props;
  // console.log('Input group row props are: ', props);
  // console.log('Value check returns: values', values);
  // console.log('Value check returns: values[fieldName]', values[fieldName]);
  // console.log("Value check returns: values[fieldName] === ''", values && (values[fieldName] || values[fieldName] === ''));
  // console.log('Value check returns: ', values && (values[fieldName] || values[fieldName] === ''));
  const hasErrors = touched && touched[fieldName] && errors && errors?.[fieldName] && errors?.[fieldName]?.length > 0;
  return useRow ? (
    <CRow className="mb-3">
      <CInputGroup>
        <CInputGroupRowPrepend iconStart={iconStart} displayStr={displayStr} />
        <CInput
          readOnly={readOnly}
          style={borderStyle}
          type={inputType}
          name={fieldName}
          placeholder={placeholderStr}
          onChange={(val: any) => {
            console.log('On Change value isl:', val);
            handleChange(fieldName, val.target.value);
          }}
          value={values[fieldName]}
          invalid={hasErrors}
        />
        <CInputGroupRowAppend iconEnd={iconEnd} endStr={endStr} />
      </CInputGroup>
      {hasErrors && <FAlertRow errors={errors} fieldName={fieldName} />}
    </CRow>
  ) : (
    <div className={className || 'mb-3'}>
      <CInputGroup>
        <CInputGroupRowPrepend iconStart={iconStart} displayStr={displayStr} />
        <CInput
          readOnly={readOnly}
          style={borderStyle}
          type={inputType}
          name={fieldName}
          placeholder={placeholderStr}
          onChange={(val: any) => {
            console.log('On Change value isl:', val);
            handleChange(fieldName, val.target.value);
          }}
          value={values[fieldName]}
          invalid={hasErrors}
        />
        <CInputGroupRowAppend iconEnd={iconEnd} endStr={endStr} />
      </CInputGroup>
      {hasErrors && <FAlertRow errors={errors} fieldName={fieldName} />}
    </div>
  );
};
export default CInputGroupRow;

import * as React from 'react';

const PathSpotHorizontalOutlinedRaw = (props: any) => {
  const {primary_color, secondary_color, tertiary_color, quaternary_color, className, viewBox, width, height} = props;
  return (
    <svg viewBox={viewBox || '0 0 511.128 102.741'} className={className || 'p-icon-5xl'}>
      <path
        fill={primary_color}
        d="M104.956 76.246v-48.34h19.8c11.031 0 15.969 8.013 15.969 15.954 0 10.935-8.02 15.829-15.969 15.829h-9.254v16.557zM124.3 50.3c4.292 0 5.821-3.328 5.821-6.442 0-3.144-1.492-6.505-5.681-6.505h-8.912V50.3z"
        transform="translate(-.097 -.714)"
      />
      <path
        fill={tertiary_color}
        d="M124.761 28.406c10.732 0 15.469 7.7 15.469 15.454s-4.737 15.329-15.469 15.329h-9.754v16.557h-9.552v-47.34h19.306m-9.738 22.4h9.277c4.6 0 6.321-3.572 6.321-6.942 0-3.433-1.724-7.005-6.181-7.005h-9.412V50.8m9.738-23.4h-20.311v49.34h11.552V60.189h8.754c5.244 0 9.569-1.76 12.509-5.091a16.878 16.878 0 003.96-11.238c0-8.19-5.092-16.454-16.469-16.454zm-8.738 10.449h8.412c3.82 0 5.181 3.1 5.181 6.005 0 1.394-.383 5.942-5.321 5.942h-8.272V37.855z"
        transform="translate(-.097 -.714)"
      />
      <path
        fill={primary_color}
        d="M179.8 76.246l-3.851-11.338h-17.015l-3.775 11.338h-11.253l18.032-48.356h11.008l17.968 48.356zM172.723 55.6l-5.341-15.736L162.1 55.6z"
        transform="translate(-.097 -.714)"
      />
      <path
        fill={tertiary_color}
        d="M172.6 28.39l17.6 47.356h-10.038l-3.852-11.338h-17.737L154.8 75.746h-10.174l17.659-47.356H172.6M161.4 56.1h12.021l-6.042-17.8-5.979 17.8m11.893-28.71h-11.7l-.243.651L143.689 75.4l-.5 1.35h12.331l.228-.684 3.546-10.654h16.3l3.621 10.659.23.679h12.188l-.5-1.349-17.6-47.355-.242-.652zM162.791 55.1l4.593-13.673 4.642 13.673z"
        transform="translate(-.097 -.714)"
      />
      <path fill={primary_color} d="M205.269 75.532V36.641h-13.062v-9.449h36.66v9.449h-13.046v38.891h-10.552z" />
      <path
        fill={tertiary_color}
        d="M228.465 28.406v8.449h-13.047v38.891h-9.552V36.855H192.8v-8.449h35.661m1-1H191.8v10.449h13.062v38.891h11.552V37.855h12.066v-1h.985v-9.449z"
        transform="translate(-.097 -.714)"
      />
      <path fill={primary_color} d="M267.737 75.548V56.304h-16.659v19.244h-10.553V27.192h10.553v19.523h16.659V27.192h10.552v48.356h-10.552z" />
      <path
        fill={tertiary_color}
        d="M277.886 28.406v47.355h-9.552V56.518h-17.659v19.243h-9.552V28.406h9.552v19.523h17.659V28.406h9.552m1-1h-11.552v19.523h-15.659V27.406h-11.552v49.355h11.552V57.518h15.659v19.243h11.552V27.406z"
        transform="translate(-.097 -.714)"
      />
      <path
        fill={primary_color}
        d="M309.32 76.883c-10.335-.016-17.552-6.219-17.552-15.084v-.5h10.543l.008.491c.059 3.234 3.25 5.767 7.266 5.767 4 0 6.583-1.986 6.583-5.059 0-2.368-1.84-4.076-5.624-5.222l-5.771-1.644c-10.414-2.759-12.6-9-12.6-13.745 0-8.452 7.088-14.586 16.854-14.586 9.6 0 16.311 6.138 16.311 14.928v.5h-10.553v-.5c0-3.329-2.34-5.48-5.961-5.48-3.49 0-6.023 2.076-6.023 4.936 0 2.1 1.659 3.622 4.931 4.535l5.3 1.508c9.263 2.576 13.767 7.25 13.767 14.288 0 10.214-9.059 14.867-17.476 14.867z"
        transform="translate(-.097 -.714)"
      />
      <path
        fill={tertiary_color}
        d="M309.026 27.8c8.931 0 15.811 5.436 15.811 14.429h-9.552c0-3.371-2.329-5.98-6.461-5.98-3.712 0-6.523 2.268-6.523 5.436 0 1.165.342 3.635 5.3 5.017l5.3 1.506c7.144 1.988 13.4 5.778 13.4 13.808 0 9.412-8.045 14.366-16.976 14.366-10.126-.015-17.053-6.2-17.053-14.584h9.552c.062 3.433 3.37 6.26 7.765 6.26 4.256 0 7.083-2.206 7.083-5.561 0-2.609-1.926-4.473-5.98-5.7l-5.778-1.646c-10.375-2.749-12.238-9.008-12.238-13.264 0-8.449 7.284-14.087 16.354-14.087m0-1c-10.056 0-17.354 6.345-17.354 15.087 0 9.389 8.134 12.946 12.982 14.231l5.76 1.641c4.707 1.425 5.254 3.387 5.254 4.738 0 2.771-2.388 4.561-6.083 4.561-3.74 0-6.712-2.318-6.766-5.278l-.023-.98h-11.532v1c0 9.014 7.592 15.568 18.052 15.584 8.659 0 17.977-4.808 17.977-15.366 0-7.191-4.756-12.161-14.135-14.771l-5.291-1.5c-4.57-1.275-4.57-3.367-4.57-4.055 0-2.57 2.323-4.436 5.523-4.436 3.318 0 5.461 1.955 5.461 4.98v1h11.552v-1c0-9.084-6.913-15.429-16.811-15.429z"
        transform="translate(-.097 -.714)"
      />
      <path
        fill={primary_color}
        d="M339.931 76.246v-48.34h19.805c11.033 0 15.97 8.013 15.97 15.954 0 10.935-8.02 15.829-15.97 15.829h-9.254v16.557zm19.34-25.946c4.291 0 5.821-3.328 5.821-6.442 0-3.144-1.492-6.505-5.682-6.505h-8.91V50.3z"
        transform="translate(-.097 -.714)"
      />
      <path
        fill={tertiary_color}
        d="M359.736 28.406c10.733 0 15.47 7.7 15.47 15.454s-4.737 15.329-15.47 15.329h-9.753v16.557h-9.552v-47.34h19.305M350 50.8h9.273c4.6 0 6.321-3.572 6.321-6.942 0-3.433-1.724-7.005-6.182-7.005H350V50.8m9.738-23.4h-20.307v49.34h11.552V60.189h8.753c5.244 0 9.57-1.76 12.509-5.091a16.877 16.877 0 003.961-11.238c0-8.19-5.093-16.454-16.47-16.454zM351 37.855h8.412c3.821 0 5.182 3.1 5.182 6.005 0 1.394-.384 5.942-5.321 5.942H351V37.855z"
        transform="translate(-.097 -.714)"
      />
      <path
        fill={primary_color}
        d="M409.577 76.867a24.688 24.688 0 01-24.558-24.76 24.428 24.428 0 0124.558-24.822 24.792 24.792 0 010 49.582zm0-39.59c-7.985 0-14.007 6.376-14.007 14.83s6.022 14.829 14.007 14.829 14.006-6.375 14.006-14.829-6.021-14.83-14.006-14.83z"
        transform="translate(-.097 -.714)"
      />
      <path
        fill={tertiary_color}
        d="M409.577 27.785a24.292 24.292 0 11-24.058 24.322 24.148 24.148 0 0124.058-24.322m0 39.651c8.449 0 14.506-6.8 14.506-15.329s-6.041-15.33-14.506-15.33c-8.527 0-14.506 6.8-14.506 15.33s5.979 15.329 14.506 15.329m0-40.651a24.771 24.771 0 00-17.8 7.409 25.652 25.652 0 00.006 35.772 24.8 24.8 0 0017.791 7.4 25.292 25.292 0 000-50.582zm0 39.651c-7.7 0-13.506-6.16-13.506-14.329s5.806-14.33 13.506-14.33 13.506 6.161 13.506 14.33-5.806 14.329-13.506 14.329z"
        transform="translate(-.097 -.714)"
      />
      <path fill={primary_color} d="M454.129 75.532V36.641h-13.061v-9.449h36.66v9.449h-13.047v38.891h-10.552z" />
      <path
        fill={tertiary_color}
        d="M477.325 28.406v8.449h-13.046v38.891h-9.552V36.855h-13.062v-8.449h35.66m1-1h-37.66v10.449h13.062v38.891h11.552V37.855h12.062v-1h.984v-9.449z"
        transform="translate(-.097 -.714)"
      />
      <path
        fill={primary_color}
        d="M506.776 40.057l-.506-4.547-1.51 3.2h-2.789l-1.542-3.183-.509 4.534h-3.869l1.472-12.151h3.108l2.708 5.856 2.678-5.856h3.171l1.473 12.151zm-17.2-.015V31.36h-2.826v-3.454h9.511v3.454H493.4v8.682z"
        transform="translate(-.097 -.714)"
      />
      <path
        fill={tertiary_color}
        d="M508.745 28.406l1.355 11.151h-2.873l-.652-5.855-2.128 4.5h-2.159l-2.159-4.457-.652 5.808h-2.858l1.351-11.151h2.346l3.028 6.554 3-6.554h2.407m-12.984 0v2.458H492.9v8.682h-2.827V30.86h-2.823v-2.454h8.511M509.631 27.406H505.7l-.268.584-2.092 4.576-2.117-4.58-.268-.58h-3.875l-.106.879-.213 1.756v-2.635H486.25v4.454h2.826v8.682h4.824V31.86h2.638l-.919 7.577-.135 1.12h4.88l.1-.888.266-2.371.651 1.344.273.564h3.42l.27-.573.621-1.313.261 2.348.1.889h4.9l-.136-1.12-1.351-11.152-.107-.879z"
        transform="translate(-.097 -.714)"
      />
      <path
        d="M50.8 102.952a32.815 32.815 0 01-29.989-26.4 32.664 32.664 0 014.931-24.681L49.61 16.237a3.281 3.281 0 012.75-1.477 3.315 3.315 0 012.855 1.587l22.306 36.072a33.308 33.308 0 014.057 25.557 32.386 32.386 0 01-14.844 20.305 32.844 32.844 0 01-15.88 4.672h-.025zM36.917 59.35a19.459 19.459 0 005.335 26.993 19.536 19.536 0 008.88 3.158 19.349 19.349 0 008.684-2.718 19.073 19.073 0 008.719-11.97A20.057 20.057 0 0066.1 59.485L52.048 36.756z"
        transform="translate(-.097 -.714)"
        fill={secondary_color}
      />
      <path
        fill={tertiary_color}
        d="M52.43 15.26a2.8 2.8 0 012.359 1.348L77.1 52.682a32.815 32.815 0 014 25.176 31.9 31.9 0 01-14.624 19.995 32.363 32.363 0 01-15.638 4.6 32.315 32.315 0 01-29.532-26 32.166 32.166 0 014.856-24.3l23.863-35.638a2.79 2.79 0 012.335-1.255h.07M51.082 90a19.871 19.871 0 008.993-2.788 19.567 19.567 0 008.946-12.282 20.545 20.545 0 00-2.5-15.707L52.233 36.1a.223.223 0 00-.175-.093.206.206 0 00-.174.093L36.5 59.072A19.9 19.9 0 0051.082 90m1.36-75.739h-.082a3.772 3.772 0 00-3.163 1.7l-23.866 35.63a33.352 33.352 0 0025.438 51.86h.101a33.336 33.336 0 0016.122-4.744 32.878 32.878 0 0015.072-20.614 33.8 33.8 0 00-4.118-25.937L55.639 16.082a3.819 3.819 0 00-3.185-1.821zM51.1 89a18.847 18.847 0 01-16.615-15.147 18.792 18.792 0 012.847-14.224l14.7-21.949 13.64 22.069A19.547 19.547 0 0168.048 74.7a18.568 18.568 0 01-8.49 11.658A18.838 18.838 0 0151.1 89z"
        transform="translate(-.097 -.714)"
      />
      <path fill={primary_color} d="M68.5 32.18l-.024-5.009a6.937 6.937 0 0113.873-.062v27.359z" transform="translate(-.097 -.714)" />
      <path
        fill={tertiary_color}
        d="M75.41 20.709a6.437 6.437 0 016.437 6.4v25.608L69 32.037l-.023-4.868a6.446 6.446 0 016.413-6.46h.023m0-1h-.023a7.446 7.446 0 00-7.413 7.46L68 32.041v.283l.149.24L81 53.244l1.85 2.977v-29.11a7.444 7.444 0 00-7.437-7.4z"
        transform="translate(-.097 -.714)"
      />
      <path
        fill={primary_color}
        d="M8.625 30.164a6.81 6.81 0 012.192-9.364 6.8 6.8 0 019.357 2.2l10.265 16.55-7.149 10.668c-.206.294-.39.587-.557.855l-.523.831z"
        transform="translate(-.097 -.714)"
      />
      <path
        fill={tertiary_color}
        d="M14.381 20.268a6.135 6.135 0 011.452.174 6.244 6.244 0 013.916 2.811l10.1 16.277-6.975 10.41c-.244.349-.465.709-.662 1.022L9.049 29.9a6.31 6.31 0 012.033-8.679 6.218 6.218 0 013.276-.952h.023m0-1h-.023a7.185 7.185 0 00-3.8 1.1A7.311 7.311 0 008.2 30.427l13.164 21.065.845 1.352.849-1.349.1-.157c.163-.261.342-.547.536-.825l6.983-10.426.358-.536L30.7 39 20.6 22.726a7.237 7.237 0 00-4.538-3.258 7.131 7.131 0 00-1.68-.2z"
        transform="translate(-.097 -.714)"
      />
      <path
        fill={primary_color}
        d="M36.228 11.6a6.811 6.811 0 012.194-9.37A6.825 6.825 0 0147.8 4.436l4.533 7.3-.769.1a6.337 6.337 0 00-4.425 2.746l-4.706 7.021z"
        transform="translate(-.097 -.714)"
      />
      <path
        fill={tertiary_color}
        d="M42.009 1.714A6.269 6.269 0 0147.376 4.7l4.124 6.645a6.791 6.791 0 00-4.775 2.963l-4.275 6.378-5.8-9.353a6.294 6.294 0 015.321-9.619h.035m0-1h-.035A7.294 7.294 0 0035.8 11.862l5.8 9.351.817 1.318.864-1.288 4.275-6.379a5.757 5.757 0 014.075-2.527l1.537-.2-.818-1.317-4.124-6.648A7.276 7.276 0 0042.009.714z"
        transform="translate(-.097 -.714)"
      />
      <path fill={primary_color} d="M17.674 13.212a6.8 6.8 0 1111.561-7.161l11.359 18.316-8.314 12.415z" transform="translate(-.097 -.714)" />
      <path
        fill={tertiary_color}
        d="M23.443 3.329a6.271 6.271 0 015.368 2.985L40 24.357l-7.7 11.5-14.2-22.909a6.294 6.294 0 015.32-9.619h.023m0-1h-.023a7.294 7.294 0 00-6.17 11.148l14.2 22.909.818 1.319.863-1.29 7.7-11.5.358-.536-.339-.548L29.661 5.787a7.276 7.276 0 00-6.218-3.458z"
        transform="translate(-.097 -.714)"
      />
      <path
        fill={primary_color}
        d="M1.618 50.123a6.8 6.8 0 015.746-10.384 6.814 6.814 0 014.413 1.605 6.994 6.994 0 011.02 1.194l7.75 12.537-.111.243a35.524 35.524 0 00-3.025 18.491l.228 2.171z"
        transform="translate(-.097 -.714)"
      />
      <path
        fill={tertiary_color}
        d="M7.387 40.239a6.286 6.286 0 014.066 1.487 6.381 6.381 0 01.93 1.092l7.6 12.292a35.951 35.951 0 00-3.067 18.751l-14.871-24a6.294 6.294 0 015.321-9.62h.023m0-1h-.025a7.294 7.294 0 00-6.17 11.148l14.87 24 2.3 3.711-.455-4.342a35.035 35.035 0 012.982-18.23l.223-.487-.281-.456-7.6-12.292a7.436 7.436 0 00-1.1-1.3 7.325 7.325 0 00-4.749-1.756z"
        transform="translate(-.097 -.714)"
      />
    </svg>
  );
};

const PathSpotHorizontalOutlined = React.memo(PathSpotHorizontalOutlinedRaw);
export default PathSpotHorizontalOutlined;

import wretch from 'wretch';
import {unzipb64Payload} from '../@webapp-lib/pathspot-react';
const controller = new AbortController();

export const abortController = controller;

const w = wretch()
  .content('application/json')
  .catcher('Error', (err) => {
    console.log('Error occured: ', err);
    throw err;
  })
  .catcher('TypeError', (err) => {
    console.log('TypeError occured: ', err);
    throw err;
  })
  .catcher(401, (err) => {
    console.log('401 Error occured: ', err);
    throw err;
  })
  .catcher(500, (err) => {
    console.log('500 Error occured: ', err);
    throw err;
  });
export const api = {
  token: '',
  abortController: new AbortController(),

  setToken: function (token: string) {
    this.token = token;
  },

  noAuth: function () {
    return w;
  },

  withAuth: function () {
    // console.log('Using with auth...', this);
    return w.auth(this.token).signal(this.abortController);
  },

  withAuthAndNoSignal: function () {
    return w.auth(this.token);
  },

  abortAllRequests: function () {
    try {
      this.abortController.abort();
    } catch (e) {
      console.log('Catching an uncaught abort exception.');
    }
    this.abortController = new AbortController(); //new controller needs to be set up to abort future requests
  },
  zjson: function (json: any) {
    // console.log('12345678910111213141516------- json: ', json);
    // console.log('json.data', json.data);
    const unzippedData = unzipb64Payload(json.data);
    console.log('unizipped payload', unzippedData);
    //console.log('12345678910111213141516+++++++ In wretcher process.env.REACT_APP_API_URL: ', process.env);
    return json && json.data ? {data: unzippedData ? unzippedData : json.data} : json;
  },
};

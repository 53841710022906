import React from 'react';
import {CCardText, CCardTitle} from '@coreui/react';

const CardMessage = (props: any) => {
  const {messageTitle, messageText, title, message, componentKey} = props;
  let itemsToRender = [];
  let renderMessage = messageText ? messageText : message;
  let renderTitle = messageTitle ? messageTitle : title;
  if (renderTitle) {
    itemsToRender.push(
      <CCardTitle key={`${componentKey}-cardmessagetitle`} className="mb-3">
        {renderTitle}
      </CCardTitle>
    );
  }
  if (renderMessage) {
    itemsToRender.push(
      <CCardText key={`${componentKey}-cardmessagetext`} className="mb-3">
        {renderMessage}
      </CCardText>
    );
  }
  return <span>{itemsToRender}</span>;
};
export default CardMessage;

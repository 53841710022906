export const NEW_API_URL = process.env.REACT_APP_NEW_API_URL;
export const CUSTOMERS_API_URL = `${NEW_API_URL}/customers`;
export const EMPLOYEES_API_URL = `${NEW_API_URL}/employees`;
export const USERS_API_URL = `${NEW_API_URL}/users`;

export const LOCATIONS_API_URL = `${NEW_API_URL}/locations`;
export const LOCATIONS_SHORT_API_URL = `${NEW_API_URL}/locations-short`;
export const LOCATION_GROUPS_API_URL = `${NEW_API_URL}/location-groups`;
export const STATIONS_API_URL = `${NEW_API_URL}/stations`;
export const DEPARTMENTS_API_URL = `${NEW_API_URL}/locations-departments`;

export const GOALS_SETTING_API_URL = `${NEW_API_URL}/goals`;
export const FORMS_API_URL = `${NEW_API_URL}/forms`;
export const LOGISTICS_API_URL = `${NEW_API_URL}/orders`;
export const HARDWARE_API_URL = `${NEW_API_URL}/hardware`;

export const WEEKLY_REPORTING_API_URL = `${NEW_API_URL}/weekly-reporting`;
export const DAILY_REPORTING_API_URL = `${NEW_API_URL}/daily-reporting`;
export const LOCATION_REPORTING_API_URL = `${NEW_API_URL}/location-reporting`;

export const LOG_IN_API_URL = `${NEW_API_URL}/auth/login`;
export const REFRESH_TOKEN_API_URL = `${NEW_API_URL}/auth/refresh`;
export const CHANGE_MY_CURRENT_PASSWORD_API_URL = `${NEW_API_URL}/auth/change-my-password`;
export const SET_NEW_ADMIN_PASSWORD_API_URL = `${NEW_API_URL}/users/admin-password`;

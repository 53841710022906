import CIcon from '@coreui/icons-react';
import { CButton, CCardHeader, CCard, CCardBody, CCol, CForm, CInput, CInputGroup, CInputGroupPrepend, CInputGroupText, CInvalidFeedback, CRow, CButtonToolbar, CInputGroupAppend, CSwitch, CFormGroup, CInputCheckbox, CLabel, CLink, CCollapse, CTextarea, CSpinner } from '@coreui/react';
import React, { useState } from 'react';





export const SpinnerIcon = (props: any) => {
    
    const { iconName, iconClassName, spinnerSize, spinnerState } = props
    
    if (spinnerState) {
        // console.log("spinnerState is true")
        return (
            <CSpinner className="mr-2" size={spinnerSize} />
        )
    } else {
        // console.log("spinnerState is false.")
        return (
            < CIcon name={iconName} className={iconClassName} />
        )
    }
}
export default SpinnerIcon
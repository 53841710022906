import {CInputGroup, CRow} from '@coreui/react';
// @ts-ignore
import React from 'react';
import {CInputGroupRowPrepend, CInputGroupRowAppend} from '../..';
import {selectorStyle} from '../../styles/styling';
//@ts-ignore
import Select from 'react-select';
import {v4 as uuidv4} from 'uuid';
const uuidVal = uuidv4();

const FSelectorGroupRow = (props: any) => {
  const {
    fieldName,
    iconStart,
    displayStr,
    placeholderStr,
    iconEnd,
    endStr,
    options,
    selectorOptions,
    setFieldValue,
    style,
    borderStyle,
    handleChange,
    values,
    touched,
    errors,
    allowEdit,
  } = props;
  return (
    <CRow className="mb-3">
      <CInputGroup>
        <CInputGroupRowPrepend iconStart={iconStart} displayStr={displayStr} />
        <Select
          key={`${fieldName}-${uuidVal}`}
          isDisabled={typeof allowEdit === 'boolean' ? !allowEdit : false}
          name={fieldName}
          placeholder={placeholderStr}
          options={options || selectorOptions}
          // onChange={(val: any) => {
          //   handleChange(val)
          // }}
          onChange={(val: any) => setFieldValue(fieldName, val)}
          value={values[fieldName]}
          styles={selectorStyle}
          classNamePrefix="react-selector"
          invalid={touched[fieldName] && errors[fieldName] ? true : false}
        />
        <CInputGroupRowAppend iconEnd={iconEnd} endStr={endStr} />
      </CInputGroup>
    </CRow>
  );
};
export default FSelectorGroupRow;

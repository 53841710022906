import {CRow, CCol} from '@coreui/react';
import React, {useState, useEffect} from 'react';

const styles = {
  container: (base: any) => ({
    ...base,
    flex: 1,
  }),
};

const AnoNoConPreview = (props: any) => {
  const {data, customText} = props;
  if (!data) {
    return null;
  }

  return (
    <div>
      <CRow className="text-center justify-content-center my-4">
        <CCol md="5">
          <strong>Location Name</strong>
        </CCol>
        <CCol md="7">{data.locName}</CCol>
        <CCol md="5">
          <strong>Date Range</strong>
        </CCol>
        <CCol md="7">{data.dateRange}</CCol>
        <CCol md="5">
          <strong>Handwashing Activity</strong>
        </CCol>
        <CCol md="7">{data.scanCount}</CCol>
      </CRow>

      <CRow className="text-center justify-content-center my-4">
        <CCol md="12">
          <div dangerouslySetInnerHTML={{__html: data.avgDailyScanCountSection}}></div>
        </CCol>
      </CRow>

      <CRow className="text-center justify-content-center my-4">
        <CCol md="12">{customText}</CCol>
      </CRow>

      <CRow className="text-center justify-content-center my-4">
        <CCol md="12">
          <div dangerouslySetInnerHTML={{__html: data.disconnectedStationsSection}}></div>
        </CCol>
      </CRow>

      <CRow className="text-center justify-content-center my-4">
        <CCol md="4">
          <strong>Handwashing Activity</strong>
        </CCol>
        <CCol md="3">
          <strong>This Period</strong>
        </CCol>
        <CCol md="3">
          <strong>Last Period</strong>
        </CCol>
        <CCol md="2">
          <strong>Change</strong>
        </CCol>

        <CCol md="4">Average first handwash of the day</CCol>
        <CCol md="3">{data.avgFirstScanOfDayTp}</CCol>
        <CCol md="3">{data.avgFirstScanOfDayLp}</CCol>
        <CCol md="2">{data.avgFirstScanOfDayCh}</CCol>

        <CCol md="4">Average last handwash of the day</CCol>
        <CCol md="3">{data.avgLastScanOfDayTp}</CCol>
        <CCol md="3">{data.avgLastScanOfDayLp}</CCol>
        <CCol md="2">{data.avgLastScanOfDayCh}</CCol>
      </CRow>

      <CRow className="text-center justify-content-center my-4">
        <CCol md="4">
          <strong>Gaps with zero recorded handwashes during open hours</strong>
        </CCol>
        <CCol md="3">
          <strong>This Period</strong>
        </CCol>
        <CCol md="3">
          <strong>Last Period</strong>
        </CCol>
        <CCol md="2">
          <strong>Change</strong>
        </CCol>

        <CCol md="4">Gaps of 4+ Hours</CCol>
        <CCol md="3">{data.gaps4hTp}</CCol>
        <CCol md="3">{data.gaps4hLp}</CCol>
        <CCol md="2">{data.gaps4hCh}</CCol>

        <CCol md="4">Gaps of 3+ Hours</CCol>
        <CCol md="3">{data.gaps3hTp}</CCol>
        <CCol md="3">{data.gaps3hLp}</CCol>
        <CCol md="2">{data.gaps3hCh}</CCol>

        <CCol md="4">Gaps of 2+ Hours</CCol>
        <CCol md="3">{data.gaps2hTp}</CCol>
        <CCol md="3">{data.gaps2hLp}</CCol>
        <CCol md="2">{data.gaps2hCh}</CCol>
      </CRow>
    </div>
  );
};

export default AnoNoConPreview;

//@ts-ignore
import pick from 'lodash/pick';
//@ts-ignore
import {ENV} from '../../constants/generic';
import _ from 'lodash';
const zlib = require('zlib');

export const removeProperty = (key: string, {[key]: value, ...restObject}) => restObject;

export const deepCloneProperty = (key: string, obj: any) => {
  return pick(obj, key);
};
export const mergeObjects = (source: any, target: any) => {
  return Object.assign(target, source);
};
export const emptyField = (attr: any) => {
  switch (typeof attr) {
    case 'object':
      if (attr === null) return true;
      if (attr.length === 0) return true;
      if (Object.keys(attr).length === 0) return true;
      return false;
    case 'string':
      if (attr === '') return true;
      return false;
    case 'number':
      if (isNaN(attr)) return true;
      return false;
    case 'undefined':
      return true;
    case 'boolean':
      return false;
    default:
      return false;
  }
};

export const getNonEmptyArray = (obj: any, fieldName: string, fallback: any) => {
  return obj && obj[fieldName] && obj[fieldName].length > 0 ? obj[fieldName] : fallback;
};

export const allFieldsEmpty = (object: any) => {
  return Object.keys(object).every((key: any) => emptyField(object[key]));
};

export const mapValueFromKeyMatch = (targetObject: any, sourceObject: any) => {
  return Object.fromEntries(
    Object.entries(sourceObject).map(([key, value]: any) => {
      let targetkey = Object.keys(targetObject).find((newkey: any) => key.toLowerCase().search(newkey.toLowerCase()) >= 0);
      return targetkey && typeof targetkey === 'string' ? [[key], targetObject[targetkey]] : [[key], value];
    })
  );
};

export const replaceObjectFieldsKVP = (targetObject: any, keyValuePairs: any) => {
  return Object.fromEntries(
    Object.entries(targetObject).map(([key, value]: any) => {
      const keyIdx = keyValuePairs.keys.indexOf(key);
      return keyIdx >= 0 ? [key, keyValuePairs.values[keyIdx]] : [key, value];
    })
  );
};

export const replaceEmptyObjectFields = (sourceObject: any, replacementValue: any) => {
  return Object.fromEntries(
    Object.entries(sourceObject).map(([key, value]: any) => {
      const testObject = {[key]: value};
      //console.log('Test object is:', testObject);
      const returnVal = emptyField(value) ? [key, replacementValue] : [key, value];
      //console.log('Return value is:  ', returnVal);
      return returnVal;
    })
  );
};

export const objectHasField = (targetObject: any, fieldPathList: any): boolean => {
  //console.log("77.1-]]]] ObjectHasField? targetObject: ", cloneDeep(targetObject))
  //console.log("77.2-]]]] ObjectHasField? fieldPathList: ", cloneDeep(fieldPathList))
  if (targetObject) {
    if (fieldPathList && fieldPathList.length > 1) {
      const newTargetObject = targetObject[fieldPathList.shift()];
      return objectHasField(newTargetObject, fieldPathList);
    } else if (fieldPathList.length === 1) {
      let objectDefined = targetObject[fieldPathList.shift()];
      return objectDefined === undefined ? false : true;
    } else {
      //should never get here, means list length is 0
      return false;
    }
  } else {
    return false;
  }
};

export const setFieldValue = (value: any, fieldPath: any, targetObject: any) => {
  let objectClone = _.cloneDeep(targetObject);
  if (fieldPath.includes('.')) {
    const fieldPathList = _.cloneDeep(fieldPath.split('.'));
    const subField: any = fieldPathList.shift();
    const newValue: any = setFieldValue(value, fieldPathList, targetObject[subField]);
    console.log('setFieldValue params: ', [newValue, subField, fieldPathList, objectClone]);
    return newValue;
  } else {
    return {...objectClone, [fieldPath]: value};
  }
};

export const getValueFromFieldPath = (baseObject: any, baseFieldPath: any): any => {
  const targetObject = _.cloneDeep(baseObject);
  const fieldPathList = _.cloneDeep(baseFieldPath);
  if (targetObject) {
    if (fieldPathList && fieldPathList.length > 1) {
      const newTargetObject = targetObject[fieldPathList.shift()];
      return getValueFromFieldPath(newTargetObject, fieldPathList);
    } else if (fieldPathList.length === 1) {
      let objectDefined = targetObject[fieldPathList.shift()];
      return objectDefined;
    } else {
      //should never get here, means list length is 0
      return undefined;
    }
  } else {
    return undefined;
  }
};
export const getObjectFromFieldPath = (targetObject: any, fieldPathList: any): any => {
  if (fieldPathList.length > 1) {
    const newTargetObject = targetObject[fieldPathList.shift()];
    //console.log("88.0-]]]] Path list is > 1, New target object is: ", cloneDeep(newTargetObject))
    return getObjectFromFieldPath(newTargetObject, fieldPathList);
  } else if (fieldPathList.length === 1) {
    //console.log("88.1-]]]] Path list === 1, target object is: ", cloneDeep(targetObject))
    //console.log("88.2-]]]] Path list === 1, fieldPathList is: ", cloneDeep(fieldPathList))
    return {
      returnObject: targetObject,
      field: fieldPathList.shift(),
    };
  } else {
    //console.log("88.3-]]]] Path list === 0, target object is: ", cloneDeep(targetObject))
    return {
      returnObject: targetObject,
      field: undefined,
    };
  }
};
export const strList2CommaSpaceStr = (items: any) => {
  return `${items
    .map((item: any) => {
      return item && item.label ? `${item.label}` : `${item}`;
    })
    .join(', ')}`;
};

export const unzipb64Payload = (payload: any) => {
  try {
    const decodedPayload = Buffer.from(payload, 'base64');
    //console.log('0.0.2-]]]] unzipb64Payload: decodedPayload: ', decodedPayload);
    //const restoredBuffer = zlib.unzipSync(decodedPayload, zlibCallback);
    const restoredBuffer = zlib.unzipSync(decodedPayload);

    //console.log('0.0.3-]]]] unzipb64Payload: restoredBuffer: ', restoredBuffer);
    const restoredJsonString = Buffer.from(restoredBuffer).toString('utf-8');
    //console.log('0.0.4-]]]] unzipb64Payload:restoredJsonString is: ', restoredJsonString);
    const restoredJson = JSON.parse(restoredJsonString);
    // console.log('0.0.5-]]]] unzipb64Payload: restoredJson is: ', restoredJson);
    return restoredJson;
  } catch (e) {
    const err: any = e;
    if (process.env.NODE_ENV === ENV.DEV) console.log('Error unzipping payload...', [err.toString(), payload]);
    return undefined;
  }
  return payload;
};
export const parseParamString = (paramString: string) => {
  switch (paramString) {
    case 'false':
      return false;
    case 'true':
      return true;
    case 'null':
      return null;
    default:
      return parseInt(paramString);
  }
};
export const parseParam = (param: any, searchString: any) => {
  const paramSplit = searchString.split(param);
  if (paramSplit && paramSplit.length > 1) {
    const terminatedValue = paramSplit[1].split('=');
    if (terminatedValue && terminatedValue.length > 1) {
      const paramValue = terminatedValue[1].split('&');
      if (paramValue && paramValue.length > 0) {
        // console.log('Param and value is: ', [param, paramValue[0]]);
        return paramValue[0];
      }
    } else {
      // console.log('Param has no value:', [param, terminatedValue]);
    }
  } else {
    // console.log('Param not found: ', [param, paramSplit]);
  }
  return undefined;
};

//= Object.keys(errors).some((key: string) => errors[key] && errors[key].length > ˆ∆)
// Object.keys(eventDetails).forEach((key: string) => errors[key] = []

// export const cloneProperty = (key: string, { [key]: value, ...restObject }) => { return { [key]: value } };

// const clonePropertyA = (targetKey: string, restObject: any) => {
//     let newObject = { ...restObject }
//     return newObject[targetKey]
// }

// return Object.fromEntries(Object.entries(restObject).map(([key, value]: any) => {
//     if (key === targetKey) {
//         return [key, value]
//     }
// }
// )
// )

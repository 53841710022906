import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import PLink from '../link/PLink';
import './buttons.scss';
//component - CoreUI / PButton

const PButton = (props: any) => {
  let {className, innerRef, onClick, disabled, active, block, color, size, pressed, shape, variant, buttonType, ...attributes} = props;

  const click = (e: any) => !disabled && onClick && onClick(e);

  const isLink = attributes.to || attributes.href;

  //render
  const classes = classNames(
    className,
    'btn',
    variant || color ? `btn${variant ? '-' + variant : ''}-${color}` : false,
    size ? `btn-${size}` : false,
    block ? 'btn-block' : false,
    shape ? `btn-${shape}` : false,
    pressed ? 'btn-pressed' : false,
    {active: active && !isLink, disabled: disabled && !isLink}
  );
  // console.log('PButton props are: ', props);
  // console.log('Attributes are: ', attributes);
  // console.log('isLink button status: ', isLink);
  if (isLink) {
    return <PLink {...attributes} active={active} disabled={disabled} className={classes} onClick={click} innerRef={innerRef} />;
  } else {
    return <button className={classes} type={buttonType || 'button'} disabled={disabled} {...attributes} onClick={click} ref={innerRef} />;
  }
};

export default PButton;

import * as React from 'react';

function PathSpotTimeRaw(props: any) {
  const {primary_color, secondary_color, tertiary_color, quaternary_color, className, viewBox, width, height} = props;
  return (
    <svg viewBox={viewBox || '0 0 510.411 510.411'} className={className || 'p-icon-5xl'}>
      <path fill="none" stroke={secondary_color} strokeLinecap="round" strokeLinejoin="round" strokeWidth={38.411} d="M255.206 285.205V150.534M318.661 316.971l-63.455-31.766" />
      <circle cx={255.206} cy={255.206} r={236} fill="none" stroke={primary_color} strokeLinecap="round" strokeLinejoin="round" strokeWidth={38.411} />
    </svg>
  );
}

const PathSpotTime = React.memo(PathSpotTimeRaw);
export default PathSpotTime;

import React from 'react';
import {CCol, CRow, CAlert} from '@coreui/react';
import {v4 as uuidv4} from 'uuid';

const componentKey = uuidv4();
const FAlertRow = (props: any) => {
  const {errors, rowClass, alertDivClass, alertColor, fieldName} = props;
  if (!(errors && errors[fieldName])) {
    return <div></div>;
  }

  let errorList: any[] = errors.length === 1 ? [...errors] : [errors];
  //Check if error is a string or TypedError
  return (
    <div key={componentKey} className={rowClass || alertDivClass || 'justify-content-center mt-3'}>
      {errorList.map((error: any) => (
        <CAlert className="mb-0" color={alertColor || 'danger'}>
          {errors[fieldName]}
        </CAlert>
      ))}
    </div>
  );
};
export default FAlertRow;

import { ModalFooterType } from './Types';
import { cancelButtonLight, continueButtonWarn, confirmButton } from '../../components/buttons/button-styles'

export const defaultModalState = {
    id: null,
    type: null,
    display: false,
    handles: {},
    dispatches: {},
    kwargs: {}
};

// export const cancelContinueFooter = {
//     type: ModalFooterType.CANCEL_CONTINUE_BUTTONS,
//     buttons: {
//         continue: { ...continueButtonWarn },
//         cancel: { ...cancelButtonLight }
//     }
// }

// export const modalConfirmButton = {
//     type: ModalFooterType.CONFIRM_BUTTON,
//     buttons: {
//         confirm: { ...confirmButton }
//     }
// }
import * as React from 'react';

function PathSpotWaterDropRaw(props: any) {
  const {primary_color, secondary_color, tertiary_color, quaternary_color, className, viewBox, width, height} = props;
  return (
    <svg viewBox={viewBox || '0 0 466.944 510.517'} className={className || 'p-icon-5xl'}>
      <path
        d="M346.859 215.132c0 64.807-48.775 117.354-113.549 117.354s-113.55-52.547-113.55-117.354c0-95.762 113.55-198.874 113.55-198.874S346.859 119.37 346.859 215.132z"
        fill="none"
        stroke={primary_color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={32.517}
      />
      <path
        d="M398.659 315.22c32.517 18.957 52.027 43.54 52.027 70.432 0 59.961-97.258 108.606-217.214 108.606S16.259 445.483 16.259 385.654c0-26.892 19.51-51.475 51.864-70.432"
        fill="none"
        stroke={secondary_color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={32.517}
      />
      <path
        d="M359.346 372.059c-20.779 24.518-69.392 41.752-126.036 41.752-50.369 0-94.3-13.624-118.135-33.882"
        fill="none"
        stroke={secondary_color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={32.517}
      />
      <path d="M219.49 249.731c-32.289-24.291-15.738-65.034-.553-93.78" fill="none" stroke={primary_color} strokeLinecap="round" strokeLinejoin="round" strokeWidth={32.517} />
    </svg>
  );
}

const PathSpotWaterDrop = React.memo(PathSpotWaterDropRaw);
export default PathSpotWaterDrop;

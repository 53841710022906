import React from 'react';
import {CAlert} from '@coreui/react';

const PAlertErrors = (props: any) => {
  const {errors, fieldName} = props;
  const errorList = errors && errors[fieldName] ? errors[fieldName] : errors;

  if (errorList && errorList.length > 0) {
    return (
      <div className="mb-2">
        {errorList.map((error: any) => {
          return <div className="custom-invalid-field-feedback">{error}</div>;
        })}
      </div>
    );
  } else {
    return <div></div>;
  }
};
export default PAlertErrors;

import {createSlice} from '@reduxjs/toolkit';
import {AnswerModel, SurveyContext, SurveyScope, SurveyAssignment, SurveyForm, QuestionModel, RequestType} from '../../modules/surveys/safety-forms/safety-form.api';
import _ from 'lodash';
import {REQUEST_STATUS, stateReducer} from '../../@webapp-lib/pathspot-react';

export const QuestionOptions = {
  existing: 'existing',
  new: 'new',
};

export enum MODALSTATE {
  originCreateNew = 'originCreateNew',
  findAnother = 'findAnother',
  useExistingForm = 'useExistingForm',
  addQuestion = 'addQuestion',
  deleteQuestion = 'deleteQuestion',
  editAnswer = 'editAnswer',
  saveForm = 'saveForm',
  assignForm = 'assignForm',
  success = 'success',
}
export interface ModalState {
  [key: string]: boolean;
  originCreateNew: boolean;
  findAnother: boolean;
  useExistingForm: boolean;
  addQuestion: boolean;
  deleteQuestion: boolean;
  editAnswer: boolean;
  saveForm: boolean;
  assignForm: boolean;
  success: boolean;
}

export interface SurveyFormState {
  initialFormState?: SurveyForm;
  lastLocation?: any;
  requestStatus?: REQUEST_STATUS;
  nextAction?: any;
  nextPayload?: any;
}

export interface SurveyPanelState {
  context: SurveyContext;
  assignedForms: any;
  surveyForm: SurveyForm;
  surveyFormState: SurveyFormState;
  modalState: ModalState;
}
type args = {type?: string; [key: string]: any};

export const defaultModalState = {
  originCreateNew: false,
  findAnother: false,
  useExistingForm: false,
  addQuestion: false,
  deleteQuestion: false,
  editAnswer: false,
  saveForm: false,
  assignForm: false,
  success: false,
};
const defaultSurveyForm: SurveyForm = {
  formId: undefined,
  formScope: SurveyScope.UNKNOWN,
  customer: undefined,
  title: '',
  acceptableMessage: '',
  unacceptableMessage: '',
  questions: undefined,
  asignees: undefined,
};
const defaultSurveyFormState: SurveyFormState = {
  initialFormState: undefined,
  lastLocation: undefined,
  requestStatus: REQUEST_STATUS.UNDEFINED,
  nextAction: undefined,
};
const initialState: SurveyPanelState = {
  // reset: false,
  context: SurveyContext.UNKNOWN,
  assignedForms: undefined,
  surveyForm: defaultSurveyForm,
  surveyFormState: defaultSurveyFormState,
  modalState: {...defaultModalState},
};

const mapAnswerPayload = (answer: any) => {
  const answerModel: AnswerModel = {};
  answerModel.answerText = {
    en: answer.answerText || '',
    id: answer.answerTextId || null,
  };

  answerModel.answerTruth = answer.answerTruth ? answer.answerTruth : answer.answerTruth === false ? false : '-';
  return {...answerModel};
};
const mapQuestionPayload = (question: any, qIdx: any) => {
  const questionModel: QuestionModel = {};
  questionModel.answers = question.answers && question.answers.length > 0 ? question.answers : [];
  questionModel.customer = question && question.customerId ? {id: question.customerId} : undefined;
  questionModel.questionText = {
    id: question.questionTextId || '',
    customerId: question.customerId,
    en: question.questionText,
  };
  questionModel.questionType = question.questionType || null;
  questionModel.id = question.id || null;
  questionModel.answers = question.answers.map((answer: any) => {
    return mapAnswerPayload(answer);
  });
  questionModel.idx = qIdx++;
  console.log('!!! Newly mapped question model is:', questionModel);
  return {...questionModel};
};

const setFormDetails = (surveyForm: SurveyForm, action: any, context: any) => {
  let qIdx: number = 0;
  const emptyQuestion: QuestionModel = {};
  surveyForm.acceptableMessage = action.payload?.acceptableMessage || surveyForm.acceptableMessage;
  surveyForm.unacceptableMessage = action.payload?.unacceptableMessage || surveyForm.unacceptableMessage;
  surveyForm.formId = action.payload?.id || surveyForm.formId;
  surveyForm.title = action.payload?.title || surveyForm.title;
  const customerId = action.payload?.customerId || surveyForm.customer?.id;
  const customerName = action.payload?.customerName || surveyForm.customer?.name;
  const customerLabel = `${customerId} - ${customerName}`;
  surveyForm.customer = {
    id: customerId,
    name: customerName,
    menu: {
      label: customerLabel,
      value: customerId,
    },
  };
  surveyForm.questions =
    action.payload?.questionModelDetails && action.payload?.questionModelDetails.length > 0
      ? action.payload.questionModelDetails.map((question: any) => {
          return mapQuestionPayload(question, qIdx);
        })
      : action.payload?.questionModelIds && action.payload?.questionModelIds.length > 0
      ? action.payload.questionModelIds.map((questionId: any) => {
          const questionModel: QuestionModel = {};
          questionModel.id = questionId || null;
          questionModel.idx = qIdx++;
          return {...questionModel};
        })
      : [emptyQuestion];
  //Customer ID 1 is considered public
  surveyForm.formScope = customerId ? (customerId !== 1 ? SurveyScope.PRIVATE : SurveyScope.PUBLIC) : SurveyScope.PRIVATE;
  // return { ...state, reset: false, surveyForm: { ...surveyForm } };
  return {surveyForm: {...surveyForm}, scope: customerId ? SurveyScope.PRIVATE : SurveyScope.PUBLIC};
};

export const surveyKey = 'survey/';

export enum SurveyActions {
  setState = 'setState',
  setLocationParams = 'setLocationParams',
  setExistingSurveyDetails = 'setExistingSurveyDetails',
  setModalState = 'setModalState',
  updateModalSetContext = 'updateModalSetContext',
  resetModalState = 'resetModalState',
  clearSetModal = 'clearSetModal',
  startNew = 'startNew',
  clearReset = 'clearReset',
  setLastLocation = 'setLastLocation',
  clearLastLocation = 'clearLastLocation',
  duplicateForm = 'duplicateForm',
  deleteQuestion = 'deleteQuestion',
  setFormFieldValue = 'setFormFieldValue',
  startSave = 'startSave',
  setAssignedSurveys = 'setAssignedSurveys',
  setRequestStatus = 'setRequestStatus',
  addQuestion = 'addQuestion',
}

export const SurveyDispatch = {
  [SurveyActions.setState]: `${surveyKey}${SurveyActions.setState}`,
  [SurveyActions.setLocationParams]: `${surveyKey}${SurveyActions.setLocationParams}`,
  [SurveyActions.setExistingSurveyDetails]: `${surveyKey}${SurveyActions.setExistingSurveyDetails}`,
  [SurveyActions.setModalState]: `${surveyKey}${SurveyActions.setModalState}`,
  [SurveyActions.updateModalSetContext]: `${surveyKey}${SurveyActions.updateModalSetContext}`,
  [SurveyActions.resetModalState]: `${surveyKey}${SurveyActions.resetModalState}`,
  [SurveyActions.clearSetModal]: `${surveyKey}${SurveyActions.clearSetModal}`,
  [SurveyActions.startNew]: `${surveyKey}${SurveyActions.startNew}`,
  [SurveyActions.clearReset]: `${surveyKey}${SurveyActions.clearReset}`,
  [SurveyActions.setLastLocation]: `${surveyKey}${SurveyActions.setLastLocation}`,
  [SurveyActions.clearLastLocation]: `${surveyKey}${SurveyActions.clearLastLocation}`,
  [SurveyActions.duplicateForm]: `${surveyKey}${SurveyActions.duplicateForm}`,
  [SurveyActions.deleteQuestion]: `${surveyKey}${SurveyActions.deleteQuestion}`,
  [SurveyActions.setFormFieldValue]: `${surveyKey}${SurveyActions.setFormFieldValue}`,
  [SurveyActions.startSave]: `${surveyKey}${SurveyActions.startSave}`,
  [SurveyActions.setAssignedSurveys]: `${surveyKey}${SurveyActions.setAssignedSurveys}`,
  [SurveyActions.setRequestStatus]: `${surveyKey}${SurveyActions.setRequestStatus}`,
  [SurveyActions.addQuestion]: `${surveyKey}${SurveyActions.addQuestion}`,
};
export const surveySlice = createSlice({
  name: 'survey',
  initialState,
  reducers: {
    [SurveyActions.setState]: (state: SurveyPanelState, {type, ...rest}: args) => {
      return {...state, ...rest};
    },
    // setQuestionIdx: (state: SurveyPanelState, action: any) => {
    //   const prevIdx: number = action.payload?.prevIdx;
    //   const newIdx: number = action.payload?.newIdx;
    //   state.surveyForm?.questions?.forEach((item: any) => {
    //     item.idx = item.idx === prevIdx ? newIdx : item.idx;
    //   });
    //   return {...state};
    // },
    [SurveyActions.setLocationParams]: (state: SurveyPanelState, action: any) => {
      console.log('Action in setLocationParamsReducer is:', action);
      const detailed = action.payload?.detailed ? action.payload.detailed : false;
      const scope = action.payload?.customerId ? SurveyScope.PRIVATE : SurveyScope.PUBLIC;
      const customerId = scope === SurveyScope.PRIVATE ? action.payload.customerId : null;
      const formId = action.payload?.formId || null;
      let surveyForm: SurveyForm = {};
      if (detailed === false && customerId === null && formId === null) {
        surveyForm = {formScope: SurveyScope.UNKNOWN};
      } else {
        surveyForm = {formId: formId, formScope: scope, customer: {id: customerId}};
      }
      return {...state, scope: scope, surveyForm: {...surveyForm}};
    },
    [SurveyActions.setExistingSurveyDetails]: (state: SurveyPanelState, action: any) => {
      const surveyForm: SurveyForm = {...state.surveyForm};
      const context = state.context;
      const newDetails = setFormDetails(surveyForm, action, context);
      return {...state, surveyForm: newDetails.surveyForm, scope: newDetails.scope};
    },
    [SurveyActions.setAssignedSurveys]: (state: SurveyPanelState, action: any) => {
      return {...state, assignedForms: {...action.payload}};
    },

    [SurveyActions.setModalState]: (state: SurveyPanelState, action: any) => {
      state.modalState = {...state.modalState, ...action.payload};
      return state;
    },
    // [SurveyActions.updateModalSetContext]: (state: SurveyPanelState, action: any) => {
    //   state.modalState = {...state.modalState, ...action.payload.modal};
    //   // state.context = action.payload.context;
    //   return state;
    // },
    [SurveyActions.resetModalState]: (state: SurveyPanelState, action: any) => {
      state.modalState = {...defaultModalState};
      return state;
    },
    [SurveyActions.clearSetModal]: (state: SurveyPanelState, action: any) => {
      state.modalState = {...defaultModalState, ...action.payload};
      return state;
    },
    [SurveyActions.startNew]: (state: SurveyPanelState, action: any) => {
      // state.surveyForm = {...state.surveyForm, formScope: SurveyScope.PUBLIC};
      state = {...initialState, context: SurveyContext.NEW, surveyForm: {...initialState.surveyForm, formScope: SurveyScope.PUBLIC}};
      return state;
    },
    // [SurveyActions.clearReset]: (state: SurveyPanelState, action: any) => {
    //   state = {...state, reset: false};
    //   return state;
    // },
    [SurveyActions.setLastLocation]: (state: SurveyPanelState, action: any) => {
      state.surveyFormState = {...state.surveyFormState, lastLocation: action.payload};
      return state;
    },
    [SurveyActions.clearLastLocation]: (state: SurveyPanelState, action: any) => {
      state.surveyFormState = {...state.surveyFormState, lastLocation: null};
      state.modalState = {...defaultModalState};
      return state;
    },
    [SurveyActions.duplicateForm]: (state: SurveyPanelState, action: any) => {
      state.modalState = {...defaultModalState};
      state.context = SurveyContext.REPLICATING;
      state.surveyForm = {...state.surveyForm, formId: undefined, title: ''};
      return state;
    },
    [SurveyActions.deleteQuestion]: (state: SurveyPanelState, action: any) => {
      // console.log('In delete question, action is: ', action);
      if (typeof action.payload === 'string') {
        const newQuestionList = _.cloneDeep(
          state.surveyForm?.questions?.filter((question: any) => {
            const qId = action.payload.split('discard-');
            console.log('Qid in reducer is: ', qId);
            return question.id !== parseInt(qId[1]);
          })
        );
        // console.log('New question list is: ', newQuestionList);
        state.surveyForm = {...state.surveyForm, questions: newQuestionList};
        return state;
      }
      return state;
    },
    [SurveyActions.setFormFieldValue]: (state: SurveyPanelState, action: any) => {},
    [SurveyActions.startSave]: (state: SurveyPanelState, action: any) => {
      state.modalState = {...defaultModalState, [MODALSTATE.saveForm]: true};
      // state.surveyFormState = {...action.payload};
      return state;
    },
    [SurveyActions.setRequestStatus]: (state: SurveyPanelState, action: any) => {
      console.log('State, action in set request status reducer is: ', state, action);
      console.log('State action payload is:', action.payload.requestType);
      const surveyForm: SurveyForm = {...state.surveyForm};
      const context = state.context;
      const newDetails = setFormDetails(surveyForm, action, context);
      if (action.payload.requestType === RequestType.CREATE_NEW_FROM) {
        console.log('State case is action.payload.requestType', action.payload.requestType);
        if (action?.payload?.returnValue?.data?.length > 0 && action.payload.returnValue?.data[0]?.id) {
          const newActionPayload = {...action.payload.returnValue.data[0]};
          console.log('Action payload exists', newActionPayload);
          const newFormValues = setFormDetails(surveyForm, {payload: newActionPayload}, state.context).surveyForm;
          console.log('Old form values are: ', _.cloneDeep(state.surveyForm));
          console.log('New form values are:', _.cloneDeep(newFormValues));
          console.log('New form context is:', _.cloneDeep(state.context));

          state.surveyForm = {...newFormValues};
          state.surveyFormState.requestStatus = REQUEST_STATUS.SUCCESS;
          state.surveyFormState.nextAction = {...action.payload.onSuccess};
          state.surveyFormState.nextPayload = {requestType: RequestType.CREATE_NEW_FROM};
          state.modalState = {...state.modalState, success: true};
        } else {
          state.surveyFormState.requestStatus = REQUEST_STATUS.FAIL;
          state.surveyFormState.nextAction = action.payload.onFail;
          state.modalState = {...state.modalState, fail: true};
        }
      }
      return state;
    },
    [SurveyActions.addQuestion]: (state: SurveyPanelState, action: any) => {
      console.log('In redux add question: ', [_.cloneDeep(state), _.cloneDeep(action)]);
      const listLength = state.surveyForm.questions?.length;
      const mappedQuestion = mapQuestionPayload(action.payload.question, listLength ? listLength + 1 : 0);
      const currentQuestions = state.surveyForm.questions;
      if (currentQuestions && currentQuestions.length > 0) {
        state.surveyForm.questions = [...currentQuestions, mappedQuestion];
      } else {
        state.surveyForm.questions = [mappedQuestion];
      }
      return state;
    },
  },
});

export const {setState} = surveySlice.actions;

export default surveySlice.reducer;

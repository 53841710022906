import * as React from 'react';
import {isPrimitive} from 'util';

function PathSpotGermRaw(props: any) {
  const {primary_color, secondary_color, tertiary_color, quaternary_color, className, viewBox, width, height} = props;

  return (
    <svg viewBox={viewBox || '0 0 364.742 507.932'} className={className || 'p-icon-5xl'}>
      <path
        fill="none"
        stroke={quaternary_color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={34.86}
        d="M151.605 151.64L86.522 86.557M420.303 420.303L355.22 355.22M151.64 355.255l-65.083 65.048M420.303 86.557l-65.083 65.048"
      />
      <circle cx={288.638} cy={288.638} r={35.208} fill={quaternary_color} />
      <circle cx={270.72} cy={196.887} r={24.297} fill={quaternary_color} />
      <path
        fill="none"
        stroke={primary_color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={34.86}
        d="M253.395 109.459V17.43M253.43 489.43V397.4M109.459 253.43H17.43M489.43 253.43H397.4"
      />
      <circle cx={206.962} cy={259.844} r={35.208} fill={quaternary_color} />
      <circle cx={253.43} cy={253.43} r={136.615} fill="none" stroke={primary_color} strokeLinecap="round" strokeLinejoin="round" strokeWidth={34.86} />
    </svg>
  );
}

const PathSpotGerm = React.memo(PathSpotGermRaw);
export default PathSpotGerm;

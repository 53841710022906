import * as React from 'react';

function PathSpotTabletRaw(props: any) {
  const {primary_color, secondary_color, tertiary_color, quaternary_color, className, viewBox, width, height} = props;
  return (
    <svg viewBox={viewBox || '0 0 376.468 505.889'} className={className || 'p-icon-5xl'}>
      <circle cx={188.251} cy={413.407} r={26.84} fill={quaternary_color} />
      <rect x={16.944} y={16.944} width={342.58} height={472} rx={9.76} fill="none" stroke={primary_color} strokeLinecap="round" strokeLinejoin="round" strokeWidth={33.889} />
      <path
        fill="none"
        stroke={quaternary_color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={33.889}
        d="M156.938 127.658l89.567 89.602M232.476 96.108l44.766 44.801"
      />
    </svg>
  );
}

const PathSpotTablet = React.memo(PathSpotTabletRaw);
export default PathSpotTablet;

import * as React from 'react';

function PathspotDesktopFallbackRaw(props: any) {
  const {primary_color, secondary_color, tertiary_color, quaternary_color, purple_color, className, viewBox, width, height} = props;

  return (
    <svg viewBox={viewBox || '0 0 509.636 333.531'} className={className || 'p-icon-5xl'}>
      <rect
        x={82.539}
        y={11.832}
        width={344.096}
        height={237.627}
        rx={6.639}
        fill="none"
        stroke={primary_color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={23.664}
      />
      <path fill="none" stroke={purple_color} strokeLinecap="round" strokeLinejoin="round" strokeWidth={7.21} d="M196.523 173.068v-46.031h-33.4v46.031" />
      <path fill="none" stroke={secondary_color} strokeLinecap="round" strokeLinejoin="round" strokeWidth={7.21} d="M300.308 173.544V66.475h-33.423v107.069" />
      <path fill="none" stroke={quaternary_color} strokeLinecap="round" strokeLinejoin="round" strokeWidth={7.266} d="M248.54 176.063V95.314h-33.661v80.749" />
      <path fill="none" stroke="#231f20" strokeLinecap="round" strokeLinejoin="round" strokeWidth={7.723} d="M352.183 173.407V81.471h-33.4v91.936" />
      <path
        fill="none"
        stroke="#231f20"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={4.63}
        d="M161.404 114.059h36.048v-13.891l-.162-17.832h51.987v-.318l-.368-28.521h51.467l.131.038 16.525-9.087"
      />
      <path fill="#231f20" d="M318.073 49.145l4.782-7.899-9.232-.193 4.45 8.092z" />
      <path
        fill="none"
        stroke={primary_color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={25.636}
        d="M12.818 320.713l69.721-69.72M496.818 320.713l-69.721-69.72M12.818 320.713h484"
      />
      <path
        fill="none"
        stroke={primary_color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2.41}
        d="M178.322 302.557l21.306-21.305M326.223 302.557l-21.305-21.305M178.322 302.557h147.901M199.628 281.252h105.29"
      />
      <path fill="none" stroke="#00a19a" strokeLinecap="round" strokeLinejoin="round" strokeWidth={25.955} d="M355.386 186.277H153.788" />
    </svg>
  );
}

const PathspotDesktopFallback = React.memo(PathspotDesktopFallbackRaw);
export default PathspotDesktopFallback;

import {api} from '../../../api/api';
import {WEEKLY_REPORTING_API_URL, LOCATION_REPORTING_API_URL, DAILY_REPORTING_API_URL} from '../../../api/constants';
import * as htmlTemplates from '../landing/sections/preview/daily-templates/html-templates';
import AnoConPreview from '../landing/sections/preview/AnoConPreview';
import EmpConPreview from '../landing/sections/preview/EmpConPreview';
import AnoNoConPreview from '../landing/sections/preview/AnoNoConPreview';
import EmpNoConPreview from '../landing/sections/preview/EmpNoConPreview';
import {template} from 'lodash';
import moment from 'moment';
import {stringToMoment} from '../../../@webapp-lib/pathspot-react';
import {v4 as uuidv4} from 'uuid';
export const getFullPreviewData = async (reportId: any) => {
  return await api.withAuth().url(`${WEEKLY_REPORTING_API_URL}/preview/${reportId}/full`).get().json();
};

export const abortFullPreviewData = async () => {
  return await api.abortController.abort();
};

export const getAllDailyTemplateData = async () => {
  return await api.withAuth().url(`${DAILY_REPORTING_API_URL}`).get().json();
};

export const getWeeklyReportingData = async () => {
  return await api.withAuth().url(`${LOCATION_REPORTING_API_URL}`).get().json();
};

export const quickUpdate = async (data: any) => {
  return await api.withAuth().url(`${WEEKLY_REPORTING_API_URL}/quick-edit`).post(data).res();
};

export const getReportDetails = async (reportId: string) => {
  return await api.withAuth().url(`${WEEKLY_REPORTING_API_URL}/${reportId}`).get().json();
};

export const updateReport = async (reportId: string, data: any) => {
  return await api.withAuth().url(`${WEEKLY_REPORTING_API_URL}/${reportId}`).post(data).res();
};

export const createNewReport = async (data: any) => {
  return await api.withAuth().url(`${WEEKLY_REPORTING_API_URL}`).post(data).res();
};

export const getWeeklyReportingDropdowns = async () => {
  return await api.withAuth().url(`${WEEKLY_REPORTING_API_URL}/dropdowns`).get().json();
};

export const updateSkipReport = async (reportId: string) => {
  return await api.withAuth().url(`${WEEKLY_REPORTING_API_URL}/action/${reportId}/skip`).post().res();
};

export const sendReportEmail = async (reportId: string, data: any) => {
  return await api.withAuth().url(`${WEEKLY_REPORTING_API_URL}/action/${reportId}/send`).post(data).res();
};

export const scheduleToSend = async (reportId: string, data: any) => {
  return await api.withAuth().url(`${WEEKLY_REPORTING_API_URL}/action/${reportId}/scheduled`).post(data).res();
};

export enum ReportEditContext {
  dashboard = 'dashboard',
  editExisting = 'editExisting',
  createNew = 'createNew',
}
export enum ReportContext {
  daily = 'daily',
  weekly = 'weekly',
}
export enum ReportType {
  weeklyInsight = 'weeklyInsight',
  dailyInsight = 'dailyInsight',
  realTimeAlert = 'realTimeAlert',
  safetyForm = 'safetyForm',
  sensorAlert = 'sensorAlert',
  offlineStation = 'offlineStation',
}
export enum ReportTypePayloadKey {
  weeklyInsight = 'WEEKLY_SCANS',
  dailyInsight = 'DAILY_SCANS',
  realTimeAlert = 'RT_SCAN',
  safetyForm = 'RT_WELLNESS',
  sensorAlert = 'RT_SENSOR_ALERT',
  offlineStation = 'OFFLINE_STATION',
}
export enum ReportPanelType {
  weeklyInsight = 'weeklyInsight',
  dailyInsight = 'dailyInsight',
  realTimeAlert = 'realTimeAlert',
  safetyForm = 'safetyForm',
  info = 'info',
}
export type ReportContextType = {
  [Property in ReportContext]: ReportType[keyof ReportType];
};
export const ReportContextDataField: ReportContextType = {
  [ReportContext.daily]: ReportType.dailyInsight,
  [ReportContext.weekly]: ReportType.weeklyInsight,
};
export interface ReportDateRange {
  start: any;
  end: any;
}
export interface ReportForm {
  reportName: string;
  reportId?: number;
  assignedTo: any;
  template: any;
  mailTo: any;
  mailCC: any;
  mailBCC: any;
  location: any;
  customText: string;
  active: boolean;
  status: any;
}
export type ReportingData = {
  name: string;
  to: Array<number>;
  cc: Array<number>;
  bcc: Array<number>;
  phoneTo: Array<number>;
  reportTemplateId: number;
  active: boolean;
  customText: string;
  reportStatusId: number;
  hideRewashesInfo: boolean;
  automatedReportId: number;
  status: number;
  templateName: string;
  dateRange: ReportDateRange;
};

export type ReportingCommon = {
  locationId: number;
  locationName: string;
  assignedToId: number;
  assignedToName: string;
  pathspotTeamMember: string;
  hasDepartments: boolean;
  activeStations: boolean;
  primaryKey: string;
};

export type ReportPickerItem = {
  locationId: number;
  locationName: string;
  automatedReportId: number;
  status: number;
  templateName: string;
  reportTemplateId: number;
  active: boolean;
  name: string;
  primaryKey: string;
};
export interface ReportingDropdowns {
  [index: string]: Array<any>;
  statuses: Array<any>;
  assignees: Array<any>;
  recipients: Array<any>;
  locations: Array<any>;
  templates: Array<any>;
}
export interface ReportingCache {
  // dailyTemplates: any;
  allReports: any;
  dropdowns: ReportingDropdowns;
}

//Initial payload data is conveted to the following type
//
export type AutomatedReport = {
  [index: string]: ReportingData | ReportingCommon | undefined;
  info: ReportingCommon;
  [ReportType.weeklyInsight]?: ReportingData;
  [ReportType.dailyInsight]?: ReportingData;
  [ReportType.realTimeAlert]?: ReportingData;
  [ReportType.safetyForm]?: ReportingData;
  [ReportType.sensorAlert]?: ReportingData;
  [ReportType.offlineStation]?: ReportingData;
};
// export enum ReportType {
//   weeklyInsight = 'WEEKLY_SCANS',
//   dailyInsight = 'DAILY_SCANS',
//   realTimeAlert = 'RT_SCAN',
//   safetyForm = 'RT_WELLNESS',
//   info = 'info',
//   sensorAlert = 'RT_SENSOR_ALERT',
//   offlineStation = 'OFFLINE_STATION',
// }
export enum ReportStatus {
  review = 'TO REVIEW',
  skipped = 'SKIPPED',
  sent = 'SENT',
  error = 'ERROR',
  ready = 'READY TO SEND',
  nc = 'NOT SETUP',
}

export const ReportStatusColor: any = {
  [ReportStatus.review]: 'grayed',
  [ReportStatus.skipped]: 'warning',
  [ReportStatus.sent]: 'complete',
  [ReportStatus.error]: 'error',
  [ReportStatus.ready]: 'ready',
  [ReportStatus.nc]: 'secondary',
};
export const allStatuses = [
  {
    label: ReportStatus.review,
    value: 1,
  },
  {
    label: ReportStatus.skipped,
    value: 2,
  },
  {
    label: ReportStatus.sent,
    value: 3,
  },
  {
    label: ReportStatus.error,
    value: 4,
  },
  {
    label: ReportStatus.ready,
    value: 5,
  },
];

export enum ReportPerspective {
  anonymousNoContam = 'anonymousNoContam',
  anonymousContam = 'anonymousContam',
  deptAnonymousContam = 'deptAnonymousContam',
  employeesContam = 'employeesContam',
  deptEmployeesContam = 'deptEmployeesContam',
}

export enum ReportRefreshTarget {
  dailyRepo = 'dailyRepo',
  dropdowns = 'dropdowns',
  allReports = 'allReports',
  fullPreview = 'fullPreview',
}

export type ReportRefresh = {
  [index: string]: boolean;

  dailyRepo: boolean;
  dropdowns: boolean;
  allReports: boolean;
  fullPreview: boolean;
};

export const ContextReportPerspective = {
  [ReportContext.daily]: {
    DAILY_IDENTIFIED: ReportPerspective.employeesContam,
    DAILY_ANON: ReportPerspective.anonymousContam,
    CHOPT_DAILY: ReportPerspective.anonymousNoContam,
    DEPT_DAILY_ANON: ReportPerspective.deptAnonymousContam,
  },
  [ReportContext.weekly]: {
    DEP_TARGET_SCAN_PERIOD: ReportPerspective.deptAnonymousContam,
    ANO_CON: ReportPerspective.anonymousContam,
    CHOPT_NO_WASHES_ANON: ReportPerspective.anonymousNoContam,
    EMP_CON: ReportPerspective.employeesContam,
    DEP_EMP_CON: ReportPerspective.deptEmployeesContam,
  },
};
export enum TemplatePreviewType {
  jsx = 'jsx',
  html = 'html',
  handlebars = 'handlebars',
}

export interface ReportTemplate {
  type: TemplatePreviewType;
  template: any;
}

export const ContextTemplates = {
  [ReportContext.daily]: {
    [ReportPerspective.anonymousContam]: {
      type: TemplatePreviewType.handlebars,
      template: htmlTemplates.dailyAnonymousContam,
    },
    [ReportPerspective.anonymousNoContam]: {
      type: TemplatePreviewType.handlebars,
      template: htmlTemplates.dailyAnonymousNoContam,
    },
    [ReportPerspective.deptAnonymousContam]: {
      type: TemplatePreviewType.handlebars,
      templpate: htmlTemplates.dailyDepartments,
    },
    [ReportPerspective.employeesContam]: {
      type: TemplatePreviewType.handlebars,
      template: htmlTemplates.dailyEmployees,
    },
  },
  [ReportContext.weekly]: {
    [ReportPerspective.anonymousContam]: {
      type: TemplatePreviewType.jsx,
      template: AnoConPreview,
    },
    [ReportPerspective.anonymousNoContam]: {
      type: TemplatePreviewType.jsx,
      template: AnoNoConPreview,
    },
    [ReportPerspective.deptAnonymousContam]: {
      type: TemplatePreviewType.html,
      template: null,
    },
    [ReportPerspective.deptEmployeesContam]: {
      type: TemplatePreviewType.html,
      template: null,
    },
    [ReportPerspective.employeesContam]: {
      type: TemplatePreviewType.jsx,
      template: EmpConPreview,
    },
  },
};

export const emptyDropdowns: ReportingDropdowns = {
  statuses: [],
  assignees: [],
  recipients: [],
  locations: [],
  templates: [],
};

export const defaultRefresh: ReportRefresh = {
  dailyRepo: false,
  dropdowns: false,
  allReports: false,
  fullPreview: false,
};

export const defaultReportingData: ReportingData = {
  name: '',
  to: [],
  cc: [],
  bcc: [],
  phoneTo: [],
  reportTemplateId: -1,
  active: false,
  customText: '',
  reportStatusId: -1,
  hideRewashesInfo: false,
  automatedReportId: -1,
  status: -1,
  templateName: '',
  dateRange: {start: moment(), end: moment()},
};
//This default object matches what is returned in the fetch all items payload
export const defaultReport = {
  name: '',
  pathspotTeamMember: 0,
  to: [],
  cc: [],
  bcc: [],
  phoneTo: [],
  reportTemplateId: [],
  trigger: null,
  dataStartTrigger: null,
  active: false,
  customText: '',
  reportStatusId: null,
  hideRewashesInfo: false,
  automatedReportId: null,
  status: '',
  locationId: null,
  locationName: '',
  assignedToId: null,
  assignedToName: '',
  templateName: '',
  dateRange: '',
};

export const defaultReportForm: ReportForm = {
  reportName: '',
  assignedTo: '',
  template: '',
  mailTo: '',
  mailCC: '',
  mailBCC: '',
  location: '',
  customText: '',
  active: false,
  status: '',
};
export type ReportingDataAttributeType = keyof ReportingData;
// export enum ReportingDataAttribute = Object.entries(
//   Object.keys(defaultReportingData).map((key: string) => {
//     return [key, `${key}`];
//   })
// );
export enum ReportDataAttribute {
  active = 'active',
  status = 'status',
  automatedReportId = 'automatedReportId',
}
export const contextAttribute = (context: ReportContext) => ReportContextDataField[context as ReportContext] as ReportType;
export const getReportDataContextAttribute = (item: AutomatedReport, context: ReportContext, attribute: ReportingDataAttributeType) => {
  // console.log('item, context, attribute: ', item, context, attribute, contextAttribute(context), `${context}`);
  const contextObjectLabel = contextAttribute(context);
  const contextObject = item[contextObjectLabel] as ReportingData;
  return contextObject[attribute];
};

export const stringToRType = (str: string) => ReportTypePayloadKey[str as keyof typeof ReportTypePayloadKey];

export const formatReportingData = (responseData: any) => {
  // console.log('In format reporting function...', responseData);
  const formattedData = responseData.map((item: any, idx: any) => {
    //Find a report type that is defined to get assigned to info
    const definedReportKey = Object.keys(ReportType).find((key: string) => item && item[stringToRType(key)]);
    let itemInfo: any = {
      locationId: item.locationId,
      locationName: item.locationName,
      hasDepartments: item.hasDepartments,
      activeStations: item.activeStations,
    };
    if (definedReportKey) {
      const assignedInfoObject = item[stringToRType(definedReportKey)];
      const assignedToInfo = {
        assignedToId: assignedInfoObject?.assignedToId,
        assignedToName: assignedInfoObject?.assignedToName,
        pathspotTeamMember: assignedInfoObject?.pathspotTeamMember,
      };

      itemInfo = {...itemInfo, ...assignedToInfo} as ReportingCommon;
    }
    const formattedCommon = {...itemInfo, primaryKey: `${item.locationId}-${idx}` || uuidv4()};
    const formattedItem = Object.fromEntries(
      Object.keys(ReportPanelType).map((rtype: string) => {
        if (item && item[stringToRType(rtype)]) {
          if (item[stringToRType(rtype)] && item[stringToRType(rtype)].dateRange) {
            const splitString = item[stringToRType(rtype)].dateRange.split(' - ');
            const start = stringToMoment(splitString[0], 'YYYY-MM-DD');
            const end = stringToMoment(splitString[1], 'YYYY-MM-DD');
            item[stringToRType(rtype)].dateRange = {
              start,
              end,
            } as ReportDateRange;
          }
          return [rtype, item[stringToRType(rtype)] as ReportingData];
        } else if (rtype === ReportPanelType.info) {
          return [rtype, {...formattedCommon}];
        } else {
          return [rtype, {...defaultReport}];
        }
      })
    );
    return {...formattedItem};
  });
  // console.log('New Formatted Data for reporting is: ', formattedData);
  return [...formattedData];
};

export const formatDataForTable = (preformattedData: any) => {
  // console.log('Preformatted data is: ', preformattedData);
  const formattedData = preformattedData.map((item: any) => {
    return {
      locationName: item?.info?.locationName,
      assignedToName: item?.weeklyInsight?.assignedToName || item?.dailyInsight?.assignedToName || item?.scanAlert?.assignedToName || item?.safetyForm?.assignedToName,
      automatedReportId: item?.weeklyInsight?.automatedReportId,
      weeklyActive: item?.weeklyInsight?.active === true ? 'yes' : 'no',
      weeklyTemplateName: item?.weeklyInsight?.templateName,
      weeklyStatus: item?.weeklyInsight?.status,
      dailyActive: item?.dailyInsight?.active === true ? 'yes' : 'no',
      dailyTemplateName: item?.dailyInsight?.templateName,
      dailyStatus: item?.dailyInsight?.status,
      safetyFormActive: item?.safetyForm?.active === true ? 'yes' : 'no',
      failedRewashActive: item?.realTimeAlert?.active === true ? 'yes' : 'no',
      primaryKey: item?.info?.primaryKey,
    };
  });
  // console.log('Table formatted data is: ', formattedData);
  return [...formattedData];
};
// export enum TemplateDataSource {
//   payload = 'payload',
//   memoized = 'memoized',
// }
// export const contextFields = {
//   [ReportContext.weekly]: {
//     objName: ReportDataType.weeklyInsight,
//     status: 'weeklyStatus',
//   },
//   [ReportContext.daily]: {
//     objName: ReportDataType.dailyInsight,
//     status: 'dailyStatus',
//   },
// };
// export const updateUser = async (userId: string, { ...values }) => {
//     return api
//         .withAuth()
//         .url(`${NEW_API_URL_USERS}/${userId}`)
//         .post({ ...values })
//         .res()
// }

import CIcon from '@coreui/icons-react';
import {CInputCheckbox, CFormGroup, CLabel} from '@coreui/react';
import React, {useRef} from 'react';
import {v4 as uuidv4} from 'uuid';
const componentKey = uuidv4();
export const PInputCheckbox = (props: any) => {
  const {
    id,
    handleChange,
    iconClass,
    buttonType,
    buttonText,
    buttonIcon,
    buttonClass,
    buttonColor,
    buttonStyle,
    buttonShape,
    buttonSize,
    buttonDisable,
    divClassName,
    values,
    fieldName,
    htmlFor,
  } = props;
  return (
    <div className={divClassName || 'mt-3 mb-3'}>
      <CFormGroup key={`key-${uuidv4}-${id}`} variant={buttonType || 'custom-checkbox'} inline>
        {buttonIcon && <CIcon key={`${componentKey}-icon`} name={buttonIcon} className={iconClass || 'mx-2'} />}

        <CInputCheckbox
          key={`${componentKey}-button`}
          // tag={id}
          id={id}
          disabled={buttonDisable || false}
          style={buttonStyle || {cursor: 'pointer'}}
          className={buttonClass || 'px-3 ml-1 mr-3 my-3'}
          // type={buttonType || 'button'}
          color={buttonColor}
          size={buttonSize || 'sm'}
          shape={buttonShape || 'pill'}
          onChange={(val: any) => handleChange(val)}
        />
        <CLabel variant="custom-checkbox" htmlFor={htmlFor || null}>
          {buttonText}
        </CLabel>
      </CFormGroup>
    </div>
  );
};
export default PInputCheckbox;

import * as React from 'react';

function PathSpotMessagingRaw(props: any) {
  const {primary_color, secondary_color, tertiary_color, quaternary_color, className, viewBox, width, height} = props;
  return (
    <svg viewBox={viewBox || '0 0 507.963 400.247'} className={className || 'p-icon-5xl'}>
      <path
        d="M23.304 218.63h72.458v49.257l59.133-49.256H297.42a7.32 7.32 0 007.287-7.32V23.301a7.32 7.32 0 00-7.287-7.32H23.304a7.353 7.353 0 00-7.32 7.32V211.31a7.353 7.353 0 007.32 7.32z"
        fill="none"
        stroke={primary_color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={31.963}
      />
      <path
        d="M484.662 334.977h-72.458v49.288l-59.136-49.288H210.479a7.32 7.32 0 01-7.288-7.288V139.648a7.32 7.32 0 017.288-7.288h274.183a7.319 7.319 0 017.319 7.288v188.039a7.319 7.319 0 01-7.319 7.29z"
        fill="none"
        stroke={secondary_color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={31.963}
      />
    </svg>
  );
}

const PathSpotMessaging = React.memo(PathSpotMessagingRaw);
export default PathSpotMessaging;

import React, { useState } from "react";
import { CCol, CRow, CButtonToolbar } from "@coreui/react";
import CIconButton from "../../components/buttons/CIconButton";

export const CIconButtonRow = (props: any) => {
    const { buttonRowProps } = props;
    const { rowClass, colClass, toolbarClass, buttonProps } = buttonRowProps

    //console.log("CIcon button row props are: ", props)

    //console.log("CIcon button row buttonRowProps are: ", buttonRowProps)
    return (
        <CRow className={rowClass || "mb-3"}>
            <CCol className={colClass || "justify-content-center"}>
                <CButtonToolbar className={toolbarClass || "justify-content-center"} >
                    <CIconButton {...buttonProps} />
                </CButtonToolbar>
            </CCol>
        </CRow>
    );
};
export default CIconButtonRow;


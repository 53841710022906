import {configureStore, ThunkAction, Action} from '@reduxjs/toolkit';
import appReducer, {AppActions, AppDispatches, appkey} from './contexts/app';
import surveyReducer from './contexts/survey';
export const store = configureStore({
  reducer: {
    [appkey]: appReducer,
    survey: surveyReducer,
  },
});
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;

export const selectApp = (state: RootState) => state.app;
export const selectAppDarkMode = (state: RootState) => state.app.darkMode;
export const selectAppSidebar = (state: RootState) => state.app.sidebarShow;
export const selectAppCurrentUser = (state: RootState) => state.app.currentUser;
export const selectCurrentUser = (state: RootState) => state.app.currentUser;

export const selectAppCurrentUserEmail = (state: RootState) => state.app.currentUser.email;
export const selectAppAsideShow = (state: RootState) => state.app.asideShow;

//Questionnaire Selectors
export const selectSurvey = (state: RootState) => state.survey;
export const selectSurveyModal = (state: RootState) => state.survey.modalState;
export const selectSurveyForm = (state: RootState) => state.survey.surveyForm;

export {AppDispatches, AppActions};

import React from 'react';
import {CCol} from '@coreui/react';
import {PTextarea} from './PInput';

export type PInputBoxProps = {
  layout: {
    label?: {
      className: string;
    };
    input?: {
      className: string;
    };
    base?: {
      className: string;
    };
  };

  content: {
    label?: string;
    placeholder?: string;
    value: any;
    fieldName: any;
    handleChange?: any;
    disabled?: boolean | null;
  };
};

const PInputBox: React.FC<PInputBoxProps> = ({layout, content}) => {
  return (
    <CCol>
      <div {...layout.base}>
        <div className={layout.label?.className}>{content.label || ''}</div>
        <PTextarea
          className={layout.input?.className}
          onChange={(change: any) => content.handleChange(content.fieldName, change.target.value)}
          value={content.value}
          placeholder={content.placeholder}
          disabled={content?.disabled}
        />
      </div>
    </CCol>
  );
};
export default PInputBox;

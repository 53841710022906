import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
// import {tagPropType} from '@coreui/react/src/utils/helper';

//component - CoreUI / PInputGroupText
const PInputGroupText = (props: any) => {
  const {
    tag: Tag,
    className,
    //
    innerRef,
    ...attributes
  } = props;

  //render
  const classes = classNames('input-group-text', className);

  return <Tag className={classes} {...attributes} ref={innerRef} />;
};

PInputGroupText.propTypes = {
  tag: React.Component,
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.object]),
  //
  innerRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
};

PInputGroupText.defaultProps = {
  tag: 'div',
};

export default PInputGroupText;

import {
  ACTIONS,
  TARGET_TYPE,
  STATE_TARGET,
  STATE_TYPE,
  ManifoldState,
  State,
  ListSubListStateAction,
  StateAction,
  PLFIELD,
  PayloadPaths
} from "./StateTypes";
//@ts-ignore
import cloneDeep from "lodash/cloneDeep";
import { removeProperty } from '../base/common'
import { listStateReducer } from './StateList'
import { subListStateReducer } from './StateSubList'
import { modalStateReducer } from './StateModal'
import { objectHasField, getValueFromFieldPath, getObjectFromFieldPath } from '../base/common'


const baseTargetPath = [PLFIELD.PAYLOAD, PLFIELD.KWARGS, PLFIELD.BASETARGET]
const fieldPathPath = [PLFIELD.PAYLOAD, PLFIELD.KWARGS, PLFIELD.FIELDPATH]
const valuePath = [PLFIELD.PAYLOAD, PLFIELD.VALUE]
const matchFieldPath = PayloadPaths.matchFieldPath



export const fieldStateReducer = (componentState: any, stateAction: any) => {
  let newComponentState = cloneDeep(componentState)
  let fieldPathList: any[]
  const stateTarget = objectHasField(stateAction, [...baseTargetPath]) ? getValueFromFieldPath(stateAction, [...baseTargetPath]) : STATE_TARGET.UNDEFINED
  if (stateTarget === STATE_TARGET.UNDEFINED) {
    fieldPathList = [stateAction.stateTarget]
  } else {
    fieldPathList = objectHasField(stateAction, [...fieldPathPath]) ? [stateTarget, ...stateAction.payload.kwargs.fieldPath] : [stateTarget]
  }
  const fieldObject = getObjectFromFieldPath(newComponentState, fieldPathList.flat())
  const fieldTarget = fieldObject.returnObject
  const fieldName = fieldObject.field
  const fieldValue = objectHasField(stateAction, [...valuePath]) ? getValueFromFieldPath(stateAction, [...valuePath]) : objectHasField(stateAction, [PLFIELD.PAYLOAD, PLFIELD.KWARGS, `${fieldName}`]) ? getValueFromFieldPath(stateAction, [PLFIELD.PAYLOAD, PLFIELD.KWARGS, `${fieldName}`]) : null
  switch (stateAction.type) {
    case ACTIONS.SET:
      fieldTarget[fieldName] = fieldValue
      return { ...newComponentState }
    case ACTIONS.REMOVE:
      newComponentState = removeProperty(stateAction.stateTarget, newComponentState)
      return { ...newComponentState }
    case ACTIONS.UPDATE:
      return { ...newComponentState }
    case ACTIONS.APPEND:
      const initialFieldValue = fieldTarget[fieldName]
      if (fieldTarget[fieldName] && fieldTarget[fieldName].length >= 0) {
        fieldTarget[fieldName].push(fieldValue)
        fieldTarget[fieldName] = fieldTarget[fieldName].flat()
      }
      return { ...newComponentState }
    default:
      return { ...newComponentState };
  }
};

export const listFieldStateReducer = (componentState: any, stateAction: any) => {
  let newComponentState = cloneDeep(componentState)
  let fieldPathList: any[]
  const stateTarget = objectHasField(stateAction, [...baseTargetPath]) ? getValueFromFieldPath(stateAction, [...baseTargetPath]) : STATE_TARGET.UNDEFINED
  if (stateTarget === STATE_TARGET.UNDEFINED) {
    fieldPathList = [stateAction.stateTarget]
  } else {
    fieldPathList = objectHasField(stateAction, [...fieldPathPath]) ? [stateTarget, ...stateAction.payload.kwargs.fieldPath] : [stateTarget]
  }
  const fieldObject = getObjectFromFieldPath(newComponentState, fieldPathList.flat())
  const fieldTarget = fieldObject.returnObject
  const fieldName = fieldObject.field
  const fieldValue = objectHasField(stateAction, [...valuePath]) ? getValueFromFieldPath(stateAction, [...valuePath]) : objectHasField(stateAction, [PLFIELD.PAYLOAD, PLFIELD.KWARGS, `${fieldName}`]) ? getValueFromFieldPath(stateAction, [PLFIELD.PAYLOAD, PLFIELD.KWARGS, `${fieldName}`]) : null
  //by default, we match on a property named "id"
  const matchFieldName = objectHasField(stateAction, [...matchFieldPath]) ? getValueFromFieldPath(stateAction, [...matchFieldPath]) : "id"
  switch (stateAction.type) {
    case ACTIONS.SET:
      fieldTarget[fieldName] = fieldValue
      return { ...newComponentState }
    case ACTIONS.REMOVE:
      if (fieldTarget[fieldName] && fieldTarget[fieldName].length >= 0) {
        const newFieldList = fieldTarget[fieldName].filter((listItem: any) => {
          return listItem[matchFieldName] !== fieldValue[matchFieldName]
        })
        fieldTarget[fieldName] = newFieldList
      }
      return { ...newComponentState }
    case ACTIONS.UPDATE:
      return { ...newComponentState }
    case ACTIONS.APPEND:
      if (fieldTarget[fieldName] && fieldTarget[fieldName].length >= 0) {
        fieldTarget[fieldName].push(fieldValue)
        fieldTarget[fieldName] = fieldTarget[fieldName].flat()
      }
      return { ...newComponentState }
    default:
      return { ...newComponentState };
  }
};
export const actionTypedReducer = (componentState: any, stateAction: any) => {
  let newComponentState = cloneDeep(componentState);
  switch (stateAction.type) {
    case ACTIONS.SET:
      console.log("In ACTION-TYPED-REDUCER, ACTION TYPE is SET...")
      newComponentState = stateAction.payload.value
      return { ...newComponentState }
    case ACTIONS.CLEAR:
      console.log("In ACTION-TYPED-REDUCER, ACTION TYPE is CLEAR...")
      newComponentState = {};
      return { ...newComponentState }
    default:
      console.log("In ACTION-TYPED-REDUCER, ACTION TYPE is DEFAULT...")
      return { ...newComponentState }
  }
};

export const targetTypeReducer = (componentState: any, stateAction: any) => {
  let newComponentState = cloneDeep(componentState);
  switch (stateAction.targetType) {
    case TARGET_TYPE.STATE:
      console.log("In TARGET-TYPED-REDUCER, TARGET TYPE is STATE, Using actionTypedReducer....")
      newComponentState = actionTypedReducer(componentState, stateAction);
      return { ...newComponentState };
    case TARGET_TYPE.LIST:
      console.log("In TARGET-TYPED-REDUCER, TARGET TYPE is LIST, Using listStateReducer....")
      newComponentState = listFieldStateReducer(componentState, stateAction);
      return { ...newComponentState };
    case TARGET_TYPE.SUBLIST:
      console.log("In TARGET-TYPED-REDUCER, TARGET TYPE is SUBLIST, Using subListStateReducer....")
      newComponentState = subListStateReducer(componentState, stateAction);
      return { ...newComponentState };
    case TARGET_TYPE.FIELD:
      console.log("In FIELD-TYPED-REDUCER, TARGET TYPE is PROP, Using fieldStateReducer....")
      newComponentState = fieldStateReducer(componentState, stateAction);
      return { ...newComponentState };
    case TARGET_TYPE.UNDEFINED:
      console.log("In TARGET-TYPED-REDUCER, TARGET TYPE is UNDEFINED, Using actionTypedReducer....")
      newComponentState = actionTypedReducer(componentState, stateAction);
      return { ...newComponentState };
    default:
      console.log("In TARGET-TYPED-REDUCER, TARGET TYPE is DEFAULT..")

      return { ...newComponentState };
  }
};

export const stateTypeReducer = (componentState: any, stateAction: any) => {
  let newComponentState = cloneDeep(componentState);
  switch (stateAction.stateType) {
    case STATE_TYPE.COMPONENT_STATE:
      console.log("In STATE-TYPED-REDUCER, STATE TYPE is COMPONENT_STATE, Using targetTypeReducer....")
      newComponentState = targetTypeReducer(componentState, stateAction);
      return { ...newComponentState };
    case STATE_TYPE.UNDEFINED:
      console.log("In STATE-TYPED-REDUCER, STATE TYPE is UNDEFINED, Using targetTypeReducer....")

      newComponentState = targetTypeReducer(componentState, stateAction);
      return { ...newComponentState };
    case STATE_TYPE.MODAL:
      console.log("In STATE-TYPED-REDUCER, STATE TYPE is MODAL, Using modalStateReducer....")
      newComponentState = modalStateReducer(componentState, stateAction);
      return { ...newComponentState };
    default:
      console.log("In STATE-TYPED-REDUCER, STATE TYPE is NULL, Using DEFAULT targetTypeReducer....")

      newComponentState = targetTypeReducer(componentState, stateAction);
      return { ...newComponentState };
  }
};

export const stateReducer = (
  componentState: any,
  stateAction: any
) => {
  //console.log("Component state in use STATE-REDUCER is: ", componentState);
  //console.log("STATE ACTION in use STATE-REDUCER is: ", stateAction);

  let newComponentState = cloneDeep(componentState);
  if ("stateType" in stateAction) {
    //console.log("In STATEREDUCER, Using stateTypeReducer....")
    newComponentState = stateTypeReducer(componentState, stateAction);
    return { ...newComponentState };
  } else if ("targeType" in stateAction) {
    //console.log("In STATEREDUCER, Using targetTypeReducer....")
    newComponentState = targetTypeReducer(componentState, stateAction);
    return { ...newComponentState };
  } else {
    //console.log("In STATEREDUCER, Using actionTypedReducer....")
    newComponentState = actionTypedReducer(componentState, stateAction);
    return { ...newComponentState };
  }

};

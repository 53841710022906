import * as React from 'react';

function PathSpotCheckCircleRaw(props: any) {
  const {primary_color, secondary_color, tertiary_color, quaternary_color, className, viewBox, width, height} = props;

  return (
    <svg viewBox={viewBox || '0 0 509.142 493.542'} className={className || 'p-icon-5xl'}>
      <circle cx={246.771} cy={246.771} r={228.2} fill="none" stroke={primary_color} strokeLinecap="round" strokeLinejoin="round" strokeWidth={37.142} />
      <path
        fill="none"
        stroke={secondary_color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={37.142}
        d="M247.96 309.727L490.571 67.116M163.35 207.624l84.61 102.103"
      />
    </svg>
  );
}

const PathSpotCheckCircle = React.memo(PathSpotCheckCircleRaw);
export default PathSpotCheckCircle;

import * as React from 'react';

function PathSpotWifiRaw(props: any) {
  const {primary_color, secondary_color, tertiary_color, quaternary_color, className, viewBox, width, height} = props;
  return (
    <svg viewBox={viewBox || '0 0 503.145 387.295'} className={className || 'p-icon-5xl'}>
      <path
        d="M475.268 146.77a26.228 26.228 0 01-18.62-7.706c-113.421-113.169-297.047-113.169-410.468 0a26.326 26.326 0 01-37.243-37.209c134-133.71 350.949-133.71 484.948 0a26.326 26.326 0 01-18.62 44.915z"
        fill={primary_color}
        stroke={primary_color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={3.145}
      />
      <path
        d="M410.6 211.47a26.235 26.235 0 01-18.621-7.706c-77.629-77.632-203.493-77.635-281.125-.006v.006a26.327 26.327 0 01-37.217-37.241c98.2-98.171 257.382-98.171 355.581 0a26.327 26.327 0 01-18.62 44.947z"
        fill={primary_color}
        stroke={primary_color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={3.145}
      />
      <path
        d="M345.774 276.138a26.357 26.357 0 01-18.62-7.706 107.289 107.289 0 00-151.728 0h0a26.333 26.333 0 11-37.241-37.241h0a160 160 0 01226.213 0 26.327 26.327 0 01-18.621 44.947z"
        fill={primary_color}
        stroke={primary_color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={3.145}
      />
      <circle cx={251.414} cy={337.536} r={49.759} fill={quaternary_color} />
    </svg>
  );
}

const PathSpotWifi = React.memo(PathSpotWifiRaw);
export default PathSpotWifi;

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// component - CoreUI / PInput
const commonPropTypes = {
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.object]),
  innerRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  valid: PropTypes.bool,
  invalid: PropTypes.bool,
};

const PInput = (props: any) => {
  let {
    className,
    //
    innerRef,
    type,
    valid,
    invalid,
    plaintext,
    size,
    sizeHtml,
    ...attributes
  } = props;

  // render
  const classes = classNames(
    // className ? className : 'form-control',
    plaintext ? 'form-control-plaintext' : 'form-control',
    size && `form-control-${size}`,
    invalid && 'is-invalid',
    valid && 'is-valid',
    className
  );

  return <input className={classes} type={type} {...attributes} size={sizeHtml} ref={innerRef} />;
};

const PTextarea = (props: any) => {
  let {
    className,
    disabled,
    innerRef,
    valid,
    invalid,
    plaintext,
    size,
    ...attributes
  } = props;

  // render
  const classes = classNames(plaintext ? 'form-control-plaintext' : 'form-control', size && `form-control-${size}`, invalid && 'is-invalid', valid && 'is-valid', className);
  return <textarea disabled={disabled} className={classes} {...attributes} ref={innerRef} />;
};

const PInputFile = (props: any) => {
  let {
    className,
    //
    innerRef,
    valid,
    invalid,
    custom,
    ...attributes
  } = props;

  // render

  const classes = classNames(custom ? 'custom-file-input' : 'form-control-file', invalid && 'is-invalid', valid && 'is-valid', className);

  return <input className={classes} {...attributes} type="file" ref={innerRef} />;
};

PInputFile.propTypes = {
  ...commonPropTypes,
  custom: PropTypes.bool,
};

const PInputCheckbox = (props: any) => {
  let {
    className,
    //
    innerRef,
    valid,
    invalid,
    custom,
    ...attributes
  } = props;

  // render

  const classes = classNames(custom ? 'custom-control-input' : 'form-check-input', invalid && 'is-invalid', valid && 'is-valid', className);

  return <input className={classes} type="checkbox" {...attributes} ref={innerRef} />;
};

PInputCheckbox.propTypes = {
  ...commonPropTypes,
  custom: PropTypes.bool,
};

const PInputRadio = (props: any) => <PInputCheckbox {...props} type="radio" />;

const PSelect = (props: any) => {
  let {
    className,
    //
    innerRef,
    valid,
    invalid,
    size,
    sizeHtml,
    custom,
    ...attributes
  } = props;

  // render
  const baseClass = custom ? 'custom-select' : 'form-control';
  const classes = classNames(baseClass, size && `${baseClass}-${size}`, invalid && 'is-invalid', valid && 'is-valid', className);

  return <select className={classes} {...attributes} size={sizeHtml} ref={innerRef} />;
};

PSelect.propTypes = {
  ...commonPropTypes,
  size: PropTypes.string,
  sizeHtml: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export {PInput, PTextarea, PInputFile, PInputCheckbox, PInputRadio, PSelect};

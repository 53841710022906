import React, { useState } from "react";
import { CCol, CRow, CButtonToolbar } from "@coreui/react";
import CIconButton from "./CIconButton";

export const CancelContinueButtons = (props: any) => {
  const { continueButtonProps, cancelButtonProps } = props;
  return (
    <CRow className="mb-3">
      <CCol className="justify-content-center">
        <CButtonToolbar justify="center">
          <CIconButton {...cancelButtonProps} />
          <CIconButton {...continueButtonProps} />
        </CButtonToolbar>
      </CCol>
    </CRow>
  );
};
export default CancelContinueButtons;

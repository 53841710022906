import * as React from 'react';

const PathSpotHorizontalWhiteRaw = (props: any) => {
  const {primary_color, secondary_color, tertiary_color, quaternary_color, className, viewBox, width, height} = props;

  return (
    <svg viewBox={viewBox || '0 0 509 100.74'} className={className || 'p-icon-5xl'}>
      <path
        fill={tertiary_color}
        d="M140.23 43.86c0 7.765-4.737 15.329-15.469 15.329h-9.754v16.557h-9.552v-47.34h19.306c10.732 0 15.469 7.703 15.469 15.454zm-9.614 0c0-3.433-1.724-7.005-6.181-7.005h-9.412V50.8h9.277c4.592 0 6.316-3.57 6.316-6.94zM176.31 64.408h-17.737L154.8 75.746h-10.174l17.659-47.356H172.6l17.6 47.356h-10.038zm-2.889-8.31l-6.042-17.8L161.4 56.1zM228.48 36.855h-13.062v38.891h-9.552V36.855H192.8v-8.449h35.661v8.449zM277.886 28.406v47.355h-9.552V56.518h-17.659v19.243h-9.552V28.406h9.552v19.523h17.659V28.406zM292.268 61.8h9.552c.062 3.433 3.37 6.26 7.765 6.26 4.256 0 7.083-2.206 7.083-5.561 0-2.609-1.926-4.473-5.98-5.7l-5.778-1.646c-10.375-2.749-12.238-9.008-12.238-13.264 0-8.449 7.284-14.087 16.354-14.087 8.931 0 15.811 5.436 15.811 14.429h-9.552c0-3.371-2.329-5.98-6.461-5.98-3.712 0-6.523 2.268-6.523 5.436 0 1.165.342 3.635 5.3 5.017l5.3 1.506c7.144 1.988 13.4 5.778 13.4 13.808 0 9.412-8.045 14.366-16.976 14.366-10.125-.017-17.057-6.199-17.057-14.584zM375.206 43.86c0 7.765-4.737 15.329-15.47 15.329h-9.753v16.557h-9.552v-47.34h19.305c10.733 0 15.47 7.703 15.47 15.454zm-9.614 0c0-3.433-1.724-7.005-6.182-7.005H350V50.8h9.272c4.596 0 6.32-3.57 6.32-6.94zM385.519 52.107a24.09 24.09 0 1124.058 24.26 24.047 24.047 0 01-24.058-24.26zm38.564 0c0-8.527-6.041-15.33-14.506-15.33-8.527 0-14.506 6.8-14.506 15.33s5.979 15.329 14.506 15.329c8.449 0 14.506-6.802 14.506-15.329zM477.341 36.855h-13.062v38.891h-9.552V36.855h-13.062v-8.449h35.66v8.449zM487.234 28.406h8.527v2.454H492.9v8.682h-2.827V30.86h-2.823v-2.454zm19.989 11.151l-.652-5.855-2.128 4.5h-2.159l-2.159-4.457-.652 5.808h-2.858l1.351-11.151h2.346l3.028 6.554 3-6.554h2.407l1.353 11.155z"
        transform="translate(-1.096 -1.714)"
      />
      <path
        d="M77.1 52.682L54.789 16.608a2.8 2.8 0 00-2.359-1.348h-.07a2.79 2.79 0 00-2.335 1.255L26.162 52.147a32.395 32.395 0 008.888 44.927 32.49 32.49 0 0015.788 5.379 32.363 32.363 0 0015.638-4.6 31.9 31.9 0 0014.616-19.995A32.815 32.815 0 0077.1 52.682zM69.021 74.93a19.567 19.567 0 01-8.946 12.281A19.871 19.871 0 0151.082 90 19.924 19.924 0 0136.5 59.072L51.884 36.1a.206.206 0 01.174-.093.223.223 0 01.175.093l14.29 23.12a20.545 20.545 0 012.498 15.71z"
        transform="translate(-1.096 -1.714)"
        fill={secondary_color}
      />
      <path
        fill={primary_color}
        d="M81.847 52.717V27.111a6.437 6.437 0 00-6.437-6.4h-.023a6.446 6.446 0 00-6.413 6.46L69 32.037zM22.212 50.962c.2-.313.418-.673.662-1.022l6.971-10.41-10.1-16.277a6.244 6.244 0 00-3.916-2.811 6.135 6.135 0 00-1.452-.174h-.023a6.218 6.218 0 00-3.276.952 6.31 6.31 0 00-2.029 8.68zM42.45 20.686l4.276-6.378a6.791 6.791 0 014.774-2.963L47.376 4.7a6.269 6.269 0 00-5.367-2.986h-.035a6.294 6.294 0 00-5.321 9.619zM32.3 35.859l7.7-11.5L28.811 6.314a6.271 6.271 0 00-5.368-2.985h-.023a6.294 6.294 0 00-5.32 9.619zM19.981 55.11l-7.6-12.292a6.381 6.381 0 00-.93-1.092 6.286 6.286 0 00-4.066-1.487h-.021a6.294 6.294 0 00-5.321 9.62l14.871 24a35.951 35.951 0 013.067-18.749z"
        transform="translate(-1.096 -1.714)"
      />
    </svg>
  );
};

const PathSpotHorizontalWhite = React.memo(PathSpotHorizontalWhiteRaw);
export default PathSpotHorizontalWhite;

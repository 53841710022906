import React from 'react'
import { CSpinner } from '@coreui/react'

const StyledSpinner = () => {
  return (
    <div className="text-center my-5">
      <h2>
        {/* <CPagination></CPagination> */}
        <br />
        <CSpinner />
        <br />
      </h2>
    </div>
  )
}
export default StyledSpinner

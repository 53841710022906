import { TypedObject } from '../..'

export enum CONTEXT_STATE {
  NEW = "new",
  EDIT = "editing",
  UNDEFINED = "undefined"
}
export enum STATE_TARGET {
  CONTEXT = "context",
  INITIAL = "initial",
  FALLBACK = "fallback",
  PRIMARY = "primary",
  SECONDARY = "secondary",
  DISPATCH = "dispatch",
  ERRORS = "errors",
  MESSAGES = "messages",
  AUX = "aux",
  INFO = "info",
  UNDEFINED = "undefined"
}
export enum ACTIONS {
  CLONE = "clone",
  SET = "set",
  APPEND = "append",
  REMOVE = "remove",
  UPDATE = "update",
  CLEAR = "clear",
  RESET = "reset",
  UNDEFINED = "undefined"
}
export enum TARGET_TYPE {
  STATE = "state",
  LIST = "list",
  SUBLIST = "subList",
  FIELD = "field",
  CONTEXT = "context",
  MODAL = "modal",
  ERROR = "error",
  DEPENDENT = "dependent",
  TYPED = "typed",
  FUNCTIONAL = "functional",
  UNDEFINED = "undefined"
}
export enum STATE_TYPE {
  COMPONENT_STATE = "componentState", //mixed state object
  RENDER = "render",
  LISTSUBLIST = "objectListing",
  CONTEXT = "context",
  MODAL = "modal",
  ERROR = "error",
  UNDEFINED = "undefined"
}
export enum PLFIELD {
  PAYLOAD = "payload",
  VALUE = "value",
  KWARGS = "kwargs",
  DISPATCHES = "dispatches",
  FIELDPATH = "fieldPath",
  HANDLES = "handles",
  BASETARGET = "baseTarget",
  MTYPE = "modalType",
  MATCHFIELD = "matchField"
}
export const PayloadPaths = {
  payload: [PLFIELD.PAYLOAD],
  kwargs: [PLFIELD.PAYLOAD, PLFIELD.KWARGS],
  baseTarget: [PLFIELD.PAYLOAD, PLFIELD.KWARGS, PLFIELD.BASETARGET],
  fieldPath: [PLFIELD.PAYLOAD, PLFIELD.KWARGS, PLFIELD.FIELDPATH],
  modalType: [PLFIELD.PAYLOAD, PLFIELD.KWARGS, PLFIELD.MTYPE],
  value: [PLFIELD.PAYLOAD, PLFIELD.VALUE],
  handles: [PLFIELD.PAYLOAD, PLFIELD.HANDLES],
  dispatches: [PLFIELD.PAYLOAD, PLFIELD.DISPATCHES],
  matchFieldPath: [PLFIELD.PAYLOAD, PLFIELD.KWARGS, PLFIELD.MATCHFIELD],
}
//###################################### INTERFACES ###################################
export interface Context {
  target?: any;
  state?: any;
  version?: any;
  paramNames?: any;
  paramValues?: any;
}
export interface State {
  id?: any;
  type?: STATE_TYPE;
  context?: Context;
}
export interface ManifoldState extends State {
  initial?: any[];
  fallback?: any[];
  primary?: any[];
  secondary?: any[];
  dispatch?: any[];
  errors?: any;
  messages?: any;
  aux?: any;
  info?: any;
}
export interface ListSubListMatchProps {
  list: any;
  subList: any;
}
export interface Payload {
  value?: any;
  kwargs?: any;
  fieldPath?: string[];
  handles?: any;
  dispatches?: any;
  matchField?: any;
}
export interface StateAction {
  type?: any; //state action type
  targetType?: any;
  stateType?: any;
  stateTarget?: any;
  payload?: Payload;
}
export interface ListSubListState extends State {
  type: STATE_TYPE.LISTSUBLIST;
  list?: [];
}
export interface ListSubListStateAction extends StateAction {
  listFieldName?: string;
  subListFieldName?: string;
  searchFields?: ListSubListMatchProps;
}
export interface ErrorInterface extends StateAction {
  errorType: any;
  errorStatePayload: any;
  errorState: any;
}

import { CInputGroup } from '@coreui/react';
import React from 'react';
import CInputGroupRowPrepend from './CInputGroupRowPrepend';
import CInputGroupRowAppend from './CInputGroupRowAppend';
import FAlertRow from './FAlertRow';
import { styles} from '../../styles/styling';
import Select from 'react-select';

const CSelectorGroupRow = (props: any) => {
  const {
    readOnly,
    fieldName, 
    iconStart, 
    displayStr, 
    placeholderStr, 
    iconEnd, 
    endStr, 
    options, 
    className, 
    style, 
    borderStyle, 
    handleChange, 
    values,
    touched, 
    errors, 
    isMulti, 
    alertColor,
    defaultVals
  } = props;
  return (
    <div className={className || 'mb-3'}>
      <CInputGroup>
        <CInputGroupRowPrepend iconStart={iconStart} displayStr={displayStr} />
        <Select // TODO : cahnge from react-select to new styling lib bc disabled is a diff color here
          name={fieldName}
          placeholder={placeholderStr}
          options={options}
          onChange={(val: any) => handleChange(val)}
          value={values && values[fieldName] ? values[fieldName] : undefined}
          styles={styles}
          isMulti={isMulti}
          invalid={touched && touched[fieldName] && errors && errors[fieldName] ? true : false}
          isDisabled={readOnly ? true : false}
          defaultValue={defaultVals? defaultVals : null}
        />
        <CInputGroupRowAppend iconEnd={iconEnd} endStr={endStr} />
      </CInputGroup>
      {errors && errors[fieldName] && errors[fieldName]?.length > 0 && <FAlertRow errors={errors} fieldName={fieldName} alertColor={alertColor} />}
    </div>
  );
};
export default CSelectorGroupRow;

import * as React from 'react';

function PathSpotGroupRaw(props: any) {
  const {primary_color, secondary_color, tertiary_color, quaternary_color, className, viewBox, width, height} = props;

  return (
    <svg viewBox={viewBox || '0 0 502.931 461.051'} className={className || 'p-icon-5xl'}>
      <circle cx={381.837} cy={339.958} r={105.628} fill="none" stroke={quaternary_color} strokeLinecap="round" strokeLinejoin="round" strokeWidth={30.931} />
      <path d="M336.772 416.542a45.066 45.066 0 0190.131 0" fill="none" stroke={quaternary_color} strokeLinecap="round" strokeLinejoin="round" strokeWidth={30.931} />
      <circle cx={381.837} cy={315.584} r={37.797} fill={quaternary_color} />
      <circle cx={121.093} cy={339.958} r={105.628} fill="none" stroke={secondary_color} strokeLinecap="round" strokeLinejoin="round" strokeWidth={30.931} />
      <path d="M76.028 416.542a45.066 45.066 0 0190.132 0" fill="none" stroke={secondary_color} strokeLinecap="round" strokeLinejoin="round" strokeWidth={30.931} />
      <circle cx={121.093} cy={315.584} r={37.797} fill={secondary_color} />
      <circle cx={256.785} cy={121.093} r={105.628} fill="none" stroke={primary_color} strokeLinecap="round" strokeLinejoin="round" strokeWidth={30.931} />
      <path d="M211.689 197.677a45.1 45.1 0 0190.162 0" fill="none" stroke={primary_color} strokeLinecap="round" strokeLinejoin="round" strokeWidth={30.931} />
      <circle cx={256.785} cy={96.72} r={37.797} fill={primary_color} />
    </svg>
  );
}

const PathSpotGroup = React.memo(PathSpotGroupRaw);
export default PathSpotGroup;

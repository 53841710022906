import Cookies from 'js-cookie';
import {api} from '../../api/api';
import {LOG_IN_API_URL, REFRESH_TOKEN_API_URL} from '../../api/constants';
import {AppActions} from '../../redux/store';

export const logIn = async ({...values}) => {
  return api
    .noAuth()
    .url(`${LOG_IN_API_URL}`)
    .options({credentials: 'include', mode: 'cors'})
    .post({...values})
    .json();
};

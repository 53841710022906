import * as React from 'react';

const PathSpotHandWashRaw = (props: any) => {
  const {primary_color, secondary_color, tertiary_color, quaternary_color, className, viewBox, width, height} = props;

  return (
    <svg viewBox={viewBox || '0 0 509.9 508.572'} className={className || 'p-icon-5xl'}>
      <path
        d="M202.992 165.553a23.681 23.681 0 0015.971-29.436v-.015h0a23.712 23.712 0 00-29.482-15.967l-73.345 22.425a89.252 89.252 0 00-63.119 85.305h0"
        fill="none"
        stroke={primary_color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={29.9}
      />
      <path d="M399.795 163.046h2.153a53.192 53.192 0 0139.049 89.312" fill="none" stroke={secondary_color} strokeLinecap="round" strokeLinejoin="round" strokeWidth={29.9} />
      <path
        d="M159.967 203.811c1.659-15.709 10.738-25.037 22.4-29.084 12.459-4.325 32.946-1.7 45.564 4.873 5.572 2.9 11.368 5.676 16.357 8.4"
        fill="none"
        stroke={secondary_color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={24.307}
      />
      <path
        d="M443.927 366.666a53.3 53.3 0 01-64.315 66.5M450.744 239.77a65.78 65.78 0 01-22.186 127.913M250.892 188.102a68.985 68.985 0 01-.329-7.056 75.707 75.707 0 01149.232-17.94M385.592 418.902a47.3 47.3 0 01-45.986 36.179"
        fill="none"
        stroke={secondary_color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={29.9}
      />
      <circle cx={448.562} cy={90.867} r={23.92} fill="none" stroke={secondary_color} strokeLinecap="round" strokeLinejoin="round" strokeWidth={29.9} />
      <circle cx={232.384} cy={40.784} r={25.834} fill="none" stroke={secondary_color} strokeLinecap="round" strokeLinejoin="round" strokeWidth={29.9} />
      <path
        fill="none"
        stroke={primary_color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={29.9}
        d="M83.212 426.197l55.734-55.704M238.693 467.22l-26.402 26.402M14.95 195.398h44.193M61.265 334.314H14.95M86.86 360.477a65.779 65.779 0 01-25.595-26.163M196.264 290.66l23.293-42.967a23.71 23.71 0 00-9.539-32.142h0a23.68 23.68 0 00-32.1 9.545l-.012.023-35.881 67.723a89.255 89.255 0 00-2.99 77.741M196.324 290.809l102.827-102.8a23.681 23.681 0 0133.49-.029l.028.029h0a23.74 23.74 0 010 33.518l-58.873 58.783M332.669 221.531l23.92-23.921a23.682 23.682 0 0133.49-.028l.028.028h0a23.741 23.741 0 010 33.519l-82.793 82.763"
      />
      <path
        d="M374.978 246.228a23.74 23.74 0 0133.518 0h0a23.681 23.681 0 01.028 33.49l-.028.028-67.664 67.664"
        fill="none"
        stroke={primary_color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={29.9}
      />
      <path
        d="M388.074 300.108a23.681 23.681 0 0133.49-.028l.028.028h0a23.681 23.681 0 01.028 33.49l-.028.028-117.148 117.119a65.393 65.393 0 01-65.78 16.416"
        fill="none"
        stroke={primary_color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={29.9}
      />
      <circle cx={314.493} cy={26.1} r={21.558} fill={secondary_color} />
      <circle cx={366.731} cy={64.86} r={21.558} fill={secondary_color} />
    </svg>
  );
};

const PathSpotHandWash = React.memo(PathSpotHandWashRaw);
export default PathSpotHandWash;

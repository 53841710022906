import React from 'react';
import CIconButton from '../buttons/CIconButton';
import {CRow} from '@coreui/react';
import {v4 as uuidv4} from 'uuid';
export const MultiButtonRow = (props: any) => {
  const {buttonItems, rowClass} = props;
  // console.log('Props in multibutton row: ', props);
  let renderStack: any = [];
  buttonItems.forEach((button: any) => renderStack.push(<CIconButton key={uuidv4()} {...button} />));
  return <div className={rowClass || 'mb-3'}>{renderStack}</div>;
};
export default MultiButtonRow;

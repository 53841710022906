import React from 'react';
import classNames from 'classnames';
import {NavLink} from 'react-router-dom';

//component - CoreUI / PLink
const PLink = (props: any) => {
  const {
    className,
    //
    innerRef,
    active,
    href,
    onClick,
    disabled,
    ...rest
  } = props;

  const to = rest ? rest.to : null;
  const click = (e: any) => {
    if ((!href && !to) || href === '#') {
      e.preventDefault();
    }
    !disabled && onClick && onClick(e);
  };

  // render

  const classes = classNames(active && 'active', disabled && 'disabled', className);

  return to ? (
    <NavLink {...rest} className={classes} onClick={click} ref={innerRef} />
  ) : (
    <a href={href || '#'} className={classes} rel={rest.target === '_blank' ? 'noopener norefferer' : null} {...rest} onClick={click} ref={innerRef} />
  );
};
export default PLink;

import React from 'react';
import {CModalGroup} from '../..';
import CIconButtonRow from '../rows/CIconButtonRow';
import {ModalProps} from '../../api/modal/Types';

import {PathSpotHorizontal} from '../../styles/assets/icons/pathspot/catalog';
import {CButton, CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import {useHistory} from 'react-router-dom';

const successButton = {
  buttonType: 'button',
  buttonText: 'Close Window',
  buttonIcon: 'cil-check-alt',
  buttonClass: 'mx-1 pr-3',
  buttonColor: 'success',
  buttonShape: 'standard',
  buttonSize: 'md',
};

const StatusModal = (props: any) => {
  const {status, message, show, clearModal, pushLocation} = props;
  const history = useHistory();
  console.log('Props in status modal are: ', props);
  let buttonProps: any = {...successButton};
  buttonProps.onClickAction = () => clearModal();
  return (
    <div>
      <CModal size="lg" show={true} onClose={() => clearModal()} color={'primary'}>
        <CModalHeader closeButton>
          <CModalTitle>Success!</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <div className="status-modal">
            <PathSpotHorizontal className="status-modal-logo-alignment" />
            <div className="status-modal-text-alignment ">
              <strong className="status-modal-text">{message}</strong>
            </div>
          </div>
        </CModalBody>
        <CModalFooter>
          <CButton
            type="button"
            color="primary"
            className="px-4 mx-1"
            onClick={() => {
              clearModal();
              if (pushLocation) {
                history.push('/weekly-reporting');
                window.location.reload();
              } else {
                window.location.reload();
              }
            }}
          >
            <CIcon name="cil-check-alt" /> {successButton.buttonText}
          </CButton>{' '}
        </CModalFooter>
      </CModal>
    </div>
  );
};
export default StatusModal;
//

// if (show === true) {
//   const modalProps: ModalProps = {
//     display: true,
//   };
//   modalProps.body = (
//     <div className="status-modal">
//       <PathSpotHorizontal className="status-modal-logo-alignment" />
//       <div className="status-modal-text-alignment ">
//         <strong className="status-modal-text">{message}</strong>
//       </div>
//     </div>
//   );

//   modalProps.headerColor = 'success';
//   modalProps.onClose = () => clearModal();
//   let buttonProps: any = {...successButton};
//   buttonProps.onClickAction = () => clearModal();
//   const buttonRowProps = {buttonProps};
//   modalProps.footer = <CIconButtonRow buttonRowProps={buttonRowProps} />;
//   return <CModalGroup modal={modalProps} />;
// } else {
//   return <></>;
// }
{
  /* <div className="status-modal">
  <PathSpotHorizontal className="status-modal-logo-alignment" />
  <div className="status-modal-text-alignment ">
    <strong className="status-modal-text">{message}</strong>
  </div>
</div>; */
}

import React from 'react';
import {CInput, CInputGroup, CRow} from '@coreui/react';
import {CInputGroupRowPrepend, CInputGroupRowAppend, PAlertErrors} from '../..';
import {v4 as uuidv4} from 'uuid';
const uuidVal = uuidv4();

const FInputGroupRow = (props: any) => {
  const {inputType, fieldName, iconStart, displayStr, placeholderStr, iconEnd, endStr, style, borderStyle, handleChange, values, allowEdit, setFieldValue, touched, errors} = props;
  //console.log('Input group row props are: ', props)
  return (
    <CRow className="mb-3">
      <CInputGroup>
        <CInputGroupRowPrepend iconStart={iconStart} displayStr={displayStr} />
        <CInput
          key={`${fieldName}-${uuidVal}`}
          disabled={typeof allowEdit === 'boolean' ? !allowEdit : false}
          style={borderStyle}
          type={inputType}
          name={fieldName}
          placeholder={placeholderStr}
          onChange={(e: any) => {
            setFieldValue(fieldName, e.target.value);
          }}
          value={values && (values[fieldName] || values[fieldName] === '') ? values[fieldName] : displayStr}
          invalid={touched && touched[fieldName] && errors && errors[fieldName] ? true : false}
        />
        <CInputGroupRowAppend iconEnd={iconEnd} endStr={endStr} />
      </CInputGroup>
      <PAlertErrors errors={errors} fieldName={fieldName} />
    </CRow>
  );
};
export default FInputGroupRow;

import React from 'react';
import CIconButton from '../buttons/CIconButton';
import {CRow} from '@coreui/react';
import PInputCheckbox from '../buttons/PInputCheckbox';
import {v4 as uuidv4} from 'uuid';

export type POptionsRowProps = {
  layout: {
    label?: {
      className: string;
    };
    input?: {
      className: string;
    };
    base?: {
      className: string;
    };
  };

  content: {
    label?: string;
    placeholder?: string;
    value: any;
    fieldName: any;
    handleChange?: any;
  };
};

const componentKey = uuidv4();
export const POptionsRow = (props: any) => {
  const {options, layout, content, handlers} = props;
  console.log('Props in POptionsRow are:', props);
  let renderStack: any = Object.keys(options).map((optionItem: any) => <CIconButton key={uuidv4()} {...content.options?.[optionItem]} onClickAction={handlers?.[optionItem]} />);
  return (
    <div className={layout?.row?.className || 'mb-3'}>
      <div className={layout?.header?.className || 'mb-3'}>{content.options?.header || ''}</div>
      <div className={layout?.options?.div?.className || 'mb-3'}>
        <span className={layout?.options?.span?.className}> {renderStack}</span>
      </div>
    </div>
  );
};
export default POptionsRow;

export enum MODAL_ACTION {
  SHOW = 'show',
  HIDE = 'hide',
  CLEAR = 'clear',
}
export enum ModalFooterType {
  CANCEL_CONTINUE_BUTTONS = 'cancelContinueButtons',
  SAVE_CANCEL_BUTTONS = 'saveCancelButtons',
  CONFIRM_BUTTON = 'confirmButton',
  TEXT = 'text',
}
export interface ModalState {
  id?: any;
  type: string | null;
  display?: boolean;
  handles?: any;
  kwargs?: any;
}

export interface ModalProps {
  header?: any;
  headerColor?: string;
  footer?: any;
  footerColor?: string;
  display: boolean;
  body?: any;
  bodyStyle?: any;
  onClose?: () => void;
}

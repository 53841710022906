import * as React from 'react';

const PathSpotHorizontalOutlinedMediumRaw = (props: any) => {
  const {primary_color, secondary_color, tertiary_color, quaternary_color, className, viewBox, width, height} = props;
  return (
    <svg viewBox={viewBox || '0 0 510.064 101.740'} className={className || 'p-icon-5xl'}>
      <path
        fill={primary_color}
        d="M105.206 76V28.156h19.555c10.859 0 15.719 7.888 15.719 15.7 0 7.187-4.116 15.579-15.719 15.579h-9.5V76zM124.3 50.552c4.476 0 6.071-3.457 6.071-6.692 0-3.266-1.558-6.755-5.931-6.755h-9.162v13.447z"
        transform="translate(-.597 -1.214)"
      />
      <path
        fill={tertiary_color}
        d="M124.761 28.406c10.732 0 15.469 7.7 15.469 15.454s-4.737 15.329-15.469 15.329h-9.754v16.557h-9.552v-47.34h19.306m-9.738 22.4h9.277c4.6 0 6.321-3.572 6.321-6.942 0-3.433-1.724-7.005-6.181-7.005h-9.412V50.8m9.738-22.9h-19.811v48.34h10.552V59.689h9.254c5.1 0 9.292-1.7 12.134-4.922a16.38 16.38 0 003.835-10.907 16.582 16.582 0 00-3.83-10.96c-2.849-3.266-7.046-4.992-12.136-4.992zm-9.238 9.449h8.912c4.189 0 5.681 3.36 5.681 6.505 0 3.114-1.529 6.442-5.821 6.442h-8.772V37.355z"
        transform="translate(-.597 -1.214)"
      />
      <path
        fill={primary_color}
        d="M179.982 76l-3.851-11.338h-17.378L154.979 76h-10.713l17.845-47.86h10.661L190.554 76zm-6.91-20.148l-5.691-16.767-5.633 16.767z"
        transform="translate(-.597 -1.214)"
      />
      <path
        fill={tertiary_color}
        d="M172.6 28.39l17.6 47.356h-10.038l-3.852-11.338h-17.737L154.8 75.746h-10.174l17.659-47.356H172.6M161.4 56.1h12.021l-6.042-17.8-5.979 17.8m11.546-28.21h-11.008l-.121.326-17.66 47.355-.251.675h11.253l.114-.342 3.661-11h17.018l3.736 11 .116.339h11.11l-.25-.674-17.6-47.356-.121-.326zM162.1 55.6l5.287-15.736 5.336 15.736z"
        transform="translate(-.597 -1.214)"
      />
      <path fill={primary_color} d="M205.019 74.782V35.891h-13.062v-8.949h36.161v8.949h-13.047v38.891h-10.052z" />
      <path
        fill={tertiary_color}
        d="M228.465 28.406v8.449h-13.047v38.891h-9.552V36.855H192.8v-8.449h35.661m.5-.5H192.3v9.449h13.062v38.891h10.552V37.355h12.566v-.5h.485v-8.949z"
        transform="translate(-.597 -1.214)"
      />
      <path fill={primary_color} d="M267.487 74.798V55.554h-17.159v19.244h-10.052V26.942h10.052v19.523h17.159V26.942h10.052v47.856h-10.052z" />
      <path
        fill={tertiary_color}
        d="M277.886 28.406v47.355h-9.552V56.518h-17.659v19.243h-9.552V28.406h9.552v19.523h17.659V28.406h9.552m.5-.5h-10.552v19.523h-16.659V27.906h-10.552v48.355h10.552V57.018h16.659v19.243h10.552V27.906z"
        transform="translate(-.597 -1.214)"
      />
      <path
        fill={primary_color}
        d="M309.321 76.633c-10.188-.016-17.3-6.116-17.3-14.834v-.25h10.046v.245c.062 3.372 3.363 6.013 7.516 6.013s6.833-2.084 6.833-5.309c0-2.493-1.9-4.28-5.8-5.461l-5.774-1.646c-10.265-2.719-12.42-8.844-12.42-13.5 0-8.307 6.983-14.336 16.6-14.336s16.061 5.9 16.061 14.678v.25h-10.048v-.25c0-3.481-2.437-5.73-6.211-5.73-3.635 0-6.273 2.181-6.273 5.186 0 2.223 1.72 3.829 5.114 4.776l5.3 1.507c9.141 2.542 13.585 7.138 13.585 14.048 0 10.042-8.93 14.617-17.226 14.617z"
        transform="translate(-.597 -1.214)"
      />
      <path
        fill={tertiary_color}
        d="M309.026 27.8c8.931 0 15.811 5.436 15.811 14.429h-9.552c0-3.371-2.329-5.98-6.461-5.98-3.712 0-6.523 2.268-6.523 5.436 0 1.165.342 3.635 5.3 5.017l5.3 1.506c7.144 1.988 13.4 5.778 13.4 13.808 0 9.412-8.045 14.366-16.976 14.366-10.126-.015-17.053-6.2-17.053-14.584h9.552c.062 3.433 3.37 6.26 7.765 6.26 4.256 0 7.083-2.206 7.083-5.561 0-2.609-1.926-4.473-5.98-5.7l-5.778-1.646c-10.375-2.749-12.238-9.008-12.238-13.264 0-8.449 7.284-14.087 16.354-14.087m0-.5c-9.766 0-16.854 6.135-16.854 14.587 0 4.747 2.187 10.986 12.61 13.747l5.769 1.644c3.777 1.143 5.617 2.851 5.617 5.219 0 3.074-2.584 5.061-6.583 5.061-4.015 0-7.207-2.534-7.265-5.769l-.009-.491h-10.547v.5c0 8.866 7.218 15.069 17.553 15.084 8.417 0 17.476-4.652 17.476-14.866 0-7.037-4.5-11.711-13.769-14.289l-5.294-1.506c-3.274-.914-4.933-2.439-4.933-4.536 0-2.86 2.533-4.936 6.023-4.936 3.621 0 5.961 2.151 5.961 5.48v.5h10.552v-.5c0-8.79-6.707-14.929-16.311-14.929z"
        transform="translate(-.597 -1.214)"
      />
      <path
        fill={primary_color}
        d="M340.181 76V28.156h19.555c10.86 0 15.72 7.888 15.72 15.7 0 7.187-4.117 15.579-15.72 15.579h-9.5V76zm19.09-25.444c4.476 0 6.071-3.457 6.071-6.692 0-3.266-1.559-6.755-5.932-6.755h-9.162v13.443z"
        transform="translate(-.597 -1.214)"
      />
      <path
        fill={tertiary_color}
        d="M359.736 28.406c10.733 0 15.47 7.7 15.47 15.454s-4.737 15.329-15.47 15.329h-9.753v16.557h-9.552v-47.34h19.305M350 50.8h9.273c4.6 0 6.321-3.572 6.321-6.942 0-3.433-1.724-7.005-6.182-7.005H350V50.8m9.738-22.9h-19.807v48.34h10.552V59.689h9.253c5.1 0 9.293-1.7 12.135-4.922a16.38 16.38 0 003.835-10.907 16.583 16.583 0 00-3.834-10.96c-2.849-3.266-7.046-4.992-12.136-4.992zm-9.238 9.455h8.912c4.189 0 5.682 3.36 5.682 6.505 0 3.114-1.529 6.442-5.821 6.442H350.5V37.355z"
        transform="translate(-.597 -1.214)"
      />
      <path
        fill={primary_color}
        d="M409.577 76.617a24.437 24.437 0 01-24.308-24.51 24.181 24.181 0 0124.308-24.572 24.542 24.542 0 010 49.082zm0-39.59c-8.128 0-14.257 6.483-14.257 15.08s6.129 15.079 14.257 15.079 14.256-6.482 14.256-15.079-6.133-15.08-14.256-15.08z"
        transform="translate(-.597 -1.214)"
      />
      <path
        fill={tertiary_color}
        d="M409.577 27.785a24.292 24.292 0 11-24.058 24.322 24.148 24.148 0 0124.058-24.322m0 39.651c8.449 0 14.506-6.8 14.506-15.329s-6.041-15.33-14.506-15.33c-8.527 0-14.506 6.8-14.506 15.33s5.979 15.329 14.506 15.329m0-40.151a24.276 24.276 0 00-17.441 7.26 25.151 25.151 0 00.006 35.069 24.3 24.3 0 0017.435 7.253 24.792 24.792 0 000-49.582zm0 39.651c-7.985 0-14.006-6.375-14.006-14.829s6.021-14.83 14.006-14.83 14.006 6.376 14.006 14.83-6.021 14.829-14.006 14.829z"
        transform="translate(-.597 -1.214)"
      />
      <path fill={primary_color} d="M453.88 74.782V35.891h-13.062v-8.949h36.16v8.949h-13.047v38.891H453.88z" />
      <path
        fill={tertiary_color}
        d="M477.325 28.406v8.449h-13.046v38.891h-9.552V36.855h-13.062v-8.449h35.66m.5-.5h-36.66v9.449h13.062v38.891h10.552V37.355h12.562v-.5h.484v-8.949z"
        transform="translate(-.597 -1.214)"
      />
      <path
        fill={primary_color}
        d="M507 39.807l-.579-5.2-1.819 3.85h-2.474l-1.851-3.821-.581 5.172h-3.363l1.412-11.651h2.727l2.868 6.2 2.838-6.2h2.789l1.412 11.651zm-17.174-.015V31.11H487v-2.954h9.011v2.954h-2.858v8.682z"
        transform="translate(-.597 -1.214)"
      />
      <path
        fill={tertiary_color}
        d="M508.745 28.406l1.355 11.151h-2.873l-.652-5.855-2.128 4.5h-2.159l-2.159-4.457-.652 5.808h-2.858l1.351-11.151h2.346l3.028 6.554 3-6.554h2.407m-12.984 0v2.458H492.9v8.682h-2.827V30.86h-2.823v-2.454h8.511m13.427-.5h-3.171l-.134.292-2.545 5.565-2.572-5.563-.135-.29h-3.108l-.053.44-1.351 11.15-.068.56h3.869l.05-.444.459-4.09 1.405 2.9.137.282h2.789l.135-.286 1.374-2.909.457 4.1.05.444h3.885l-.068-.56-1.351-11.151-.054-.44zm-12.927 0h-9.511v3.454h2.826v8.682h3.824V31.36h2.858v-3.454z"
        transform="translate(-.597 -1.214)"
      />
      <path
        d="M50.821 102.7a32.562 32.562 0 01-29.76-26.2 32.406 32.406 0 014.893-24.492l23.864-35.632a3.033 3.033 0 012.542-1.366A3.07 3.07 0 0155 16.478L77.308 52.55a33.061 33.061 0 014.027 25.367 32.129 32.129 0 01-14.73 20.149 32.577 32.577 0 01-15.759 4.634h-.025zM36.709 59.211a19.709 19.709 0 005.4 27.339 19.776 19.776 0 008.994 3.2A19.616 19.616 0 0059.946 87a19.318 19.318 0 008.831-12.125 20.3 20.3 0 00-2.467-15.522L52.057 36.3z"
        transform="translate(-.597 -1.214)"
        fill={secondary_color}
      />
      <path
        fill={tertiary_color}
        d="M52.43 15.26a2.8 2.8 0 012.359 1.348L77.1 52.682a32.815 32.815 0 014 25.176 31.9 31.9 0 01-14.624 19.995 32.363 32.363 0 01-15.638 4.6 32.315 32.315 0 01-29.532-26 32.166 32.166 0 014.856-24.3l23.863-35.638a2.79 2.79 0 012.335-1.255h.07M51.082 90a19.871 19.871 0 008.993-2.788 19.567 19.567 0 008.946-12.282 20.545 20.545 0 00-2.5-15.707L52.233 36.1a.223.223 0 00-.175-.093.206.206 0 00-.174.093L36.5 59.072A19.9 19.9 0 0051.082 90m1.354-75.24h-.076a3.281 3.281 0 00-2.749 1.475L25.746 51.869a32.9 32.9 0 009.025 45.621 32.995 32.995 0 0016.029 5.462h.05a32.841 32.841 0 0015.88-4.672 32.386 32.386 0 0014.848-20.304 33.3 33.3 0 00-4.058-25.557L55.214 16.345a3.283 3.283 0 00-2.772-1.585zM51.092 89.5a19.528 19.528 0 01-8.841-3.156 19.456 19.456 0 01-5.334-26.99l15.131-22.598L66.1 59.486a20.05 20.05 0 012.438 15.327 19.068 19.068 0 01-8.718 11.969 19.344 19.344 0 01-8.728 2.718z"
        transform="translate(-.597 -1.214)"
      />
      <path fill={primary_color} d="M68.748 32.109l-.024-4.939a6.687 6.687 0 0113.376-.06v26.483z" transform="translate(-.597 -1.214)" />
      <path
        fill={tertiary_color}
        d="M75.41 20.709a6.437 6.437 0 016.437 6.4v25.608L69 32.037l-.023-4.868a6.446 6.446 0 016.413-6.46h.023m0-.5h-.023a6.946 6.946 0 00-6.913 6.96l.023 4.87v.141l.075.121 12.849 20.68.925 1.488V27.111a6.943 6.943 0 00-6.937-6.9z"
        transform="translate(-.597 -1.214)"
      />
      <path
        fill={primary_color}
        d="M8.836 30.032a6.562 6.562 0 012.114-9.024 6.545 6.545 0 019.011 2.114l10.181 16.413-7.06 10.544c-.206.294-.392.591-.561.861l-.31.493z"
        transform="translate(-.597 -1.214)"
      />
      <path
        fill={tertiary_color}
        d="M14.381 20.268a6.135 6.135 0 011.452.174 6.244 6.244 0 013.916 2.811l10.1 16.277-6.975 10.41c-.244.349-.465.709-.662 1.022L9.049 29.9a6.31 6.31 0 012.033-8.679 6.218 6.218 0 013.276-.952h.023m0-.5h-.023a6.693 6.693 0 00-3.541 1.031 6.811 6.811 0 00-2.193 9.367l13.164 21.06.422.676.425-.674.1-.157c.168-.267.351-.56.551-.845l6.974-10.419.18-.267-.17-.274L20.174 22.99a6.738 6.738 0 00-4.227-3.035 6.618 6.618 0 00-1.566-.187z"
        transform="translate(-.597 -1.214)"
      />
      <path fill={primary_color} d="M36.44 11.465a6.555 6.555 0 0111.149-6.9l4.328 6.974-.384.051a6.589 6.589 0 00-4.6 2.854l-4.491 6.7z" transform="translate(-.597 -1.214)" />
      <path
        fill={tertiary_color}
        d="M42.009 1.714A6.269 6.269 0 0147.376 4.7l4.124 6.645a6.791 6.791 0 00-4.775 2.963l-4.275 6.378-5.8-9.353a6.294 6.294 0 015.321-9.619h.035m0-.5h-.035A6.794 6.794 0 0036.228 11.6l5.8 9.351.409.659.432-.644 4.275-6.378a6.335 6.335 0 014.425-2.745l.768-.1-.408-.659L47.8 4.436a6.776 6.776 0 00-5.792-3.222z"
        transform="translate(-.597 -1.214)"
      />
      <path fill={primary_color} d="M17.887 13.08A6.56 6.56 0 0120 4.057a6.561 6.561 0 019.023 2.126L40.3 24.363l-8.012 11.958z" transform="translate(-.597 -1.214)" />
      <path
        fill={tertiary_color}
        d="M23.443 3.329a6.271 6.271 0 015.368 2.985L40 24.357l-7.7 11.5-14.2-22.909a6.294 6.294 0 015.32-9.619h.023m0-.5h-.023a6.793 6.793 0 00-5.746 10.383l14.2 22.91.409.66.432-.645 7.7-11.5.18-.267-.17-.274L29.236 6.051a6.779 6.779 0 00-5.793-3.222z"
        transform="translate(-.597 -1.214)"
      />
      <path
        fill={primary_color}
        d="M1.831 49.991a6.544 6.544 0 015.533-10 6.557 6.557 0 014.251 1.547 6.716 6.716 0 01.975 1.141l7.674 12.416-.056.122a35.77 35.77 0 00-3.045 18.62l.113 1.086z"
        transform="translate(-.597 -1.214)"
      />
      <path
        fill={tertiary_color}
        d="M7.387 40.239a6.286 6.286 0 014.066 1.487 6.381 6.381 0 01.93 1.092l7.6 12.292a35.951 35.951 0 00-3.067 18.751l-14.871-24a6.294 6.294 0 015.321-9.62h.023m0-.5h-.025a6.795 6.795 0 00-5.746 10.382l14.871 24 1.149 1.855-.227-2.171a35.524 35.524 0 013.025-18.491l.111-.243-.141-.228-7.6-12.292a6.944 6.944 0 00-1.006-1.192 6.81 6.81 0 00-4.408-1.622z"
        transform="translate(-.597 -1.214)"
      />
    </svg>
  );
};

const PathSpotHorizontalOutlinedMedium = React.memo(PathSpotHorizontalOutlinedMediumRaw);
export default PathSpotHorizontalOutlinedMedium;

import React, {Component} from 'react';
import {HashRouter, Route, Switch, Redirect} from 'react-router-dom';
import './scss/style.scss';
import 'spinkit/spinkit.min.css'; //TODO: move to scss
import 'react-grid-layout/css/styles.css'; //TODO: move to scss
import 'react-resizable/css/styles.css'; //TODO: move to scss
import {ReportingContextProvider} from './modules/automated-reporting/api/ReportingContext';
import {AuthenticationProvider} from './api/authentication/AuthenticationContext';
const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const TheLayout = React.lazy(() => import('./layout/containers/TheLayout'));

// Pages
const Login = React.lazy(() => import('./layout/login/Login'));

const App = ({initialAuthState}: any) => {
  return (
    <ReportingContextProvider>
      <HashRouter>
        <React.Suspense fallback={loading}>
          <AuthenticationProvider initialAuthState={initialAuthState}>
            <Switch>
              <Route exact path="/login" component={Login} />
              <Route path="/" component={TheLayout} />
            </Switch>
          </AuthenticationProvider>
        </React.Suspense>
      </HashRouter>
    </ReportingContextProvider>
  );
};

export default App;

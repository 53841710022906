import React from 'react';
import {CCard, CCardBody, CCardHeader, CCol, CDataTable, CRow} from '@coreui/react';
import {replaceEmptyObjectFields} from '../../api/base/common';

const PDataTable = (props: any) => {
  const {isFetching, tableData, layout, tableProps} = props;
  const cleanedTableData = tableData.map((item: any) => replaceEmptyObjectFields(item, tableProps.emptyFieldString));
  return (
    <div style={layout?.baseStyle}>
      <CDataTable
        items={cleanedTableData}
        fields={tableProps?.tableDataFields}
        columnHeaderSlot={tableProps?.tableDataColumnNames}
        loading={isFetching}
        columnFilter
        tableFilter
        itemsPerPageSelect={{...layout?.itemsPerPage?.select}}
        itemsPerPage={layout?.itemsPerPage?.default}
        hover
        sorter
        pagination={{...layout?.pagination}}
        scopedSlots={{...layout?.dynamicFields}}
      />
    </div>
  );
};
export default PDataTable;

//Brandon S. Nichols
//2022-05-20 10:22:00

import React from 'react';
import CIcon from '@coreui/icons-react';
import {CButton, CCol, CRow, CButtonToolbar} from '@coreui/react';
import SpinnerIcon from '../icons/SpinnerIcon';

export const SaveCancelButtons = (props: any) => {
  const {saveProps, cancelProps, rowProps, applyStr, cancelStr, handleReset, spinnerState, setSpinnerState, handleSubmit} = props;
  console.log('SaveCancelButtonToolbar: props:', props);
  const waitString = saveProps?.buttonTextInterim || 'Submitting...';
  //Backwards compatibility for only sending strings as props for the buttons.
  const saveString = saveProps?.buttonText || applyStr;
  const cancelString = cancelProps?.buttonText || cancelStr;
  console.log('Saveprops?.buttonType : ', saveProps?.buttonType);
  console.log('Saveprops?.buttonType || submit : ', saveProps?.buttonType || 'submit');

  return (
    <CRow className={rowProps?.className || 'm-1'}>
      <CCol className={saveProps?.column?.className || 'justify-content-center'}>
        <CButtonToolbar className={saveProps?.toolbar?.className || 'justify-content-center'}>
          <CButton
            // type={saveProps?.buttonType || 'submit'}
            type="submit"
            onClick={(e: any) => {
              // console.log('Submit button clicked...', [e.type, e.detail, e.currentTarget, e.target, e.nativeEvent, e.button]);
              return setSpinnerState?.(true);
              // handleSubmit();
            }}
            color={saveProps?.buttonColor || 'primary'}
            size={saveProps?.buttonSize || 'sm'}
            className={saveProps?.buttonClass || 'px-4 mx-4'}
            disabled={saveProps?.buttonDisabled || false}
            variant={saveProps?.buttonVariant || ''}
          >
            <SpinnerIcon
              spinnerState={spinnerState}
              iconName={saveProps?.iconName || 'cil-check'}
              iconClassName={saveProps?.iconClassName || 'mr-2 text-center'}
              spinnerSize={saveProps?.iconSize || 'sm'}
            />
            <span>{spinnerState === true ? waitString : saveString || 'Save Changes'}</span>
          </CButton>
          <CButton
            onClick={(event: any) => {
              const noVal = setSpinnerState?.(false);
              return handleReset?.(event);
            }}
            type={cancelProps?.buttonType || 'reset'}
            color={cancelProps?.buttonColor || 'danger'}
            size={cancelProps?.buttonSize || 'sm'}
            className={cancelProps?.buttonClass || 'px-4 mx-4'}
            disabled={cancelProps?.buttonDisabled || false}
          >
            <CIcon name={cancelProps?.iconName || 'cil-ban'} className={cancelProps?.iconClassName || 'mx-2'} />
            {cancelString || 'Cancel'}
          </CButton>
        </CButtonToolbar>
      </CCol>
    </CRow>
  );
};
export default SaveCancelButtons;

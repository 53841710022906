import * as React from 'react';

function PathSpotSearchRaw(props: any) {
  const {primary_color, secondary_color, tertiary_color, quaternary_color, className, viewBox, width, height} = props;
  return (
    <svg viewBox={viewBox || '0 0 492.878 508.246'} className={className || 'p-icon-5xl'}>
      <path
        fill="none"
        stroke={quaternary_color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={36.246}
        d="M474.755 490.123L317.518 332.886M197.976 283.737a85.759 85.759 0 110-171.518h0"
      />
      <circle cx={197.978} cy={197.978} r={179.854} fill="none" stroke={primary_color} strokeLinecap="round" strokeLinejoin="round" strokeWidth={36.246} />
    </svg>
  );
}

const PathSpotSearch = React.memo(PathSpotSearchRaw);
export default PathSpotSearch;

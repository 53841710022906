import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
// import {tagPropType} from '@coreui/react/src/utils/helper';

//component - CoreUI / PFormText
const PFormText = (props: any) => {
  const {
    tag: Tag,
    className,
    innerRef,
    //
    color,
    ...attributes
  } = props;

  //render

  const classes = classNames('form-text', color && `text-${color}`, className);

  return <Tag className={classes} {...attributes} ref={innerRef} />;
};

PFormText.propTypes = {
  tag: React.Component,
  children: PropTypes.node,
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.object]),
  //
  innerRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  color: PropTypes.string,
};

PFormText.defaultProps = {
  tag: 'small',
  color: 'muted',
};

export default PFormText;

import * as React from 'react';

function PathSpotArrowDownRaw(props: any) {
  const {primary_color, secondary_color, tertiary_color, quaternary_color, className, viewBox, width, height} = props;

  return (
    <svg viewBox={viewBox || '0 0 364.742 507.932'} className={className || 'p-icon-5xl'}>
      <path
        d="M115.526.375h126.445a17.591 17.591 0 0117.589 17.591v261.261a17.591 17.591 0 11-35.181 0V35.557h-91.263v243.67a17.591 17.591 0 11-35.181 0V17.966A17.591 17.591 0 01115.526.375z"
        fill={primary_color}
        stroke={tertiary_color}
        strokeMiterlimit={10}
        strokeWidth={0.75}
      />
      <path
        d="M17.971 265.154h328.81a17.591 17.591 0 0113.781 28.524l-164.4 207.221a17.59 17.59 0 01-27.561 0L4.186 293.678a17.59 17.59 0 0113.785-28.524zm292.4 35.182H54.377l127.994 161.33z"
        fill={primary_color}
        stroke={tertiary_color}
        strokeMiterlimit={10}
        strokeWidth={0.75}
      />
    </svg>
  );
}

const PathSpotArrowDown = React.memo(PathSpotArrowDownRaw);
export default PathSpotArrowDown;

export const BACKEND_OVERRIDE = "BE/C";
export const PHONE_NUMBER_MASK = [
  "(",
  /[1-9]/,
  /\d/,
  /\d/,
  ")",
  " ",
  /\d/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
  /\d/,
  /\d/
];
// export const TIME_MASK = ['(', '/[0 - 1]', '/[0 - 9]', 2[0 - 3]): [0 - 5][0 - 9]$"]
// const timePattern = "^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$"

export enum ENV {
  PROD = "production",
  STAGING = "staging",
  DEV = "development"
}

import CIcon from '@coreui/icons-react';
import {CInputGroupAppend, CInputGroupText} from '@coreui/react';
import React from 'react';
import {v4 as uuidv4} from 'uuid';

const CInputGroupRowAppend = (props: any) => {
  const {iconEnd, endStr} = props;
  let appendItems: any[] = [];
  if (iconEnd) {
    appendItems.push(
      <CInputGroupText key={uuidv4()}>
        <CIcon name={iconEnd} />
      </CInputGroupText>
    );
  }
  if (endStr) {
    appendItems.push(<CInputGroupText key={uuidv4()}>{endStr}</CInputGroupText>);
  }
  if (appendItems.length > 0) {
    return <CInputGroupAppend key={uuidv4()}>{appendItems}</CInputGroupAppend>;
  } else {
    return <span></span>;
  }
};
export default CInputGroupRowAppend;

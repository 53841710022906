import * as React from 'react';

function PathSpotArrowUpRaw(props: any) {
  const {primary_color, secondary_color, tertiary_color, quaternary_color, className, viewBox, width, height} = props;

  return (
    <svg viewBox={viewBox || '0 0 364.742 507.932'} className={className || 'p-icon-5xl'}>
      <path
        d="M249.217 507.557H122.771a17.591 17.591 0 01-17.589-17.591V228.705a17.591 17.591 0 0135.182 0v243.67h91.262v-243.67a17.591 17.591 0 0135.182 0v261.261a17.591 17.591 0 01-17.591 17.591z"
        fill={primary_color}
        stroke="#fff"
        strokeMiterlimit={10}
        strokeWidth={0.75}
      />
      <path
        d="M346.776 242.778H17.971A17.591 17.591 0 014.19 214.254L168.59 7.033a17.59 17.59 0 0127.561 0l164.406 207.221a17.591 17.591 0 01-13.781 28.524zm-292.4-35.182h255.989L182.371 46.266z"
        fill={primary_color}
        stroke="#fff"
        strokeMiterlimit={10}
        strokeWidth={0.75}
      />
    </svg>
  );
}

const PathSpotArrowUp = React.memo(PathSpotArrowUpRaw);
export default PathSpotArrowUp;

import * as React from 'react';

function PathSpotLocationRaw(props: any) {
  const {primary_color, secondary_color, tertiary_color, quaternary_color, className, viewBox, width, height} = props;
  return (
    <svg viewBox={viewBox || '0 0 464.335 507.665'} className={className || 'p-icon-5xl'}>
      <path
        d="M119.091 132.863c0-64.445 48.5-116.7 112.915-116.7s112.915 52.258 112.915 116.7c0 95.1-112.915 197.762-112.915 197.762S119.091 227.963 119.091 132.863z"
        fill="none"
        stroke={primary_color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={32.335}
      />
      <path
        d="M396.43 313.463c32.335 18.852 51.736 43.3 51.736 70.039 0 59.626-96.715 108-216 108s-216-48.5-216-108c0-26.742 19.4-51.187 51.575-70.039"
        fill="none"
        stroke={quaternary_color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={32.335}
      />
      <path
        d="M357.337 369.981c-20.662 24.38-69 41.518-125.331 41.518-50.088 0-93.773-13.548-117.475-33.693"
        fill="none"
        stroke={quaternary_color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={32.335}
      />
      <circle cx={232.006} cy={140.756} r={39.514} fill={primary_color} />
    </svg>
  );
}

const PathSpotLocation = React.memo(PathSpotLocationRaw);
export default PathSpotLocation;
